// React
import React from "react";
import {
  BrowserRouter as Switch,
  Route,
  useLocation,
  useHistory,
} from "react-router-dom";
import { useCookies } from "react-cookie";

// Material
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

import Hidden from "@material-ui/core/Hidden";

import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Button } from "@material-ui/core";

// Massless
import {
  sAnalyticsLoad,
  sAnalyticsTrack,
  sAnalyticsIdentify,
} from "./massless/AnalyticsClient";

import NavBar from "./components/NavBar";
import SideNav from "./components/SideNav";
import BottomNav from "./components/BottomNavigation";

import { CommandBarProvider } from "./context/CommandBarContext";
import { SnackbarProvider } from "./context/SnackbarContext";
import { useAuth } from "./context/AuthContext";

import StudioPage from "./pages/StudioPage";
import ProjectPage from "./pages/ProjectPage";
import SpacePage from "./pages/SpacePage";
import PluginsPage from "./pages/PluginsPage";
import AccountPage from "./pages/AccountPage";
import LoginRedirectPage from "./pages/LoginRedirectPage";

import LoginPage from "./pages/LoginPage";

// import "./css/Variables.css";
// import "./css/AppBasic.css";

const theme = createMuiTheme({
  spacing: 5,
  palette: {
    type: "light",
    text: {
      primary: "#273E78",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    primary: {
      main: "#6E54F4",
    },
    secondary: {
      main: "#FF005C",
    },
    action: {
      disabledBackground: "#D9DFF2",
      disabled: "#D9DFF2",
    },
  },

  overrides: {
    canvas: { outline: "none", border: "none" },
    MuiTooltip: {
      tooltip: {
        fontSize: "1em",
        backgroundColor: "#6E54F4",
        borderRadius: "5px",
      },
    },
    MuiIconButton: {
      root: {
        color: "#A9BDF1",
        "&:hover": {
          color: "#6E54F4",
        },
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: "0px 2px 6px rgba(33, 6, 170, 0.3)",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  appRoot: { display: "flex", width: "100%" },
  appBody: {
    width: "100%",
    height: "calc(100vh - 40px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F0F2F5",
    [theme.breakpoints.down("md")]: {
      borderRadius: 0,
      margin: "0",
    },
    [theme.breakpoints.up("md")]: {
      borderRadius: "40px",
      margin: "20px 20px 20px 0",
    },
    // display: "flex",
    // flexDirection: "column",
    // overflow: "hidden",
    // width: "100%",
    // height: "calc(100vh - 40px)",
    // backgroundColor: "#F0F2F5",
    // borderRadius: "40px",
    // margin: "20px 20px 20px 0",
  },
}));

function ProtectedRoute(props) {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const [cookies, setCookie] = useCookies(["destination"]);

  if (isAuthenticated == false && location.pathname != "/login") {
    console.log("on way to somewhere but not authenticated");
    console.log(location);
    setCookie("destination", location.pathname, { path: "/" });
  }

  return (
    <>
      {!isAuthenticated && <LoginPage />}
      {isAuthenticated && props.route}
    </>
  );
}

function App(props) {
  const classes = useStyles();

  const history = useHistory();
  const { isLoading } = useAuth();

  const { isAuthenticated } = useAuth();

  React.useEffect(() => {
    sAnalyticsLoad();

    let massless = [
      "              ##                   ##               ",
      "         ###########          ###########           ",
      "       ,(((((((((  (((       (((((((((( (((         ",
      "       (((((((((( (((((     (((((((((( (((((        ",
      "      (((((((((( (((((((   (((((((((( (((((((       ",
      "      ((((((((( ((((((((   ((((((((((((((((((       ",
      "     ////////// ///////// ////////// /////////      ",
      "     ///////// ////////// ////////// /////////      ",
      "    //////////  ///////////////////  //////////     ",
      "    //////////  //////// //////////  //////////     ",
      "   **********    ****** **********    **********    ",
      "  **********     ****** **********     **********   ",
      " **********        **  **********       **********  ",
      " *********          ***********          *********  ",
      " ",
      " ............Welcome to Massless Space..............",
    ];
    console.log(process.env.REACT_APP_SPACE_SERVER);
    console.log(process.env.REACT_APP_GLOBAL_STUDIO);
    console.log(process.env.REACT_APP_GLOBAL_PROJECT);

    massless.forEach((line) => {
      console.log(line);
    });
  }, []);

  const renderRoutes = () => {
    return (
      <>
        <Route exact path="/">
          <ProtectedRoute
            route={
              <ProjectPage
                studioId={process.env.REACT_APP_GLOBAL_STUDIO}
                projectId={process.env.REACT_APP_GLOBAL_PROJECT}
              />
            }
          />
        </Route>
        <Route exact path="/s/:spaceId">
          <ProtectedRoute
            route={
              <SpacePage
                studioId={process.env.REACT_APP_GLOBAL_STUDIO}
                projectId={process.env.REACT_APP_GLOBAL_PROJECT}
              />
            }
          />
        </Route>
        <Route path="/plugins">
          <ProtectedRoute route={<PluginsPage />} />
        </Route>
        <Route path="/account/*" exact>
          <ProtectedRoute route={<AccountPage />} />
        </Route>
        <Route path="/login">
          <LoginRedirectPage />
        </Route>
      </>
    );
  };

  //console.log(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <CommandBarProvider>
        <CssBaseline />
        <Box className={classes.appRoot}>
          {isAuthenticated && (
            <>
              <Hidden smDown>
                <SideNav />
              </Hidden>
              <Hidden mdUp>
                <BottomNav />
              </Hidden>
            </>
          )}

          <SnackbarProvider>
            {/* <Box className={classes.appBody}> */}
            {isLoading && (
              <Box style={{ width: "100%", height: "100vh", display: "flex" }}>
                <CircularProgress style={{ margin: "auto" }} color="primary" />
              </Box>
            )}
            {!isLoading && renderRoutes()}
          </SnackbarProvider>
        </Box>
      </CommandBarProvider>
    </MuiThemeProvider>
  );
}

export default App;
