// THREE
import * as THREE from "three";

export function CardMedia3D_Collection(threeCanvas) {
  let aspect = 1.78;
  let camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 10);

  camera.position.x = 0;
  camera.position.y = 0;
  camera.position.z = 3.5;

  let renderer = new THREE.WebGLRenderer({
    antialias: true,
    canvas: threeCanvas,
  });
  renderer.setClearColor(0x222343, 1);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFShadowMap;

  let scene = new THREE.Scene();

  let light = new THREE.SpotLight(0xffffff, 1.5);
  light.position.set(0, 5, 20);
  light.angle = Math.PI / 9;

  light.castShadow = true;
  light.shadow.camera.near = 0.1;
  light.shadow.camera.far = 5;
  light.shadow.mapSize.width = 1024;
  light.shadow.mapSize.height = 1024;

  scene.add(light);

  const N = 3.0;
  const R = 0.5;

  var geometries = [
    new THREE.BoxBufferGeometry(2.0 * R, 2.0 * R, 2.0 * R),
    new THREE.ConeBufferGeometry(R, 2.0 * R, 32),
    new THREE.CylinderBufferGeometry(R, R, 3.0 * R, 32),
    new THREE.TorusBufferGeometry(R, R / 3.0, 32, 16),
    new THREE.DodecahedronBufferGeometry(R),
    new THREE.OctahedronBufferGeometry(R),
  ];

  let group = new THREE.Group();
  group.rotation.x = Math.random() * 2 * Math.PI;
  group.rotation.y = Math.random() * 2 * Math.PI;
  group.rotation.z = Math.random() * 2 * Math.PI;
  scene.add(group);

  for (var i = 0; i < N; i++) {
    const geometry = geometries[Math.floor(Math.random() * geometries.length)];
    // const material = new THREE.MeshLambertMaterial({
    //   color: Math.random() * 0xffffff,
    //   flatShading: true,
    // });

    var material = new THREE.MeshStandardMaterial({
      color: Math.random() * 0xffffff,
      roughness: 0.7,
      metalness: 0.0,
    });

    let object = new THREE.Mesh(geometry, material);

    object.position.x = 2.0 * R * Math.cos((i * 2.0 * Math.PI) / N);
    object.position.y = 0.0;
    object.position.z = 2.0 * R * Math.sin((i * 2.0 * Math.PI) / N);

    object.rotation.x = Math.random() * 2 * Math.PI;
    object.rotation.y = Math.random() * 2 * Math.PI;
    object.rotation.z = Math.random() * 2 * Math.PI;

    object.castShadow = true;
    object.receiveShadow = true;

    group.add(object);
  }

  const resizeRendererToDisplaySize = (renderer) => {
    const canvas = renderer.domElement;
    const pixelRatio = window.devicePixelRatio;
    const width = (canvas.clientWidth * pixelRatio) | 0;
    const height = (canvas.clientHeight * pixelRatio) | 0;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  };

  let animate = () => {
    if (resizeRendererToDisplaySize(renderer)) {
      const canvas = renderer.domElement;
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
    }

    group.rotation.x += 0.001;
    group.rotation.y += 0.001;
    group.rotation.z += 0.001;

    renderer.render(scene, camera);
    requestAnimationFrame(animate);
  };
  animate();
}

export function CardMedia3D_Single(threeCanvas) {
  let aspect = 1.78;
  let camera = new THREE.PerspectiveCamera(75, aspect, 0.1, 10);

  camera.position.x = 0;
  camera.position.y = 0;
  camera.position.z = 1.8;

  let renderer = new THREE.WebGLRenderer({
    antialias: true,
    canvas: threeCanvas,
  });
  renderer.setClearColor(0x222343, 1);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFShadowMap;

  let scene = new THREE.Scene();

  let light = new THREE.SpotLight(0xffffff, 1.5);
  light.position.set(0, 5, 20);
  light.angle = Math.PI / 9;

  light.castShadow = true;
  light.shadow.camera.near = 0.1;
  light.shadow.camera.far = 5;
  light.shadow.mapSize.width = 1024;
  light.shadow.mapSize.height = 1024;

  scene.add(light);

  const R = 0.5;

  var geometries = [
    new THREE.BoxBufferGeometry(2.0 * R, 2.0 * R, 2.0 * R),
    new THREE.ConeBufferGeometry(R, 2.0 * R, 32),
    new THREE.CylinderBufferGeometry(R, R, 3.0 * R, 32),
    new THREE.TorusBufferGeometry(R, R / 3.0, 32, 16),
    new THREE.DodecahedronBufferGeometry(R),
    new THREE.OctahedronBufferGeometry(R),
  ];

  const geometry = geometries[Math.floor(Math.random() * geometries.length)];
  const material = new THREE.MeshLambertMaterial({
    color: Math.random() * 0xffffff,
    flatShading: true,
  });

  let object = new THREE.Mesh(geometry, material);

  object.position.x = 0.0;
  object.position.y = 0.0;
  object.position.z = 0.0;

  object.rotation.x = Math.random() * 2 * Math.PI;
  object.rotation.y = Math.random() * 2 * Math.PI;
  object.rotation.z = Math.random() * 2 * Math.PI;

  object.castShadow = true;
  object.receiveShadow = true;

  scene.add(object)

  const resizeRendererToDisplaySize = (renderer) => {
    const canvas = renderer.domElement;
    const pixelRatio = window.devicePixelRatio;
    const width = (canvas.clientWidth * pixelRatio) | 0;
    const height = (canvas.clientHeight * pixelRatio) | 0;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  };

  let animate = () => {
    if (resizeRendererToDisplaySize(renderer)) {
      const canvas = renderer.domElement;
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
    }

    object.rotation.x += 0.004;
    object.rotation.y += 0.004;
    object.rotation.z += 0.004;

    renderer.render(scene, camera);
    requestAnimationFrame(animate);
  };
  animate();
}
