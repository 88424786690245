// React
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// Stripe
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Massless
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { SpaceProvider } from "./context/SpaceContext";
import { KeysProvider } from "./context/KeysContext";
import { SpaceProxyProvider } from "./context/SpaceProxyContext";

// Cookies
import { CookiesProvider } from "react-cookie";

const stripePromise = loadStripe("pk_test_qP0Bbo5RHaRirSbQ7QH28mQe");

ReactDOM.render(
  <>
    <CookiesProvider>
      <BrowserRouter>
        <AuthProvider>
          <SpaceProvider>
            <SpaceProxyProvider>
              <KeysProvider>
                <Elements stripe={stripePromise}>
                  <App />
                </Elements>
              </KeysProvider>
            </SpaceProxyProvider>
          </SpaceProvider>
        </AuthProvider>
      </BrowserRouter>
    </CookiesProvider>
  </>,
  document.getElementById("root")
);
