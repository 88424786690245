import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";

import PublicIcon from "@material-ui/icons/Public";
import FolderIcon from "@material-ui/icons/Folder";
import BlurOnIcon from "@material-ui/icons/BlurOn";

// import { useStyles } from "../Style";

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer:{
    display: "flex",
  },
  breadcrumbIcon: {
    width: "20px",
    height: "20px",
    marginRight: "5px"
  },
}));

export function BreadcrumbGenerator(props) {
  const classes = useStyles();
  //console.log(props);
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {props.studioId != null && (
        <Link className={classes.breadcrumbContainer} color="inherit" href="/">
          <PublicIcon className={classes.breadcrumbIcon}/>
          Global Studio
        </Link>
      )}
      {props.projectId != null && (
        <Link color="inherit" href={"/"+props.studioId+"/"+props.projectId}>
          <FolderIcon className={classes.breadcrumbIcon} />
          Project
        </Link>
      )}
      {props.spaceId != null && (
        <Typography color="textPrimary">
          <BlurOnIcon className={classes.breadcrumbIcon} />
          Space
        </Typography>
      )}
    </Breadcrumbs>
  );
}
