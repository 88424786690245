import * as THREE from "three";
import { Vector3, MathUtils } from "three";
import { VertexNormalsHelper } from "./VertexNormalsHelper";

export function createDefaultThreeMaterial() {
  return new THREE.MeshStandardMaterial({
    color: 0xffffff,
    metalness: 0.0,
    roughness: 0.7,
    emissive: 0x0,
    flatShading: false,
  });
}

export function convertLight(lightIn) {
  let lightOut = new THREE.Object3D();
  if (true)
    switch (lightIn.type) {
      case 2 /* Point */:
        lightOut = new THREE.PointLight(0xff0000, 1, 100);
        lightOut.color = convertColor(lightIn.color);
        lightOut.intensity = lightIn.intensity;
        lightOut.distance = lightIn.distance;
        lightOut.decay = lightIn.decay;
        break;
      case 3 /* Directional */:
        lightOut = new THREE.DirectionalLight();
        lightOut.color = convertColor(lightIn.color);
        lightOut.intensity = lightIn.intensity;
        //Add target to light object and parent it to the light. This is necessary for the light to not be pointing at 0,0,0
        var targetObject = new THREE.Object3D();
        lightOut.add(targetObject);
        var targetPosition = new Vector3(0, 0, -2);
        targetObject.position.copy(targetPosition);
        lightOut.target = targetObject;
        break;
      case 6 /* Spot */:
        lightOut = new THREE.SpotLight();
        lightOut.color = convertColor(lightIn.color);
        lightOut.intensity = lightIn.intensity;
        lightOut.distance = lightIn.distance;
        lightOut.decay = lightIn.decay;
        lightOut.angle = lightIn.spotangle;
        //Add target to light object and parent it to the light. This is necessary for the light to not be pointing at 0,0,0
        var targetObject = new THREE.Object3D();
        lightOut.add(targetObject);
        var targetPosition = new Vector3(0, 0, -2);
        targetObject.position.copy(targetPosition);
        lightOut.target = targetObject;
        break;
      default:
        lightOut = new THREE.Object3D();
        console.log("Light type not supported");
        break;
    }
  // lightOut.layers.enable(0); //Enabled by default
  // lightOut.layers.enable(1); //Enabled by default
  lightOut.castShadow = true;

  var d = 10;

  lightOut.shadow.camera.left = -d;
  lightOut.shadow.camera.right = d;
  lightOut.shadow.camera.top = d;
  lightOut.shadow.camera.bottom = -d;

  lightOut.shadow.camera.near = 0.001;
  lightOut.shadow.camera.far = 1000;

  lightOut.shadow.mapSize.width = 1024;
  lightOut.shadow.mapSize.height = 1024;

  lightOut.shadow.bias = -0.0001;

  // console.log(lightOut);
  return lightOut;
}

export function convertCamera(cameraIn) {
  let cameraOut = new THREE.Object3D();
  if (cameraIn) {
    switch (cameraIn.type) {
      case 1 /* Perspective */:
        cameraOut = new THREE.PerspectiveCamera(
          cameraIn.fov,
          cameraIn.aspect,
          cameraIn.near,
          cameraIn.far
        );
        break;
      case 2 /* Orthographic */:
        cameraOut = new THREE.OrthographicCamera(
          cameraIn.left,
          cameraIn.right,
          cameraIn.top,
          cameraIn.bottom,
          cameraIn.near,
          cameraIn.far
        );
        break;
      default:
        console.log("Camera type not supported");
        break;
    }
  } else {
    console.log("Null camera");
  }

  return cameraOut;
}

export function convertPointLight() {}

//Add visuals to show where in the 3D scene the lights are located
export function addObjectHelpers(threeObject) {
  let threeHelper = null;
  //Add visualisations for each of the light types
  if (threeObject.type == "PointLight") {
    //Point Light
    var helperSize = 0.25;
    threeHelper = new THREE.PointLightHelper(threeObject, helperSize);
    setUpLightHelper(threeHelper);
  } else if (threeObject.type == "SpotLight") {
    threeHelper = new THREE.SpotLightHelper(threeObject);
    setUpLightHelper(threeHelper);
  } else if (threeObject.type == "DirectionalLight") {
    var helperSize = 0.5;
    threeHelper = new THREE.DirectionalLightHelper(threeObject, helperSize);
    setUpLightHelper(threeHelper);
  } else if (
    threeObject.type == "OrthographicCamera" ||
    threeObject.type == "PerspectiveCamera"
  ) {
    threeHelper = new THREE.CameraHelper(threeObject);
    setUpCamHelper(threeHelper);
  }
  return threeHelper;
}

function setUpLightHelper(threeHelper) {
  threeHelper.name = "LightHelper";
  threeHelper.layers.set(2);
  threeHelper.traverse(function (object) {
    object.layers.set(2);
  });
  return threeHelper;
}

function setUpCamHelper(threeHelper) {
  threeHelper.name = "CamHelper";
  threeHelper.layers.set(3);
  threeHelper.traverse(function (object) {
    object.layers.set(3);
  });
  return threeHelper;
}

export function convertColor(c) {
  // console.log(">Color");
  // console.log(c);
  const s = new THREE.Color(c.r, c.g, c.b);
  // console.log(s);
  return s;
}

export function convertToLightState(spaceNode, platform) {
  // console.log("convertToState");
  // console.log(spaceNode);
  // console.log("platform: " + platform);
  const unityType = ["none", "spot", "directional", "point", "area"];
  const blenderType = ["none", "point", "spot", "sun", "area"];
  const webType = [
    "none",
    "ambient",
    "point",
    "directional",
    "hemisphere",
    "rectarea",
    "spot",
  ];
  let platformData = null;
  if (spaceNode && spaceNode.lightData && spaceNode.lightData[platform]) {
    platformData = { ...spaceNode.lightData[platform] };
    if (platform == "unity") {
      // console.log("type: " + platformData.type)
      // console.log("type: " + unityType[platformData.type])
      platformData.type = unityType[platformData.type];
    } else if (platform == "blender") {
      // console.log("type: " + platformData.type);
      // console.log("type: " + blenderType[platformData.type]);
      platformData.type = blenderType[platformData.type];
    } else if (platform == "web") {
      platformData.type = webType[platformData.type];
    }
  }
  // console.log("platformData");
  // console.log(platformData);
  return platformData;
}

export function convertToCameraState(spaceNode, platform) {
  // console.log("convertToState");
  // console.log(spaceNode);
  // console.log("platform: " + platform);
  // const unityType = ["none", "perspective", "orthographic"];
  const blenderType = ["none", "perspective", "orthographic", "panoramic"];
  const blenderSensorFit = ["none", "auto", "horiztonal", "vertical"];

  const webType = ["none", "perspective", "orthographic"];

  const unityClearFlags = [
    "none",
    "skybox",
    "solidcolor",
    "depthonly",
    "dontclear",
  ];
  const unityGateFit = [
    "none",
    "none",
    "vertical",
    "horizonal",
    "fill",
    "overscan",
  ];
  const unityRenderingPath = [
    "none",
    "useplayersettings",
    "vertexlit",
    "forward",
    "deferredlighting",
    "deferredshading",
  ];
  const unityTargetEye = ["none", "none", "left", "right", "both"];

  let platformData = null;
  if (spaceNode && spaceNode.cameraData && spaceNode.cameraData[platform]) {
    platformData = { ...spaceNode.cameraData[platform] };
    if (platform == "unity") {
      // console.log("type: " + platformData.type)
      // console.log("type: " + unityType[platformData.type])
      platformData.type = platformData.orthographic
        ? "orthographic"
        : "perspective";
      platformData.clearflags = unityClearFlags[platformData.clearflags];
      platformData.gatefit = unityGateFit[platformData.gatefit];
      platformData.renderingpath =
        unityRenderingPath[platformData.renderingpath];
      platformData.stereotargeteye =
        unityTargetEye[platformData.stereotargeteye];
    } else if (platform == "blender") {
      // console.log("type: " + platformData.type);
      // console.log("type: " + blenderType[platformData.type]);
      platformData.type = blenderType[platformData.type];
      platformData.sensorfit = blenderSensorFit[platformData.sensorfit];
    } else if (platform == "web") {
      platformData.type = webType[platformData.type];
    }
  }
  // console.log("platformData");
  // console.log(platformData);
  return platformData;
}

export function convertToState(spaceNode, platform) {
  switch (spaceNode.type) {
    case "light":
      return convertToLightState(spaceNode, platform);
      break;
    case "camera":
      return convertToCameraState(spaceNode, platform);
      break;
  }
  return null;
}

export function convertMeshFlat(spaceMesh) {
  let vertices = [];
  let faces = [];

  // Vertices
  let originalVertices = spaceMesh
    .getVerticesList()
    .map((v) => new THREE.Vector3(v.getX(), v.getY(), v.getZ()));

  // Get verts from faces
  spaceMesh.getFacesList().forEach((f, i) => {
    let faceIndices = f.getIndicesList();
    let indexCount = faceIndices.length;

    const v0 = originalVertices[faceIndices[0]];
    const v1 = originalVertices[faceIndices[1]];
    const v2 = originalVertices[faceIndices[2]];
    const q1 = new THREE.Vector3().subVectors(v1, v0);
    const q2 = new THREE.Vector3().subVectors(v2, v0);
    let faceNormal = new THREE.Vector3().crossVectors(q1, q2);
    faceNormal.normalize();

    // create new vertices
    let startIndex = vertices.length;
    for (let vertexIndex = 0; vertexIndex < indexCount; vertexIndex++) {
      vertices.push(originalVertices[faceIndices[vertexIndex]]);
    }

    // Fan triangulation of n-gons
    for (let i = 0; i < indexCount - 2; i++) {
      let a = startIndex + 0,
        b = startIndex + i + 1,
        c = startIndex + i + 2;
      let vertexNormals = [faceNormal, faceNormal, faceNormal];

      let tf = new THREE.Face3(a, b, c, vertexNormals);
      faces.push(tf);
    }
  });

  let localGeo = new THREE.Geometry();
  localGeo.vertices = vertices;
  localGeo.faces = faces;

  return localGeo;
}

// convert mesh flat shader
export function convertMesh(spaceMesh, flatShaded = false) {
  if (flatShaded) {
    return convertMeshFlat(spaceMesh);
  } else {
    return convertMeshSmooth(spaceMesh);
  }

  return null;
}

export function convertMeshSmooth(spaceMesh) {
  let LocalGeo = new THREE.Geometry();

  // Vertices
  spaceMesh.getVerticesList().forEach((v, i) => {
    let vec = new THREE.Vector3(v.getX(), v.getY(), v.getZ());
    LocalGeo.vertices.push(vec);
    //console.log(vec)
  });

  // Normals
  let Normals = [];
  spaceMesh.getNormalsList().forEach((n, i) => {
    Normals.push(new THREE.Vector3(n.getX(), n.getY(), n.getZ()));
  });

  // Faces
  spaceMesh.getFacesList().forEach((f, i) => {
    let FaceIndices = f.getIndicesList();
    let IndexCount = FaceIndices.length;
    // Fan triangulation of n-gons
    for (let i = 0; i < IndexCount - 2; i++) {
      let a = 0,
        b = i + 1,
        c = i + 2;
      let VertexNormals = [
        Normals[FaceIndices[a]],
        Normals[FaceIndices[b]],
        Normals[FaceIndices[c]],
      ];
      let tf = new THREE.Face3(
        FaceIndices[a],
        FaceIndices[b],
        FaceIndices[c],
        VertexNormals
      );
      LocalGeo.faces.push(tf);
      //console.log(tf)
    }
  });

  return LocalGeo;
}

export function convertTransform(spaceNode, remoteTransform) {
  if (spaceNode == null) {
    let err =
      "updateNodeTransform(spaceNode, spaceTransform) spaceNode cannot be null";
    console.error(err);
    throw err;
  }
  if (remoteTransform == null) {
    let err =
      "updateNodeTransform(spaceNode, spaceTransform) spaceTransform cannot be null";
    console.error(err);
    throw err;
  }
  // Get Protobuf types
  let remotePosition = remoteTransform.getPosition();
  let remoteRotation = remoteTransform.getRotation();
  let remoteScale = remoteTransform.getScale();

  // Convert to Three types
  let threePosition = new THREE.Vector3(
    remotePosition.getX(),
    remotePosition.getY(),
    remotePosition.getZ()
  );
  let threeRotation = new THREE.Quaternion(
    remoteRotation.getX(),
    remoteRotation.getY(),
    remoteRotation.getZ(),
    remoteRotation.getW()
  );
  let threeScale = new THREE.Vector3(
    remoteScale.getX(),
    remoteScale.getY(),
    remoteScale.getZ()
  );

  // // Cameras & lights need an extra transformation applied
  // if (spaceNode.type == "camera" || spaceNode.type == "light") {
  //   let Q = new THREE.Quaternion();
  //   Q.setFromAxisAngle(new THREE.Vector3(0, 1, 0), Math.PI);
  //   threeRotation.multiply(Q);
  // }

  return {
    position: threePosition,
    rotation: threeRotation,
    scale: threeScale,
  };
}

export function applyTransform(spaceNode, localTransform) {
  // Argument checking
  if (spaceNode == null) {
    let err =
      "updateNodeTransform(spaceNode, spaceTransform) spaceNode cannot be null";
    console.error(err);
    throw err;
  }
  if (localTransform == null) {
    let err =
      "updateNodeTransform(spaceNode, spaceTransform) spaceTransform cannot be null";
    console.error(err);
    throw err;
  }

  // Apply to three object
  spaceNode.threeObject.position.copy(localTransform.position);
  spaceNode.threeObject.quaternion.copy(localTransform.rotation);
  spaceNode.threeObject.scale.copy(localTransform.scale);

  // Update cached transform
  spaceNode.transform = localTransform;
}

// var rgbToHex = function (rgb) {
//     let hex = Number(rgb).toString(16);
//     if (hex.length < 2) {
//          hex = "0" + hex;
//     }
//     return hex;
//   };

//   var fullColorHex = function(r,g,b) {
//     let red = rgbToHex(r);
//     let green = rgbToHex(g);
//     let blue = rgbToHex(b);
//     return red+green+blue;
//   };
