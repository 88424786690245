// React
import React from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

// Massless
import lightPanel from "./LightPanels";
import { Panel } from "./Panel";
import * as Utility from "../../massless/Utility";

const useStyles = makeStyles((theme) => ({
  inspectSectionTitle: { fontSize: "0.75rem" },
  sectionTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.875rem",
  },
}));

export function LightInspector(props) {
  const classes = useStyles();

  const [lightState, setLightState] = React.useState(null);

  React.useEffect(() => {
    const newState = Utility.convertToState(props.spaceNode, props.platform);
    setLightState(newState);
  }, [props.spaceNode, props.platform]);

  return (
    <Box>
      <Typography className={classes.inspectSectionTitle}>
        Light Properties
      </Typography>

      {lightState != null && (
        <Panel panel={lightPanel[props.platform]} state={lightState} />
      )}
      {lightState == null && <Typography>No data</Typography>}
    </Box>
  );
}
