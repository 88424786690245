// React
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

// Material-UI
import { makeStyles, withTheme, withStyles } from "@material-ui/core/styles";

import { Container, Box, Button } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Massless
// import { makeStyles } from "../Style";
import { useAuth } from "../context/AuthContext";
import { useSpace } from "../context/SpaceContext";
import { BreadcrumbGenerator } from "../components/BreadcrumbGenerator"

import {
  sAnalyticsLoad,
  sAnalyticsTrack,
} from "../massless/AnalyticsClient";

import NewProjectGrid from "../components/ProjectGrid/ProjectGrid";
// import {MasslessButton} from "../uiComponents/masslessButton";

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    maxWidth: "none",
    padding: "20px 20px 20px 20px",
    color: "white",
  },
  studioInfo: {
    padding: "0 0 10px 0",
  },
  pageTitle: {
    color: "#FFFFFF",
  },
  newProjButton: {
    height: "30px",
    border: "solid 2px #5423e6",
    borderRadius: "20px",
    marginLeft: "10px",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#5423e6",
      border: "solid 2px #5423e6",
    },
    alignSelf: "center",
  },
}));

function validateProduction() {
  console.log("Validate");
  let hasTarget = false;
  let valid = true;
  // Make sure production name isn't empty
  if (
    valid &&
    this.newProjectNameInput != null &&
    this.newProjectNameInput.value.length == 0
  ) {
    valid = false;
    this.focusNewProjectName();
  }
  // Make sure target platform has been selected
  if (hasTarget) {
    if (
      valid &&
      this.newProjectPlatformSelect != null &&
      this.newProjectPlatformSelect.value == ""
    ) {
      valid = false;
      this.focusNewProjectPlatform();
    }
  }
  // // Create production if valid
  // if (valid) {
  //   this.createProduction();
  // }
  return true;
}

export default function StudioPage() {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const { getGlobalStudioReference, createProject } = useSpace();
  let history = useHistory();

  const [newProjectFormOpen, setNewProjectFormOpen] = React.useState(false);
  const [newProjectTitle, setNewProjectTitle] = React.useState("");
  const [newProjectDescription, setNewProjectDescription] = React.useState("");

  const {
    profile
  } = useAuth();

  const trackUser = (trackId) => {
    if (profile == null) return;
    let userId = profile.sub.split("|")[1];
    sAnalyticsTrack(trackId,{id: userId, email: profile.email});
  }

  React.useEffect(() => {
    if (profile == null) {
      trackUser("global-studio-signed-out")
      return;
    }
    let userId = profile.sub.split("|")[1];
    // sAnalyticsIdentify(userId, { name: profile.name, email: profile.email });
    trackUser("global-studio-signed-in")
  }, [profile]);

  // React.useEffect(() => {
  //   sAnalyticsPage();
  // },[])

  const titleChange = (e) => {
    setNewProjectTitle(e.target.value);
  };

  const descriptionChange = (e) => {
    setNewProjectDescription(e.target.value);
  };

  const createProjectClicked = async () => {
    let globalStudioReference = await getGlobalStudioReference();

    setNewProjectFormOpen(false);
    let projectInfo = {
      studioId: globalStudioReference,
      title: newProjectTitle,
      description: newProjectDescription,
    };

    createProject(projectInfo)
      .then(async (projectRef) => {
        console.log(projectRef);
        history.replace(
          "/" + globalStudioReference + "/" + projectRef.getProjectid()
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };


  return (
    <>
      <Container className={classes.bodyContainer}>

        {isAuthenticated && (
          <>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              padding="0 0 10px 0"
            >
              <Box className={classes.pageTitle} flexGrow="1">
                { <BreadcrumbGenerator studioId="global" /> }
              </Box>

              <Button
                className={classes.newProjButton}
                variant="outlined"
                color="secondary"
                onClick={() => {
                  trackUser("new-project-clicked")
                  setNewProjectFormOpen(true);
                }}
              >
                New Project
              </Button>
              <Dialog
                open={newProjectFormOpen}
                onClose={() => {
                  trackUser("new-project-dialog-close")
                  setNewProjectFormOpen(false);
                }}
              >
                <DialogTitle id="form-dialog-title">
                  Create a New Project
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    To create a new Project, please enter some details below
                  </DialogContentText>
                  <TextField
                    onChange={titleChange}
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Your Project Title"
                    type="text"
                    fullWidth
                    color="secondary"
                  />
                  <TextField
                    onChange={descriptionChange}
                    margin="dense"
                    id="description"
                    label="What is your project about?"
                    type="text"
                    fullWidth
                    color="secondary"
                    multiline
                    rows={3}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      trackUser("new-project-cancel-click")
                      setNewProjectFormOpen(false);
                    }}
                    color="text.primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={async () => {
                      trackUser("new-project-create-click")
                      await createProjectClicked();
                    }}
                    color="secondary"
                  >
                    Create
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
            <NewProjectGrid />
          </>
        )}
      </Container>
    </>
  );
}
