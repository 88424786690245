// React
import React from "react";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//Material-UI Components
import { Button } from "@material-ui/core";

export function NewSpaceDialog(props) {
  const [newSpaceName, setNewSpaceName] = React.useState(false);
  const [newSpaceDescription, setNewSpaceDescription] = React.useState(false);

  const render = () => {
    return (
      <>
        <Dialog
          open={props.open}
          onClose={() => {
            props.callbacks.trackUser("new-space-dialog-close", {
              studioId: props.callbacks.projectInfo.studioId,
              projectId: props.callbacks.projectInfo.projectId,
            });
            props.callbacks.setNewSpaceFormOpen(false);
          }}
        >
          <DialogTitle id="form-dialog-title">Create a New Space</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To create a new Space, please enter some details below
            </DialogContentText>
            <TextField
              color="primary"
              onChange={(e) => setNewSpaceName(e.target.value)}
              autoFocus
              margin="dense"
              id="title"
              label="Space Name"
              type="text"
              fullWidth
            />
            <TextField
              color="primary"
              onChange={(e) => setNewSpaceDescription(e.target.value)}
              margin="dense"
              id="description"
              label="Description"
              type="text"
              fullWidth
              multiline
              rows={3}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                props.callbacks.trackUser("new-space-dialog-cancel-click", {
                  studioId: props.callbacks.projectInfo.studioId,
                  projectId: props.callbacks.projectInfo.projectId,
                });
                props.callbacks.setNewSpaceFormOpen(false);
              }}
              color="text.primary"
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                props.callbacks.trackUser("new-space-create-space-click", {
                  studioId: props.callbacks.projectInfo.studioId,
                  projectId: props.callbacks.projectInfo.projectId,
                });
                await props.callbacks.createSpaceClicked(
                  newSpaceName,
                  newSpaceDescription
                );
              }}
              color="primary"
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  return render();
}
