// React
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";

// Material
import {
  makeStyles,
  withStyles,
  rgbToHex,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Avatar from "@material-ui/core/Avatar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PowerIcon from "@material-ui/icons/Power";
import PublicIcon from "@material-ui/icons/Public";

import SvgIcon from "@material-ui/core/SvgIcon";

import clsx from "clsx";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DocsIcon from "@material-ui/icons/Description";
import LearnIcon from "@material-ui/icons/MenuBook";

// Massless
// import authPasswordless from "../AuthPasswordless";
// import { useStyles } from "../Style";
import { useAuth } from "../context/AuthContext";
import { useCommands } from "../context/CommandBarContext";

import { gAnalytics } from "../massless/AnalyticsClient";

import MasslessLogo1x from "../imgs/MasslessLogo@1x.png";
import MasslessLogo2x from "../imgs/MasslessLogo@2x.png";
import { Tooltip } from "@material-ui/core";

import { sAnalyticsTrack, trackUser } from "../massless/AnalyticsClient";

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    borderBottom: "solid 2px #393384",
    background: "#222343",
    boxShadow: "none",
    height: "50px",
  },
  masslessLogo: {
    display: "block",
  },
  preLoginNav: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexGrow: 1,
      flexDiection: "row-reverse",
      width: "100%",
    },
  },
  postLoginNav: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flex: "auto",
    },
  },
  menuButton: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      marginLeft: "auto",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    marginLeft: theme.spacing(2),
    justifyContent: "flex-end",
  },
  menuButtonContainer: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "row-reverse",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    marginLeft: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  version: {
    flexGrow: 1,

    marginLeft: "10px",
    fontSize: "10px",
    lineHeight: "12px",
    color: "rgba(255, 255, 255, 0.2)",
  },
  toolBar: {
    alignContent: "center",
    display: "flex",
    minHeight: "50px",
    [theme.breakpoints.up("xs")]: {
      padding: "0 16px 0 16px",
    },
  },
  navButton: {
    height: "30px",
    border: "solid 2px #5423e6",
    borderRadius: "20px",
    marginLeft: "10px",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#5423e6",
      border: "solid 2px #5423e6",
    },
  },
  userName: {
    flexGrow: "1",
    color: "#ffffff",
    textAlign: "end",
    alignSelf: "center",
  },
  userAvatar: {
    width: "30px",
    height: "30px",
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
    cursor: "pointer",
    alignSelf: "center",
  },
  midMenu: {
    display: "flex",
  },
  navLink: {
    color: "#000",
  },
  navTabs: {
    minHeight: "50px",
  },
  navLabels: {
    textTransform: "lowerCase",
  },
  navTabsIndicator: {
    backgroundColor: "#3a3384",
    height: "5px",
    borderRadius: "10px 10px 0 0",
  },
  list: {
    width: "250",
  },
  fullList: {
    width: "auto",
  },
  toolTip: {
    fontSize: "14px",
    backgroundColor: "#050510",
    borderRadius: "8px",
  },
  button: {
    margin: "0 5px",
    textTransform: "none",
    fontWeight: 400,
  },
}));

function BlenderIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M18.99,10.96c0.04,0.82-0.29,1.59-0.87,2.15c-0.59,0.58-1.43,0.94-2.37,0.94c-0.94,0-1.78-0.36-2.38-0.94	
	c-0.58-0.57-0.91-1.33-0.86-2.15c0.04-0.8,0.44-1.51,1.03-2.01c0.58-0.49,1.36-0.79,2.21-0.79c0.85,0,1.63,0.3,2.21,0.79	
	C18.55,9.46,18.95,10.16,18.99,10.96z M23.98,12.41c-0.04,0.52-0.14,1.03-0.3,1.54c-0.32,1.01-0.85,1.94-1.59,2.76	
	c-0.76,0.84-1.7,1.52-2.78,2.01c-1.14,0.51-2.38,0.77-3.66,0.77c-1.28,0-2.52-0.26-3.66-0.76c-1.08-0.48-2.02-1.16-2.79-2	
	c-0.74-0.82-1.28-1.75-1.59-2.76c-0.15-0.48-0.25-1.08-0.26-1.4c-0.01-0.31,0.06-0.83,0.06-0.83l-4.7,3.85	
	c-0.82,0.66-1.93,0.66-2.45,0c-0.51-0.66-0.25-1.69,0.56-2.31c0.01-0.01,0.02-0.02,0.03-0.02l8.62-6.62L4.65,6.65l0,0	
	c-0.82,0-1.35-0.55-1.2-1.22C3.6,4.77,4.36,4.26,5.15,4.25h0.01l9.53-0.01L11.56,1.7l0,0c-0.49-0.4-0.42-1.03,0.12-1.41	
	c0.54-0.38,1.37-0.38,1.86,0c0.01,0,0.01,0.01,0.02,0.02l7.5,5.76l0,0c0.74,0.58,1.37,1.26,1.85,2.04c0.53,0.85,0.87,1.78,1.02,2.78	
	C24,11.39,24.02,11.9,23.98,12.41z M20.71,10.88c-0.05-0.61-0.23-1.18-0.52-1.71c-0.28-0.51-0.67-0.98-1.15-1.37	
	c-0.94-0.77-2.14-1.18-3.4-1.18c-1.25,0-2.45,0.42-3.4,1.19c-0.48,0.39-0.87,0.86-1.15,1.37c-0.29,0.52-0.47,1.1-0.52,1.71	
	c-0.05,0.62,0.03,1.23,0.24,1.81c0.21,0.59,0.56,1.14,1.04,1.62c0.97,0.99,2.33,1.54,3.8,1.54c1.47,0,2.83-0.56,3.8-1.55	
	c0.47-0.48,0.82-1.04,1.04-1.63C20.69,12.11,20.77,11.5,20.71,10.88z"
      />
    </SvgIcon>
  );
}

function UnityIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M19.95,19.25L15.65,12l4.31-7.25L22.03,12L19.95,19.25z M10.36,18.56l-5.42-5.38h8.61l4.3,7.25L10.36,18.56z	
	 M10.36,5.45l7.5-1.87l-4.3,7.25H4.94L10.36,5.45z M21.37,0l-9.8,2.55l-1.45,2.49L7.18,5.02L0,12l7.18,6.98l2.94-0.02l1.45,2.49	
	l9.8,2.55L24,14.47L22.51,12L24,9.53L21.37,0z"
      />
    </SvgIcon>
  );
}

export default function ButtonAppBar(props) {
  /* Analytics ********************************************************************/

  let { authStage, profile, isAuthenticated, signOut } = useAuth();

  /* Nav ************************************************************************************************/

  const classes = useStyles();
  let history = useHistory();
  //let { open, setOpen } = useSnackbar();

  let [nickname, setNickname] = React.useState("");
  let [picture, setPicture] = React.useState("");

  React.useEffect(() => {
    if (profile == null) return;
    setNickname(profile.nickname);
    setPicture(profile.picture);
  }, [profile]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const preLoginMenu = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          {
            label: "Learn More",
            link: "https://massless.io/massless-space/",
            id: "nav-learn-more-click",
            icon: <LearnIcon />,
          },
          {
            label: "Docs",
            link: "https://docs.space.massless.io/",
            id: "nav-learn-more-click",
            icon: <DocsIcon />,
          },
        ].map((buttonInfo) => (
          <ListItem
            button
            key={buttonInfo.label}
            component="a"
            href={buttonInfo.link}
            onClick={() => {
              trackUser(buttonInfo.id);
            }}
          >
            <ListItemIcon color="white">{buttonInfo.icon}</ListItemIcon>
            <ListItemText primary={buttonInfo.label} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const postLoginMenu = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {/* {['Projects', 'Plugins', 'Docs'].map((text, index) => ( */}
        <ListItem button key="Account" component={Link} to="/account">
          <ListItemIcon color="white">
            <Avatar
              alt="User Avatar"
              className={classes.userAvatar}
              src={picture}
            />
          </ListItemIcon>
          <ListItemText primary={nickname} />
        </ListItem>
        <Divider />
        <ListItem button key="Projects" component={Link} to="/">
          <ListItemIcon color="white">
            <PublicIcon />
          </ListItemIcon>
          <ListItemText primary="Global Studio" />
        </ListItem>
        <ListItem button key="Plugins" component={Link} to="/plugins">
          <ListItemIcon color="white">
            <PowerIcon />
          </ListItemIcon>
          <ListItemText primary="Plugins" />
        </ListItem>
        <ListItem
          button
          key="Docs"
          component="a"
          href="https://docs.space.massless.io/"
          target="blank"
        >
          <ListItemIcon color="white">
            <DocsIcon />
          </ListItemIcon>
          <ListItemText primary="Docs" />
        </ListItem>
        {/* ))} */}
      </List>
    </div>
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Sets the Tab Indicator to the right position on page refresh
  useEffect(() => {
    let path = location.pathname;
    if (path === "/" && value !== 0) setValue(0);
    else if (path === "/plugins" && value !== 1) setValue(1);
  }, [value]);

  let location = useLocation();

  const handleProfileMenuOpen = () => {
    console.log("profile click");
    signOut();
    history.replace("/");
  };

  /* Commands ****************************************************************/
  const { registerCommands } = useCommands();

  const addCommands = () => {
    registerCommands([
      {
        id: "explore",
        name: "Explore",
        description: "Explore Spaces",
        shortcut: "",
        command: () => {
          history.replace("/");
        },
      },
      {
        id: "connect-to-unity",
        name: "Connect to Unity",
        description: "Download Unity Plugin",
        shortcut: "",
        command: () => {
          window.open(process.env.REACT_APP_UNITY_PLUGIN);
          window.open("https://docs.space.massless.io/getting-started#unity");
        },
      },
      {
        id: "connect-to-blender",
        name: "Connect to Blender",
        description: "Download Blender Addon",
        shortcut: "",
        command: () => {
          window.open(process.env.REACT_APP_BLENDER_PLUGIN);
          window.open("https://docs.space.massless.io/getting-started#blender");
        },
      },
      {
        id: "documentation",
        name: "Documentation",
        description: "Open documentation",
        shortcut: "",
        command: () => {
          window.open("https://docs.space.massless.io/");
        },
      },
      {
        id: "plugins",
        name: "Plugins",
        description: "Open plugins page",
        shortcut: "",
        command: () => {
          history.replace("/plugins");
        },
      },
      {
        id: "account",
        name: "Account",
        description: "Open account page",
        shortcut: "",
        command: () => {
          history.replace("/account");
        },
      },
    ]);
  };

  /* On Mount ****************************************************************/

  React.useEffect(() => {
    trackUser("login-page-view");
    addCommands();
  }, []);

  return (
    <>
      <div className={classes.root}>
        {/* <ThemeProvider theme={theme}> */}
        <AppBar className={classes.appBar} position="static">
          <Toolbar className={classes.toolBar}>
            <Box display="flex" alignItems="center" style={{ width: "100%" }}>
              <Box
                display="flex"
                alignItems="center"
                style={{ marginRight: "16px" }}
              >
                <Tooltip
                  classes={{ tooltip: classes.toolTip }}
                  title={process.env.REACT_APP_VERSION}
                >
                  <Link
                    to="/"
                    onClick={() => {
                      trackUser("nav-bar-massless-logo-click");
                    }}
                  >
                    <img
                      className={classes.masslessLogo}
                      src={MasslessLogo1x}
                      srcSet={MasslessLogo1x + " 1x," + MasslessLogo2x + " 2x"}
                    />
                  </Link>
                </Tooltip>
                {/* <Typography variant="h6" className={classes.version}>
                  {process.env.REACT_APP_VERSION}
                </Typography> */}
              </Box>
              {/* PreLogin Buttons */}
              {!isAuthenticated && (
                <>
                  <div className={classes.preLoginNav}>
                    <Box flexGrow="1"></Box>
                    <Box>
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.navButton}
                        href="https://massless.io/massless-space"
                        target="_blank"
                        style={{ textTransform: "none" }}
                        onClick={() => {
                          gAnalytics.track("landing-page-learn-more-click", {});
                        }}
                      >
                        Learn more
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.navButton}
                        style={{ textTransform: "none" }}
                        href="https://docs.space.massless.io/"
                        target="_blank"
                        onClick={() => {
                          gAnalytics.track("landing-page-docs-click", {});
                        }}
                      >
                        Docs
                      </Button>
                    </Box>
                  </div>
                  <div className={classes.menuButtonContainer}>
                    {["right"].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <Button
                          onClick={toggleDrawer(anchor, true)}
                          edge="start"
                          className={classes.menuButton}
                          color="inherit"
                          aria-label="menu"
                        >
                          <MenuIcon />
                        </Button>
                        <Drawer
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                        >
                          {preLoginMenu(anchor)}
                        </Drawer>
                      </React.Fragment>
                    ))}
                  </div>
                </>
              )}
              {/* PostLogin Buttons */}
              {isAuthenticated && (
                <>
                  <Box className={classes.postLoginNav}>
                    <Box flexGrow="1">
                      <Link to="/">
                        <Button
                          onClick={() => {
                            trackUser("nav-explore-click");
                          }}
                          className={classes.button}
                          startIcon={<PublicIcon />}
                        >
                          Explore
                        </Button>
                      </Link>
                    </Box>
                    <Box display="flex">
                      <Box>
                        <Button
                          href={process.env.REACT_APP_UNITY_PLUGIN}
                          onClick={() => {
                            window.open(
                              "https://docs.space.massless.io/getting-started#unity"
                            );
                            trackUser("nav-bar-unity-plugin-button-click");
                          }}
                          className={classes.button}
                          startIcon={<UnityIcon />}
                        >
                          Connect to Unity
                        </Button>
                        <Button
                          href={process.env.REACT_APP_BLENDER_PLUGIN}
                          onClick={() => {
                            window.open(
                              "https://docs.space.massless.io/getting-started#blender"
                            );
                            trackUser("nav-bar-blender-plugin-button-click");
                          }}
                          className={classes.button}
                          startIcon={<BlenderIcon />}
                        >
                          Connect to Blender
                        </Button>
                        <Link to="/plugins">
                          <Button
                            onClick={() => {
                              trackUser("nav-plugins-click");
                            }}
                            className={classes.button}
                            startIcon={<PowerIcon />}
                          >
                            Plugins
                          </Button>
                        </Link>
                        <Button
                          href="https://docs.space.massless.io/"
                          target="_blank"
                          onClick={() => {
                            trackUser("nav-docs-click");
                          }}
                          className={classes.button}
                          startIcon={<DocsIcon />}
                        >
                          Docs
                        </Button>
                      </Box>
                      <Box display="flex">
                        {/* <a onClick={() => {header.signOut();}}> */}
                        <Avatar
                          alt="User Avatar"
                          className={classes.userAvatar}
                          src={picture}
                          component={Link}
                          to={"/account"}
                          onClick={() => {
                            trackUser("nav-avatar-click");
                          }}
                        />
                      </Box>
                      {/* <Link to="/account" className={classes.userName}>
                        {nickname}
                      </Link> */}
                    </Box>
                  </Box>

                  <div className={classes.menuButtonContainer}>
                    {["right"].map((anchor) => (
                      <React.Fragment key={anchor}>
                        <Button
                          onClick={toggleDrawer(anchor, true)}
                          edge="start"
                          className={classes.menuButton}
                          color="inherit"
                          aria-label="menu"
                        >
                          <MenuIcon />
                        </Button>
                        <Drawer
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                        >
                          {postLoginMenu(anchor)}
                        </Drawer>
                      </React.Fragment>
                    ))}
                  </div>
                </>
              )}
            </Box>
          </Toolbar>
        </AppBar>
        {/* </ThemeProvider> */}
      </div>
    </>
  );
}

{
  /* <Tabs
                    value={value}
                    onChange={handleChange}
                    centered
                    classes={{
                      indicator: classes.navTabsIndicator,
                    }}
                    className={classes.navTabs}
                    overflow="visible"
                  >
                    <Tooltip classes={{ tooltip: classes.toolTip }} title="Global Studio">
                      <Tab
                        value={0}
                        // label="Global Studio"
                        icon={<PublicIcon />}
                        to="/"
                        onClick={() => {
                          gAnalytics.track("nav-productions-click");
                        }}
                        component={Link}
                      />
                    </Tooltip>
                    <Tooltip classes={{ tooltip: classes.toolTip }}  title="Plugins">
                      <Tab
                        value={1}
                        // label="Plugins"
                        icon={<PowerIcon />}
                        to="/plugins"
                        onClick={() => {
                          gAnalytics.track("nav-plugins-click");
                        }}
                        component={Link}
                      />
                    </Tooltip>
                    <Tooltip classes={{ tooltip: classes.toolTip }}  title="Docs">
                      <Tab
                        value={2}
                        // label="Docs"
                        icon={<DocsIcon />}
                        href="https://docs.space.massless.io/"
                        target="_blank"
                        onClick={() => {
                          gAnalytics.track("nav-docs-signed-in-click");
                        }}
                      />
                    </Tooltip>
                  </Tabs> */
}
