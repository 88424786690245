/*

This component constructs an "Inspector Panel" type user interface
from a JSON  definition, see LightPanels.json for an example definition.

The Panel is definied by a set of properties. A PanelRow renders a label and a 
PanelInput that is set by the "input" field of the property.

Changing the value of the property can trigger the rendering of a set of child or display properties.
Child properties are nested and Display properties appear after without nesting.

The Panel maintains a panelState dictionary of propertyIds and values.
The panelState is updated when a property is changed, and the panel will render
based on the current panelState.

This allows for efficient transmission of complex panel states.

*/

// React
import React from "react";

import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

import { withStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inspectSectionTitle: { fontSize: "0.75rem" },
  sectionTitle: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.875rem",
  },
  panelInput: { fontSize: "0.7rem" },
}));

const StyledTextBox = withStyles({
  root: {
    input: {
      padding: "2px 8px",
    },
  },
})(TextField);

const StyledSelect = withStyles({
  root: {
    width: "90%",
    padding: "2px 8px",
    fontSize: "0.7rem",
  },
})(Select);

const styledInputs = {
  textField: (property, value, onChange = null) => {
    return (
      <StyledTextBox
        inputProps={{
          style: {
            fontSize: "0.7rem",
            padding: "4px 8px",
          },
        }}
        value={
          property.fixed
            ? Number.parseFloat(value).toFixed(property.fixed)
            : value
        }
        variant="outlined"
        color="primary"
        fullWidth
        onChange={(e) => {
          onChange(e.target.value);
        }}
        disabled
      ></StyledTextBox>
    );
  },
  vector2: (property, value, onChange = null) => {
    return (
      <Box display="flex" flexDirection="row">
        <Typography
          style={{
            fontSize: "0.7rem",
            display: "flex",
            alignSelf: "center",
            color: "rgba(255,255,255,0.25)",
          }}
        >
          X:
        </Typography>{" "}
        <StyledTextBox
          style={{ marginLeft: "8px", marginRight: "8px" }}
          inputProps={{
            style: {
              fontSize: "0.7rem",
              padding: "4px 8px",
              width: "48%",
            },
          }}
          value={
            property.fixed
              ? Number.parseFloat(value.x).toFixed(property.fixed)
              : value.x
          }
          variant="outlined"
          color="primary"
          fullWidth
          onChange={(e) => {
            onChange(e.target.value);
          }}
          disabled
        />
        <Typography
          style={{
            fontSize: "0.7rem",
            display: "flex",
            alignSelf: "center",
            color: "rgba(255,255,255,0.25)",
          }}
        >
          Y:
        </Typography>
        <StyledTextBox
          style={{ marginLeft: "8px" }}
          inputProps={{
            style: {
              fontSize: "0.7rem",
              padding: "4px 8px",
            },
          }}
          value={
            property.fixed
              ? Number.parseFloat(value.y).toFixed(property.fixed)
              : value.y
          }
          variant="outlined"
          color="primary"
          fullWidth
          onChange={(e) => {
            onChange(e.target.value);
          }}
          disabled
        />
      </Box>
    );
  },
  rect: (property, value, onChange = null) => {
    return (
      <Box display="flex" flexDirection="row">
        <Typography
          style={{
            fontSize: "0.7rem",
            display: "flex",
            alignSelf: "center",
            color: "rgba(255,255,255,0.25)",
          }}
        >
          X:
        </Typography>{" "}
        <StyledTextBox
          style={{ marginLeft: "8px", marginRight: "8px" }}
          inputProps={{
            style: {
              fontSize: "0.7rem",
              padding: "4px 8px",
              width: "48%",
            },
          }}
          value={
            property.fixed
              ? Number.parseFloat(value.x).toFixed(property.fixed)
              : value.x
          }
          variant="outlined"
          color="primary"
          fullWidth
          onChange={(e) => {
            onChange(e.target.value);
          }}
          disabled
        />
        <Typography
          style={{
            fontSize: "0.7rem",
            display: "flex",
            alignSelf: "center",
            color: "rgba(255,255,255,0.25)",
          }}
        >
          Y:
        </Typography>
        <StyledTextBox
          style={{ marginLeft: "8px" }}
          inputProps={{
            style: {
              fontSize: "0.7rem",
              padding: "4px 8px",
            },
          }}
          value={
            property.fixed
              ? Number.parseFloat(value.y).toFixed(property.fixed)
              : value.y
          }
          variant="outlined"
          color="primary"
          fullWidth
          onChange={(e) => {
            onChange(e.target.value);
          }}
          disabled
        />
      </Box>
    );
  },
  slider: (property, value, onChange = null) => {
    return (
      <Box style={{ display: "flex", width: "100%" }}>
        <Slider
          style={{ width: "80%" }}
          color="primary"
          value={typeof value === "number" ? value : 0}
          min={property.range[0]}
          max={property.range[1]}
          step={property.range[2]}
          onChange={(e, value) => {
            onChange(value);
          }}
          valueLabelDisplay="auto"
          disabled
        />
        <Box
          style={{
            display: "flex",
            borderRadius: "4px",
            width: "20%",
            height: "24px",
            padding: "4px 8px",
            marginLeft: "8px",
          }}
          disabled
        >
          <Typography
            style={{
              fontSize: "0.7rem",
              display: "flex",
              alignSelf: "center",
              color: "rgba(255,255,255,0.25)",
            }}
          >
            {property.fixed
              ? Number.parseFloat(value).toFixed(property.fixed)
              : value}
          </Typography>
        </Box>
      </Box>
    );
  },
  color: (property, value, onChange = null) => {
    return (
      <Box
        style={{
          borderRadius: "4px",
          cursor: "pointer",
          backgroundColor: `rgb(${255 * value.r},${255 * value.g},${
            255 * value.b
          })`,
          width: "100%",
          height: "24px",
        }}
        disabled
      ></Box>
    );
  },
  select: (property, value = null, onChange = null) => {
    return (
      <StyledSelect
        inputProps={{
          style: {
            fontSize: "0.7rem",
            padding: "0px",
          },
        }}
        value={value}
        variant="outlined"
        color="primary"
        fullWidth
        onChange={(e) => {
          onChange(e.target.value);
        }}
        disabled
      >
        {property.range.map((item) => {
          return <MenuItem value={item.value}>{item.text}</MenuItem>;
        })}
      </StyledSelect>
    );
  },
  checkbox: (property, value = null, onChange = null) => {
    return (
      <Box
        style={{
          width: "100%",
          display: "flex",
          marginLeft: "-17px",
          border: "1px solid #D9DFF2",
        }}
      >
        <Checkbox
          checked={value}
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          disabled
        />
      </Box>
    );
  },
  group: (property, value = null, onChange = null) => {
    return <Box />;
  },
};

function PanelInput(props) {
  const classes = useStyles();

  React.useEffect(() => {
    console.log("PanelRow> State");
    console.log(props.state);
  }, [props.state]);

  const render = (property) => {
    let elements = (
      <Box
        style={{
          display: "flex",
          border: "1px solid #D9DFF2",
          borderRadius: "4px",
          width: "100%",
          height: "24px",
          padding: "4px 8px",
        }}
      >
        <Typography
          style={{
            fontSize: "0.7rem",
            display: "flex",
            alignSelf: "center",
            color: "rgba(255,255,255,0.25)",
          }}
        >
          Input not supported
        </Typography>
      </Box>
    );
    if (property.input in styledInputs) {
      const generator = styledInputs[property.input];
      let value = "";
      let inputType = [property.input];
      // console.log(inputType);
      if (props.propertyId in props.state) {
        value = props.state[props.propertyId];
      }
      elements = generator(property, value, props.onChange, inputType);
    }
    return elements;
  };
  return render(props.property);
}

function PanelRow(props) {
  const classes = useStyles();

  React.useEffect(() => {
    console.log("PanelRow> State");
    console.log(props.state);
  }, [props.state]);

  const render = (id, property) => {
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
          style={{ height: "20px", marginTop: "8px" }}
        >
          <Box style={{ width: "30%" }}>
            <Tooltip title={property.title}>
              <Typography
                style={{
                  fontSize: "11px",
                  lineHeight: "13px",
                  marginRight: "16px",
                }}
              >
                {property.label}
              </Typography>
            </Tooltip>
          </Box>
          <PanelInput
            className={classes.panelInput}
            propertyId={id}
            property={property}
            state={props.state}
            onChange={(value) => {
              props.setState({ ...props.state, [id]: value });
            }}
          />
        </Box>
        {property.display &&
          property.display[props.state[props.propertyId]] &&
          property.display[props.state[props.propertyId]].map(
            (propertyId, index) => {
              return (
                <PanelRow
                  key={index}
                  panel={props.panel}
                  propertyId={propertyId}
                  state={props.state}
                  setState={props.setState}
                />
              );
            }
          )}

        {property.children && property.children[props.state[props.propertyId]] && (
          <Box style={{ paddingLeft: "16px" }}>
            {property.children[props.state[props.propertyId]].map(
              (propertyId) => {
                return (
                  <PanelRow
                    panel={props.panel}
                    propertyId={propertyId}
                    state={props.state}
                    setState={props.setState}
                  />
                );
              }
            )}
          </Box>
        )}
      </>
    );
  };

  let property = null;
  if (props.propertyId in props.panel.properties) {
    property = props.panel.properties[props.propertyId];
  }

  return property ? (
    render(props.propertyId, property)
  ) : (
    <Typography>Not supported</Typography>
  );
}

export function Panel(props) {
  const classes = useStyles();

  const computeState = () => {
    console.log("computeState");
    let state = {};

    // init with defaults
    const properties = props.panel.properties;
    Object.keys(properties).forEach((key) => {
      state[key] = properties[key].default;
    });

    console.log("input state");
    console.log(props.state);
    // patch with input data
    for (const [key, value] of Object.entries(props.state)) {
      if (key in state) {
        state[key] = value;
      }
    }
    console.log("newstate");
    console.log(state);
    return state;
  };

  const [panelState, setPanelState] = React.useState(computeState());

  React.useEffect(() => {
    setPanelState(computeState());
  }, [props.state]);

  // Update the initial state if the panel description changes
  React.useEffect(() => {
    setPanelState(computeState());
  }, [props.panel]);

  React.useEffect(() => {
    console.log("panelState effect");
    console.log(panelState);
  }, [panelState]);

  const render = () => {
    return (
      <Box className={classes.panelRoot}>
        {props.panel.root.map((propertyId) => {
          return (
            <PanelRow
              panel={props.panel}
              propertyId={propertyId}
              state={panelState}
              setState={setPanelState}
            />
          );
        })}
      </Box>
    );
  };

  const render2 = () => {
    return (
      <Box>
        {props.state &&
          Object.entries(props.state).map(([key, value]) => {
            return (
              <Box>
                {key}: {JSON.stringify(value)}
              </Box>
            );
          })}
      </Box>
    );
  };

  return render();
}
