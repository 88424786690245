// React
import React from "react";

import { withStyles, makeStyles } from "@material-ui/core/styles";

// Scrollbars
import { ScrollBars } from "../ScrollBars";

// Material
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import CommentIcon from "@material-ui/icons/Comment";
import ReplyIcon from "@material-ui/icons/Reply";

import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import Badge from "@material-ui/core/Badge";
import TextField from "@material-ui/core/TextField";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

// Massless
import * as Services from "../../massless/Services";
// import { listComments } from "../../massless/Space";
import { useSpace } from "../../context/SpaceContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { useKeys } from "../../context/KeysContext";

import { Vector3 } from "three/build/three.module";

const useStyles = makeStyles((theme) => ({
  desktopRoot: {
    // backgroundColor: "#ffffff",
    // borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(1),
    overflowX: "auto",
    height: "100%",
    marginTop: "0",
    opacity: "inherit",
  },
  mobileRoot: {
    // backgroundColor: "#222343",
    // borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    overflowX: "auto",
    height: "auto%",
  },
  panelHeader: {
    margin: "8px",
  },
  listContainer: {
    padding: "0",
  },
  accordianSummary: {
    padding: "0 8px",
  },
  accordianSummaryContent: {
    display: "flex",
    flexDirection: "column",
  },
  commentDisplay: {
    display: "flex",
  },
  replyInput: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0 8px",
  },
  commentText: {
    //padding: "0 8px",
    fontSize: "0.875rem",
    lineHeight: "1rem",
    fontWeight: "300",
    marginBottom: "5px",
    overflowWrap: "anywhere",
  },
  commentDate: {
    color: "#7D8BAE",
    fontSize: "0.6rem",
  },
  commentTextRoot: {
    margin: "0",
    paddingRight: "32px",
  },
  replyRoot: { display: "flex" },
  replyListItem: {
    display: "flex",
    alignItems: "flex-start",
  },
  replyContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "flex-start",
  },
  replyTextRoot: {
    margin: "0",
  },
  resolvedCheck: {
    marginLeft: "auto",
  },
  commentThread: {
    display: "flex",
    background: "#E7EAF2",
    width: "100%",
  },
  collapse: {
    width: "100%",
  },
  collapseInner: {
    width: "100%",
    display: "flex",
  },
  commentContent: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
  },
  toolTip: {
    textTransform: "capitalize",
  },
  replyAvatar: {
    minWidth: "0",
    marginRight: "16px",
  },
  commentActions: {
    // right: "4px",
  },
  commentDivider: {
    opacity: "inherit",
    backgroundColor: "#D3DEED",
  },
  replyDivider: {
    backgroundColor: "#FFFFFF",
  },
  replyIndent: {
    background: "#a9bdf1",
    width: "8px",
    marginRight: "4px",
  },
  replyList: {
    width: "100%",
  },
  customScroll: {
    [theme.breakpoints.down("md")]: {
      height: "100vh",
      marginTop: "4px",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
      marginTop: "0",
    },
    width: "100%",
  },
}));

function ChildComment(props) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.replyRoot}>
        <Box className={classes.replyIndent}></Box>
        <Box className={classes.replyContent}>
          <ListItem
            className={classes.replyListItem}
            // button
            // onMouseEnter={() => {
            //   if (
            //     props.commentInfo &&
            //     props.commentInfo.comment.parent.commentid
            //   ) {
            //     props.callbacks.onMouseEnter(
            //       props.commentInfo.comment.parent.commentid
            //     );
            //   }
            // }}
            // onMouseLeave={() => {
            //   if (
            //     props.commentInfo &&
            //     props.commentInfo.comment.parent.commentid
            //   ) {
            //     props.callbacks.onMouseLeave(
            //       props.commentInfo.comment.parent.commentid
            //     );
            //   }
            // }}
            fullWidth
          >
            <ListItemIcon className={classes.replyAvatar}>
              <Tooltip
                classes={{ tooltip: classes.toolTip }}
                title={
                  (props.replyInfo.comment &&
                    props.replyInfo.profile.nickname) ||
                  ""
                }
              >
                <Avatar
                  alt="User Avatar"
                  className={classes.userAvatar}
                  src={
                    (props.replyInfo.comment &&
                      props.replyInfo.profile.picture) ||
                    ""
                  }
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              className={classes.replyTextRoot}
              classes={{
                primary: classes.commentText,
                secondary: classes.commentDate,
              }}
              primary={props.replyInfo.comment && props.replyInfo.comment.text}
              secondary={
                props.replyDate.getHours() +
                ":" +
                props.replyDate.getMinutes() +
                " " +
                props.replyDate.toDateString()
              }
            />
          </ListItem>
        </Box>
      </Box>
      <Divider classes={{ root: classes.replyDivider }} fullWidth />
    </>
  );
}

function CommentDisplay(props) {
  const classes = useStyles();
  const [repliesOpen, setRepliesOpen] = React.useState(true);
  const [replyInputOpen, setReplyInputOpen] = React.useState(false);

  const [commentReplies, setCommentReplies] = React.useState([]);

  function replyKeyPress(e) {
    if (e.keyCode == 86) {
      props.callbacks.setCommentText(e.target.value);
    }
    if (e.keyCode == 13) {
      props.callbacks.executeAddComment();
      setReplyInputOpen(false);
    }
  }

  React.useEffect(() => {
    props.comments
      .sort((a, b) => {
        return a.comment.createtime.seconds > b.comment.createtime.seconds
          ? 1
          : -1;
      })
      .filter(
        (c) =>
          c.comment.parent != null &&
          c.comment.parent.commentid == props.commentInfo.reference.commentid
      )
      .map((replyInfo) => {
        let replyDate = new Date();
        if (replyInfo.comment != null && replyInfo.comment.createtime) {
          replyDate = new Date(replyInfo.comment.createtime.seconds * 1000);
        }
        commentReplies.push(replyInfo);
      });
  }, []);

  const [collapsePrompt, setCollapsePrompt] = React.useState(
    "Collapse Replies"
  );

  const { bindKeys, unbindKeys, registerKeys } = useKeys();

  React.useEffect(() => {
    if (repliesOpen) setCollapsePrompt("Collapse Replies");
    else setCollapsePrompt("Expand Replies");
  }, [repliesOpen]);

  const handleRepliesOpen = () => {
    console.log(commentReplies);
    setRepliesOpen(!repliesOpen);
  };

  const handleReplyInputOpen = () => {
    setReplyInputOpen(!replyInputOpen);
  };

  return (
    <>
      <ListItem
        button
        onMouseEnter={() => {
          if (props.commentInfo && props.commentInfo.reference.commentid) {
            props.onMouseEnter(props.commentInfo.reference.commentid);
          }
        }}
        onMouseLeave={() => {
          if (props.commentInfo && props.commentInfo.reference.commentid) {
            props.onMouseLeave(props.commentInfo.reference.commentid);
          }
        }}
        onClick={(e) => {
          handleRepliesOpen();
        }}
      >
        <Box className={classes.commentContent}>
          <ListItemIcon className={classes.replyAvatar}>
            <Tooltip
              classes={{ tooltip: classes.toolTip }}
              title={
                (props.commentInfo.comment &&
                  props.commentInfo.profile.nickname) ||
                ""
              }
            >
              <Avatar
                alt="User Avatar"
                className={classes.userAvatar}
                src={
                  (props.commentInfo.comment &&
                    props.commentInfo.profile.picture) ||
                  ""
                }
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            className={classes.commentTextRoot}
            classes={{
              primary: classes.commentText,
              secondary: classes.commentDate,
            }}
            primary={
              props.commentInfo.comment && props.commentInfo.comment.text
            }
            secondary={
              props.date.getHours() +
              ":" +
              props.date.getMinutes() +
              " " +
              props.date.toDateString()
            }
          />
          <ListItemSecondaryAction className={classes.commentActions}>
            <Tooltip
              classes={{ tooltip: classes.toolTip }}
              title={"Reply to this comment"}
            >
              <IconButton
                onClick={(event) => {
                  handleReplyInputOpen();
                  event.stopPropagation();
                }}
                aria-label="reply"
                size="small"
              >
                <Badge
                  variant="dot"
                  badgeContent={commentReplies.length}
                  color="primary"
                >
                  <ReplyIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          </ListItemSecondaryAction>
        </Box>
        {/* {repliesOpen ? <ExpandLess /> : <ExpandMore />} */}
        {/* {replyInputOpen ? <ExpandLess /> : <ExpandMore />} */}
      </ListItem>
      <Divider className={classes.commentDivider} />
      <Collapse
        classes={{
          wrapper: classes.collapse,
          wrapperInner: classes.collapseInner,
        }}
        className={classes.commentThread}
        in={repliesOpen}
        timeout="auto"
        unmountOnExit
      >
        <List className={classes.replyList} component="div" disablePadding>
          {props.comments
            .sort((a, b) => {
              return a.comment.createtime.seconds > b.comment.createtime.seconds
                ? 1
                : -1;
            })
            .filter(
              (c) =>
                c.comment.parent != null &&
                c.comment.parent.commentid ==
                  props.commentInfo.reference.commentid
            )
            .map((replyInfo) => {
              let replyDate = new Date();
              if (replyInfo.comment != null && replyInfo.comment.createtime) {
                replyDate = new Date(
                  replyInfo.comment.createtime.seconds * 1000
                );
              }
              return (
                <>
                  <ChildComment replyInfo={replyInfo} replyDate={replyDate} />
                </>
              );
            })}
        </List>
      </Collapse>
      <Collapse
        classes={{
          wrapper: classes.collapse,
          wrapperInner: classes.collapseInner,
        }}
        className={classes.commentThread}
        in={replyInputOpen}
        timeout="auto"
        unmountOnExit
      >
        <Box className={classes.replyIndent} />
        <Box className={classes.replyInput}>
          <TextField
            autoFocus
            variant="filled"
            color="primary"
            onFocus={(e) => {
              props.callbacks.beginReply(
                props.commentInfo.reference.commentid,
                new Vector3(0, 0, 0)
              );
              unbindKeys();
            }}
            onBlur={(e) => {
              //setReplyInputOpen(false);
              unbindKeys();
            }}
            onChange={(e) => {
              props.callbacks.setCommentText(e.target.value);
            }}
            onKeyDown={(e) => {
              replyKeyPress(e);
            }}
            margin="dense"
            id="description"
            label="Reply to comment"
            type="text"
            fullWidth
            multiline
            rows={3}
          />
          <AccordionActions>
            <Button
              onClick={(e) => {
                setReplyInputOpen(false);
              }}
              size="small"
            >
              Cancel
            </Button>
            <Button
              onClick={async () => {
                props.callbacks.executeAddComment();
                setReplyInputOpen(false);
              }}
              size="small"
              color="primary"
            >
              Reply
            </Button>
          </AccordionActions>
        </Box>
        {/* <Divider /> */}
      </Collapse>
    </>
  );
}

export function Comments(props) {
  const classes = useStyles();

  const textFieldWidth = "60px";
  const textFieldWidth4 = "45px";

  const render = () => {
    return (
      <>
        <Divider className={classes.commentDivider} />
        <Hidden smDown>
          <Box className={classes.desktopRoot}>
            <ScrollBars className={classes.customScroll} autoHide>
              <Box>
                {props.comments == null && (
                  <Box style={{ fontSize: "12px" }}>No comments</Box>
                )}
                {props.comments && (
                  <>
                    <List className={classes.listContainer}>
                      {props.comments
                        .sort((a, b) => {
                          return a.comment.createtime.seconds <
                            b.comment.createtime.seconds
                            ? 1
                            : -1;
                        })
                        .filter(
                          (c) =>
                            c.comment.parent == null ||
                            c.comment.parent.commentid == "" ||
                            c.comment.parent.commentid == "None"
                        )
                        .map((commentInfo) => {
                          let numberOfReplies = 0;
                          let date = new Date();
                          if (
                            commentInfo.comment != null &&
                            commentInfo.comment.createtime
                          ) {
                            date = new Date(
                              commentInfo.comment.createtime.seconds * 1000
                            );
                          }
                          // if (commentInfo.comment.parent.commentid == "None") {
                          return (
                            <>
                              <CommentDisplay
                                comments={props.comments}
                                commentInfo={commentInfo}
                                numberOfReplies={numberOfReplies}
                                date={date}
                                callbacks={props.callbacks}
                                onMouseEnter={props.callbacks.onMouseEnter}
                                onMouseLeave={props.callbacks.onMouseLeave}
                              />
                            </>
                          );
                        })}
                    </List>
                  </>
                )}
              </Box>
            </ScrollBars>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box className={classes.mobileRoot}>
            <Box>
              {props.comments == null && (
                <Box style={{ fontSize: "12px" }}>No comments</Box>
              )}
              {props.comments && (
                <>
                  <List className={classes.listContainer}>
                    {props.comments
                      .sort((a, b) => {
                        return a.comment.createtime.seconds <
                          b.comment.createtime.seconds
                          ? 1
                          : -1;
                      })
                      .filter(
                        (c) =>
                          c.comment.parent == null ||
                          c.comment.parent.commentid == "" ||
                          c.comment.parent.commentid == "None"
                      )
                      .map((commentInfo) => {
                        let date = new Date();
                        if (
                          commentInfo.comment != null &&
                          commentInfo.comment.createtime
                        ) {
                          date = new Date(
                            commentInfo.comment.createtime.seconds * 1000
                          );
                        }
                        // if (commentInfo.comment.parent.commentid == "None") {
                        return (
                          <>
                            <CommentDisplay
                              comments={props.comments}
                              commentInfo={commentInfo}
                              date={date}
                              callbacks={props.callbacks}
                              onMouseEnter={props.callbacks.onMouseEnter}
                              onMouseLeave={props.callbacks.onMouseLeave}
                            />
                          </>
                        );
                      })}
                  </List>
                </>
              )}
            </Box>
          </Box>
        </Hidden>
      </>
    );
  };
  return render();
}

/*
const textFieldWidth = "60px";
const textFieldWidth4 = "45px";

const [open, setOpen] = React.useState(false);
  const [inspectorPosition, setInspectorPosition] = React.useState({
    x: 0.0,
    y: 0.0,
    z: 0.0,
  });
  const [inspectorEuler, setInspectorEuler] = React.useState({
    x: 0.0,
    y: 0.0,
    z: 0.0,
  });
  const [inspectorQuaternion, setInspectorQuaternion] = React.useState({
    x: 0.0,
    y: 0.0,
    z: 0.0,
    w: 0.0,
  });
  const [inspectorScale, setInspectorScale] = React.useState({
    x: 0.0,
    y: 0.0,
    z: 0.0,
  });

const generateInspector = () => {
  return (
    <Box>
      <p>
        <strong>Position</strong>
      </p>
      <Box display="flex">
        {[
          { value: inspectorPosition.x, component: 0, label: "x" },
          { value: inspectorPosition.y, component: 1, label: "y" },
          { value: inspectorPosition.z, component: 2, label: "z" },
        ].map((data) => (
          <TextField
            id="outlined-number"
            label={data.label}
            // type="number"
            value={data.value.toFixed(3)}
            inputProps={{ style: { width: { textFieldWidth } } }}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        ))}
      </Box>
      <p>
        <strong>Rotation</strong>
      </p>
      <p>Euler</p>
      <Box display="flex">
        {[
          { value: inspectorEuler.x, component: 0, label: "x" },
          { value: inspectorEuler.y, component: 1, label: "y" },
          { value: inspectorEuler.z, component: 2, label: "z" },
        ].map((data) => (
          <TextField
            id="outlined-number"
            label={data.label}
            // type="number"
            value={data.value.toFixed(3)}
            inputProps={{ style: { width: { textFieldWidth } } }}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        ))}
      </Box>
      <p>Quaternion</p>
      <Box display="flex">
        {[
          { value: inspectorQuaternion.x, component: 0, label: "x" },
          { value: inspectorQuaternion.y, component: 1, label: "y" },
          { value: inspectorQuaternion.z, component: 2, label: "z" },
          { value: inspectorQuaternion.w, component: 3, label: "w" },
        ].map((data) => (
          <TextField
            id="outlined-number"
            label={data.label}
            // type="number"
            value={data.value.toFixed(3)}
            inputProps={{ style: { width: { textFieldWidth4 } } }}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        ))}
      </Box>
      <strong>
        <p>Scale</p>
      </strong>
      <Box display="flex">
        {[
          { value: inspectorScale.x, component: 0, label: "x" },
          { value: inspectorScale.y, component: 1, label: "y" },
          { value: inspectorScale.z, component: 2, label: "z" },
        ].map((data) => (
          <TextField
            id="outlined-number"
            label={data.label}
            // type="number"
            value={data.value.toFixed(3)}
            inputProps={{ style: { width: { textFieldWidth } } }}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
          />
        ))}
      </Box>
    </Box>
  );
};
*/
