export async function getUserProfile(userId) {
  // Get the profile for the user
  let body = { userId: userId };
  let response = await fetch(
    new Request(
      "https://us-central1-bamboo-zone-280015.cloudfunctions.net/profile",
      {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(body),
      }
    )
  );
  let profile = await response.json();
  // console.log(profile);
  return profile;
}

export function sendShareEmail(setUserRole, spaceInfo, profile, toEmail, callbacks) {

  // Add user role
  setUserRole({ email: toEmail, ...spaceInfo })
    .then((res) => {
      //console.log("userRole");
      //console.log(res);
      callbacks &&
        callbacks.setUserRoleSuccess &&
        callbacks.setUserRoleSuccess();
    })
    .catch((err) => {
      callbacks && callbacks.setUserRoleFail && callbacks.setUserRoleFail();
    });

  // Send email
  const linkURL = process.env.REACT_APP_ROOT + "/s/" + spaceInfo.spaceId;
  const thumbnailURL = `https://storage.googleapis.com/massless-space-content/Spaces/${spaceInfo.spaceId}/thumbnail.png`;

  const shareData = {
    spaceInfo: spaceInfo,
    to: toEmail,
    root: process.env.REACT_APP_ROOT,
    from: profile,
    link: linkURL,
    thumbnail: thumbnailURL,
  };

  const request = new Request(
    "https://us-central1-bamboo-zone-280015.cloudfunctions.net/sendInviteEmail",
    {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(shareData),
    }
  );

  callbacks && callbacks.sendingInfo && callbacks.preSend();

  fetch(request)
    .then((response) => {
      callbacks && callbacks.sendSuccess && callbacks.sendSuccess();
    })
    .catch((err) => {
      callbacks && callbacks.sendFail && callbacks.sendFail();
    });
}

export function sendShareEmailWithSnacks(setUserRole, spaceInfo, profile, toEmail, callbacks) {
  console.log("sendShareEmailWithSnacks")
  sendShareEmail(setUserRole, spaceInfo, profile, toEmail, {
    setUserRoleSuccess: () => {
      callbacks.addSuccessSnack(`${toEmail} is now a collaborator!`);
      callbacks.refreshCollaborators();
    },
    setUserRoleFail: () => {
      callbacks.addErrorSnack(`Could not add ${toEmail} as a collaborator`);
    },
    preSend: () => {
      callbacks.setShareDialogOpen(false);
      callbacks.addInfoSnack(`Sending email to ${toEmail}...`);
    },
    sendSuccess: () => {
      callbacks.addSuccessSnack(`Invite email sent to ${toEmail}`);
    },
    sendFail: () => {
      callbacks.addErrorSnack(`Could not share Space with ${toEmail}`);
    },
  });
}
