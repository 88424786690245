// React
import React, { useState, useEffect } from "react";

// Scrollbars
import { ScrollBars } from "../components/ScrollBars";

import mailchimp from "@mailchimp/mailchimp_marketing";

// Material
import { makeStyles, withStyles } from "@material-ui/core/styles";

// Material Components
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  IconButton,
  SvgIcon,
  Paper,
  Hidden,
  InputLabel,
  MenuItem,
  FormControlLabel,
  FormControl,
  Select,
  Checkbox,
  Link,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
} from "@material-ui/core";

//Material Icons
import BackIcon from "@material-ui/icons/ArrowBack";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";

// Massless Components
import { useSnackbar } from "../context/SnackbarContext";
import SpiralManipulator from "../massless/SpiralManipulator";

//Images
import logoAnim from "../imgs/LandingPage/Space-Logo-Animation.gif";

import FooterLogo from "../imgs/LandingPage/SpaceLogoWhite_S.png";
import HeroCrop from "../imgs/LandingPage/Hero_Crop.png";
import HeroCropMob from "../imgs/LandingPage/Hero_Crop_Mob.png";
import BottomBackground from "../imgs/LandingPage/BottomBackground_PDF.jpg";
import HaveYourSayBGCrop from "../imgs/LandingPage/HaveYourSayBG_Crop.jpg";
import HaveYourSayBGCropMob from "../imgs/LandingPage/HaveYourSayBG_Crop_Mob.jpg";
import ManyUsers1x from "../imgs/LandingPage/ManyUsers_PDF@1x.png";
import ManyUsers2x from "../imgs/LandingPage/ManyUsers_PDF@2x.png";
import ForgetFiles1x from "../imgs/LandingPage/ForgetFiles_PDF@1x.png";
import ForgetFiles2x from "../imgs/LandingPage/ForgetFiles_PDF@2x.png";
import FeedbackFixed1x from "../imgs/LandingPage/Feedback_PDF@1x.png";
import FeedbackFixed2x from "../imgs/LandingPage/Feedback_PDF@2x.png";
import SimpleUI1x from "../imgs/LandingPage/Simple UI@1x.png";
import SimpleUI2x from "../imgs/LandingPage/Simple UI@2x.png";

// > Analytics
import { useAuth } from "../context/AuthContext";
import { trackUser } from "../massless/AnalyticsClient";

const useStyles = makeStyles((theme) => ({
  appBody: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    width: "100%",
    height: "100vh",
    backgroundColor: "#F0F2F5",
  },
  bottomNav: {
    bottom: 0,
    position: "fixed",
    zIndex: 10,
    width: "100%",
    // boxShadow:
    //   "0px -4px 8px rgba(17, 73, 139, 0.2), 0px -1px 2px rgba(7, 43, 84, 0.1)",
    backgroundColor: "#6E54F4",
  },
  bottomNavLabel: { color: "#FFFFFF" },
  pageTitle: {
    color: "#6E54F4",
    textAlign: "center",
    fontWeight: 300,
    ["@media (max-width:370px)"]: {
      // eslint-disable-line no-useless-computed-key
      fontSize: "1.4rem !important",
      lineHeight: "1.4rem !important",
    },
    [theme.breakpoints.up("xs")]: {
      margin: "10px 5px 0",
      fontSize: "1.7rem",
      lineHeight: "1.7rem",
    },
    [theme.breakpoints.up("md")]: {
      margin: "10px 20px 0",
      fontSize: "2.5rem",
      lineHeight: "2.5rem",
    },
  },
  pageTitleGrey: {
    color: "#25283d",
    textAlign: "center",
    margin: "0 20px",
    fontWeight: 300,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.7rem",
      lineHeight: "1.7rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
      lineHeight: "2.5rem",
    },
  },
  pageCopy: {
    color: "#273E78",
    textAlign: "center",
    margin: "10px 20px 0",
    fontWeight: 300,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1rem",
      lineHeight: "1.3rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
      lineHeight: "1.5rem",
    },
  },
  caveat: {
    color: "#979798",
    textAlign: "center",
    fontWeight: 300,
    fontSize: "rem",
    margin: "20px 0",
  },
  landingNavBar: {
    display: "flex",
    padding: "20px",
  },
  spaceLogo: {
    display: "flex",
    maxWidth: 250,
  },
  mobileSpaceLogo: {
    display: "flex",
    maxWidth: 250,
    margin: "3% auto",
  },
  logoAnim: { maxWidth: "100%", maxHeight: "40px" },
  buttonPrompt: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heroSection: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    background: "rgb(194,193,192)",
    background:
      "linear-gradient(90deg, rgba(194,193,192,1) 0%, rgba(171,170,168,1) 100%)",
  },
  haveYourSaySection: {
    padding: "250px 0 0",
    backgroundColor: "#c4c2c0",
    [theme.breakpoints.down("md")]: {
      padding: "60px 0 0",
      margin: "-20px 0",
    },
    [theme.breakpoints.up("md")]: {
      padding: "100px 0 0",
      marginTop: "-40px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "100px 0 0",
      marginTop: "-40px",
    },
    [theme.breakpoints.up("xl")]: {
      padding: "100px 0 0",
      marginTop: "-40px",
    },
  },
  haveYourSayContrain: {
    maxWidth: "700px",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      marginBottom: "-20px",
    },
  },
  haveYourSayCrop: { maxWidth: "100%" },
  haveYourSayCropMob: { maxWidth: "100%" },
  copyConstrain: { maxWidth: "700px", margin: "0 auto" },
  heroConstrain: {
    maxWidth: "700px",
    margin: "0 auto",
    [theme.breakpoints.up("xs")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "20px",
    },
  },
  heroActions: {
    display: "flex",
    justifyContent: "center",
    margin: "10px 20px 0",
  },
  ctaButton: {
    boxShadow: "inset 0px 0px 0px 3px #6E54F4",
    backgroundColor: "#6E54F4",
    color: "#ffffff",
    borderRadius: "50px",
    lineHeight: "1rem",
    "&:hover": {
      color: "#ffffff",
      background: "#6E54F4",
      boxShadow:
        "0px 4px 4px rgba(37, 14, 156, 0.2), 0px 2px 2px rgba(37, 14, 156, 0.2)",
      // border: "3px solid #F0F2F5",
      // boxShadow: "inset 0px 0px 0px 3px #F0F2F5",
      // boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#6E54F4",
      borderColor: "#6E54F4",
    },
  },
  textButton: {
    color: "#273E78",
    borderRadius: "50px",
    margin: "0 5px 0 0",
    lineHeight: "1rem",
    fontWeight: 400,
    "&:hover": {
      color: "#6E54F4",
      boxShadow: "inset 0px 0px 0px 3px #6E54F4",
      // border: "3px solid #F0F2F5",
      // boxShadow: "inset 0px 0px 0px 3px #F0F2F5",
      // boxShadow: "none",
    },
    "&:active": {
      color: "#ffffff",
      boxShadow: "none",
      backgroundColor: "#6E54F4",
      borderColor: "#6E54F4",
    },
  },
  ctaButtonText: { padding: "8px 16px" },
  skewPop: {
    background: "white",
    transform: "skewY(-2.5deg)",
    zIndex: 2,
    // [theme.breakpoints.down("md")]: {
    width: "100%",
    marginTop: "-40px",
    // },
  },
  skewWrap: {
    transform: "skewY(2.5deg)",
    padding: 60,
    [theme.breakpoints.down("md")]: { padding: "40px 10px" },
    [theme.breakpoints.up("md")]: { padding: 60 },
  },
  skewPopQuote: {
    margin: "20px auto 0",
    color: "#273E78",
    textAlign: "center",
    fontWeight: 300,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.7rem",
      lineHeight: "1.7rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2rem",
      lineHeight: "2.5rem",
    },
    // [theme.breakpoints.down("md")]: { width: "100%", fontSize: "1.6rem" },
    // [theme.breakpoints.up("md")]: { width: "50%" },
  },
  skewPopCopy: {
    maxWidth: "530px",
    color: "#273E78",
    textAlign: "center",
    margin: "10px auto 10px",
    fontWeight: 400,
    [theme.breakpoints.down("md")]: { width: "100%" },
    [theme.breakpoints.up("md")]: { width: "50%" },
  },
  realtimeSection: {
    marginTop: "-40px",
    background: "#d9d9d9",
    paddingBottom: "100px",
  },
  realtimeSectionCopy: {
    [theme.breakpoints.up("xs")]: {
      paddingTop: 60,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: 100,
    },
  },
  realtimeUI: {
    background: "#d9d9d9",
    display: "flex",
    outline: "none",
    maxWidth: 700,
    [theme.breakpoints.down("md")]: {
      margin: "20px auto 0",
    },
    [theme.breakpoints.up("md")]: {
      margin: "20px auto",
    },
  },
  UIGraphic: {
    width: "100%",

    padding: "0 5px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "250px",
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: "none",
    },
  },
  benefitsSection: {
    backgroundImage: `url(${BottomBackground})`,
    backgroundPosition: "top",
    backgroundSize: "cover",
    marginTop: "-40px",
    [theme.breakpoints.down("md")]: {
      paddingTop: 40,
      paddingBottom: 100,
    },
    [theme.breakpoints.up("md")]: {
      padding: "100px 0",
    },
  },
  benefit: {
    display: "flex",
    maxWidth: "1080px",
    margin: "0 auto",
    maxHeight: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
    },
    [theme.breakpoints.up("md")]: {
      width: "80%",
      padding: "100px 0",
    },
  },
  benefitHalf: {
    position: "relative",
    display: "flex",
    [theme.breakpoints.down("md")]: { width: "100%" },
    [theme.breakpoints.up("md")]: { width: "50%" },
  },
  benefitImage: {
    padding: "40px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "50vh",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      // width: "80%",
      margin: "auto",
      maxWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "-9999px",
      bottom: "-9999px",
      left: "-9999px",
      right: "-9999px",
      margin: "auto",
    },
  },
  benefitPanel: {
    height: "100%",
    background: "#ffffff",
    transform: "skewY(-2.5deg)",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      margin: "20px 0",
      borderRadius: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "400px",
      margin: "auto",
      borderRadius: 20,
    },
    [theme.breakpoints.up("lg")]: { margin: "auto", borderRadius: 20 },
  },
  benefitWrap: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    transform: "skewY(2.5deg)",
    padding: 40,
  },
  benefitTitle: {
    color: "#6E54F4",
    fontWeight: 300,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.7rem",
      lineHeight: "1.7rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
      lineHeight: "2.5rem",
    },
  },
  benefitCopy: {
    margin: "20px 0 0",
    fontWeight: 300,
    [theme.breakpoints.up("xs")]: {
      fontSize: "1rem",
      lineHeight: "1.3rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
      lineHeight: "1.5rem",
    },
  },
  footer: {
    display: "flex",
    flexDirection: "column",
    height: "200px",
    backgroundColor: "#232241",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "55px",
    },
  },
  footerWrap: { margin: "auto" },
  footerLogo: {
    margin: "auto",
    maxWidth: "350px",
  },
  copyrightText: { color: "#ffffff7d", textAlign: "center", fontWeight: 300 },

  //Dialog Styling
  loginButton: {
    height: "30px",
    borderRadius: "20px",
    width: "100%",
  },
  formControl: {
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  formLabelControl: {
    margin: "0",
  },
  formLabel: {
    width: "auto",
    fontSize: "14px",
  },
  dialogPanel: {
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    maxWidth: "440px",
    [theme.breakpoints.down("sm")]: {
      padding: 8,
      margin: 8,
    },
    [theme.breakpoints.up("sm")]: {
      padding: 32,
      margin: 16,
    },
  },
  voteSelect: { marginBottom: "20px" },
  voteSelectLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.7rem",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "inherit",
    },
  },
}));

//#region Custom Icon
function GoogleIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M22,11.48c0-0.76-0.06-1.53-0.19-2.28H11.22v4.32h6.06c-0.25,1.39-1.06,2.63-2.24,3.41v0
        c-1.01,0.68-2.3,1.07-3.81,1.07c-2.93,0-5.41-1.98-6.3-4.63h0c-0.47-1.39-0.47-2.9,0-4.3c0.89-2.66,3.37-4.63,6.3-4.63
        c1.6-0.02,3.15,0.58,4.3,1.68l3.2-3.2C16.7,1.02,14.01-0.03,11.22,0C6.98,0,3.1,2.39,1.19,6.19c-1.59,3.17-1.59,6.91,0,10.08
        c1.9,3.79,5.79,6.18,10.03,6.18c3.03,0,5.58-0.99,7.44-2.71l0,0C20.78,17.78,22,14.89,22,11.48z"
      />
    </SvgIcon>
  );
}
function DocumentationIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H21V18C20.4477 18 20 18.4477 20 19C20 19.5523 20.4477 20 21 20V22ZM18 19C18 18.6494 18.0602 18.3128 18.1707 18H6C5.44772 18 5 18.4477 5 19C5 19.5523 5.44772 20 6 20H18.1707C18.0602 19.6872 18 19.3506 18 19ZM6 4H19V16H6C5.64936 16 5.31278 16.0602 5 16.1707V5C5 4.44772 5.44772 4 6 4Z"
      />
    </SvgIcon>
  );
}
//#endregion

//#region Get Window Size
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
//#endregion

function LoginPage() {
  //#region Analytics
  const { profile, authStart, authSignIn, authorize } = useAuth();

  React.useEffect(() => {
    trackUser("login-page-view");
  }, []);
  //#endregion

  //#region Login
  const classes = useStyles();

  const { addSnack } = useSnackbar();

  const [stage, setStage] = useState("start");
  const [message, setMessage] = useState(" ");
  const [code, setCode] = useState("");
  const [email, setEmail] = useState(" ");

  const emailInputChange = (e) => setEmail(e.target.value);
  const codeInputChange = (e) => setCode(e.target.value);

  const start = async () => {
    console.log("start()");
    console.log(email);

    if (email.length >= 6 && state.termsCheck == true) {
      // TODO: Validation on the input
      authStart(email)
        .then(() => {
          setStage("signIn");
          emailError = false;
        })
        .catch((err) => {
          addSnack({
            severity: "error",
            message: "Problem with email address",
          });
          emailError = true;
          setStage("start");
        });
    } else if (state.termsCheck == true) {
      addSnack({ severity: "error", message: "Email not long enough" });
      emailError = true;
    } else if (state.termsCheck == false) {
      addSnack({ severity: "error", message: "Please read the T&C's" });
      checkboxColor = "primary";
      emailError = false;
    }
  };

  const signIn = async () => {
    console.log("authCode()");
    if (code.length == 6) {
      // TODO: Validation on the input
      authSignIn(code).catch(() => {
        addSnack({ severity: "error", message: "Problem with code" });
      });
    } else {
      addSnack({ severity: "error", message: "Code in six digits" });
    }
  };
  //#endregion

  //#region T&Cs CheckBox State
  let checkboxColor = "white";

  let emailError = false;

  const TermsCheckbox = withStyles({})((props) => (
    <Checkbox
      color="primary"
      size="medium"
      style={{ marginLeft: "-12px", marginRight: "8px" }}
      icon={<CheckBoxOutlineBlankIcon color={checkboxColor} />}
      {...props}
    />
  ));

  const [state, setState] = React.useState({
    termsCheck: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const termsError = state.termsCheck !== true;
  //#endregion

  //#region Login Render
  const renderStart = () => {
    return (
      <>
        <DialogTitle style={{ padding: "12px 18px" }}>
          Enter your email to sign in!
        </DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ margin: 16 }}
        >
          <TextField
            error={emailError}
            inputProps={{ style: { textAlign: "center" } }}
            onChange={emailInputChange}
            onClick={(emailError = false)}
            id="standard-basic"
            label="Your email"
            fullWidth="true"
            autoFocus
            color="primary"
          />
        </Box>
        <FormControl
          required
          error={termsError}
          component="fieldset"
          className={classes.formControl}
        >
          <FormControlLabel
            className={classes.formLabelControl}
            control={
              <TermsCheckbox
                checked={state.termsCheck}
                onChange={handleChange}
                name="termsCheck"
              />
            }
            label={
              <Typography className={classes.formLabel}>
                I have read and agree to the{" "}
                <Link
                  target="_blank"
                  color="primary"
                  href="https://massless.io/terms-conditions/"
                >
                  terms & conditions
                </Link>{" "}
                and{" "}
                <Link
                  target="_blank"
                  color="primary"
                  href="https://massless.io/privacy-policy/"
                >
                  privacy policy
                </Link>{" "}
                and want to receive Massless updates
              </Typography>
            }
          />
        </FormControl>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="16px"
        >
          <Button
            className={classes.loginButton}
            variant="outlined"
            color="primary"
            onClick={() => {
              trackUser("login-start-submit-click", profile, { email: email });
              start();
            }}
          >
            Submit
          </Button>
          {/* <Typography style={{ margin: "16px" }}>Or sign in with:</Typography>
          <Button
            className={classes.loginButton}
            variant="outlined"
            startIcon={<GoogleIcon />}
            onClick={() => {
              googleStart();
            }}
          >
            Google
          </Button> */}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ color: "red" }}
        >
          {message}
        </Box>
      </>
    );
  };

  const renderSignIn = () => {
    return (
      <>
        <Box>
          <IconButton
            size="small"
            color="text.primary"
            aria-label="back"
            onClick={() => {
              setStage("start");
            }}
            // style={{ position: "relative", left: "-30px", top: "-30px" }}
          >
            <BackIcon />
          </IconButton>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ padding: 16, paddingBottom: "0px", textAlign: "center" }}
        >
          <Typography>
            An email with the code has been sent to <br /> {email}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <TextField
            onChange={codeInputChange}
            id="standard-basic"
            label="Your code"
            fullWidth="true"
            autoFocus
            style={{ margin: 16 }}
            color="primary"
            inputProps={{
              pattern: "[0-9]{6}",
              inputMode: "numeric",
              style: { textAlign: "center" },
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            className={classes.loginButton}
            variant="outlined"
            color="primary"
            style={{ margin: 16 }}
            onClick={() => {
              trackUser("login-sign-in-submit-click", profile, {
                email: email,
              });
              signIn();
            }}
          >
            Submit
          </Button>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ color: "red" }}
        >
          {message}
        </Box>
      </>
    );
  };
  //#endregion

  //#region Dynamic Copy
  const textArray = ["Work", "Sculpt", "Collaborate", "Design", "Review"];

  const [dynamicWordID, setDynamicWordID] = useState(0);

  // Effect
  useEffect(() => {
    let currentIdx = dynamicWordID;
    setTimeout(() => {
      setDynamicWordID(currentIdx + 1);
    }, 3000);
  }, []);

  useEffect(() => {
    let currentIdx = dynamicWordID;
    setTimeout(() => {
      setDynamicWordID(currentIdx + 1);
    }, 3000);
  }, [dynamicWordID]);

  let dynamicWord = textArray[dynamicWordID % textArray.length];

  //#endregion

  //#region Benefits Region
  const benefits = [
    {
      id: 1,
      img1x: ManyUsers1x,
      img2x: ManyUsers2x,
      title: "One scene, many users",
      copy:
        "Massless Space lets directors, producers, designers and artists collaborate more effectively. Ideate, direct, design and build. Model, light, texture*. All in tandem, with zero lag.",
      buttonText: "CONNECT YOUR TEAM",
      caveat: "*Coming Soon",
    },
    {
      id: 2,
      img1x: ForgetFiles1x,
      img2x: ForgetFiles2x,
      title: "Forget about files",
      copy:
        "With seamless file management, Space tracks your project’s changes, storing assets on the cloud. No need to stop to install, save, or export. Whether you’re modelling in Blender or developing in Unity, any edits are updated in real-time for the whole team.",
      buttonText: "START A PROJECT",
    },
    {
      id: 3,
      img1x: FeedbackFixed1x,
      img2x: FeedbackFixed2x,
      title: "Feedback: fixed",
      copy:
        "Why ask for an update or render, when you can log into Space and see for yourself? Why create a feedback doc, when you can leave a comment in the scene? This is the way 3D collaboration is meant to be: easy for directors, efficient for creatives.",
      buttonText: "3D FASTER",
    },
  ];
  //#endregion

  //#region Waitlist Dialog Control
  const [waitlistSoftware, setWaitlistSoftware] = useState("");
  const [waitlistComment, setWaitlistComment] = useState(" ");
  const [waitlistEmail, setWaitlistEmail] = useState(" ");

  const waitlistSoftwareChange = (e) => setWaitlistSoftware(e.target.value);
  const waitlistCommentChange = (e) => setWaitlistComment(e.target.value);
  const waitlistEmailChange = (e) => setWaitlistEmail(e.target.value);

  React.useEffect(() => {
    console.log(waitlistSoftware);
  }, [waitlistSoftware]);

  const [openWaitlistDialog, setOpenWaitlistDialog] = React.useState(false);

  function setWaitlistDialogOpen(state) {
    setOpenWaitlistDialog(state);
  }

  const waitlistSubmitClicked = () => {
    // console.log("Submit");
    waitlistAddMember();
  };
  //#endregion

  //#region Login Dialog Control
  const [openLoginDialog, setOpenLoginDialog] = React.useState(false);

  function setLoginDialogOpen(state) {
    setOpenLoginDialog(state);
  }

  const loginClicked = () => {
    console.log("Submit");
  };
  //#endregion

  //#region MailChimp
  mailchimp.setConfig({
    apiKey: "dd7791cdf2cfea3ddd9da4217b11509b-us15",
    server: "us15",
  });

  const listId = "2d7e2bbc14";

  const subscribingUser = {
    email: waitlistEmail,
    software: waitlistSoftware,
    comment: waitlistComment,
  };

  async function waitlistAddMember() {
    let response = null;
    try {
      response = await mailchimp.lists.addListMember(listId, {
        email_address: subscribingUser.email,
        status: "subscribed",
        merge_fields: {
          SOFTWARE: subscribingUser.software,
          COMMENT: subscribingUser.comment,
        },
      });
      addSnack({
        severity: "success",
        message: "You have successfully signed up to the Waitlist.",
      });
      setOpenWaitlistDialog(false);
      // console.log(
      //   `Successfully added contact as an audience member. The contact's id is ${response.id}.`
      // );
    } catch (e) {
      addSnack({
        severity: "error",
        message: "Please check your email address!",
      });
      throw e;
    }
  }
  //#endregion

  return (
    <>
      <Box className={classes.appBody}>
        <Hidden mdUp>
          <BottomNavigation
            // value={value}
            // onChange={(event, newValue) => {
            //   setValue(newValue);
            // }}
            showLabels
            className={classes.bottomNav}
          >
            <BottomNavigationAction
              classes={{ wrapper: classes.bottomNavLabel }}
              label="Documentation"
              color="primary"
              icon={<DocumentationIcon />}
              onClick={() => {
                window.open("https://docs.space.massless.io/");
                trackUser("login-page-documentation-click");
              }}
            />
            <BottomNavigationAction
              classes={{ wrapper: classes.bottomNavLabel }}
              label="Sign In"
              icon={<LockOpenIcon />}
              onClick={() => {
                setLoginDialogOpen(true);
              }}
            />
            <BottomNavigationAction
              classes={{ wrapper: classes.bottomNavLabel }}
              label="Join Waitlist"
              icon={<HourglassEmptyIcon />}
              onClick={() => {
                setWaitlistDialogOpen(true);
              }}
            />
          </BottomNavigation>
        </Hidden>
        <ScrollBars style={{ display: "flex" }} autoHide>
          <Box className={classes.heroSection}>
            <Hidden smDown>
              <Box className={classes.landingNavBar}>
                <Box className={classes.spaceLogo}>
                  <img className={classes.logoAnim} src={logoAnim} />
                </Box>
                <Box style={{ marginLeft: "auto" }}>
                  <Button
                    classes={{ text: classes.ctaButtonText }}
                    className={classes.textButton}
                    onClick={() => {
                      window.open("https://docs.space.massless.io/");
                      trackUser("login-page-documentation-click");
                    }}
                  >
                    Documentation
                  </Button>
                  <Button
                    classes={{ text: classes.ctaButtonText }}
                    className={classes.textButton}
                    onClick={() => {
                      setLoginDialogOpen(true);
                    }}
                  >
                    Sign In
                  </Button>
                  <Button
                    classes={{ text: classes.ctaButtonText }}
                    className={classes.ctaButton}
                    onClick={() => {
                      setWaitlistDialogOpen(true);
                    }}
                  >
                    Join Waitlist
                  </Button>
                </Box>
              </Box>
            </Hidden>
            <Hidden mdUp>
              <Box className={classes.mobileSpaceLogo}>
                <img className={classes.logoAnim} src={logoAnim} />
              </Box>
            </Hidden>
            <Box className={classes.heroConstrain}>
              <Typography variant="h4" className={classes.pageTitle}>
                Collaborate in 3D, friction free.
              </Typography>
              <Typography className={classes.pageCopy}>
                Massless Space is currently in Beta testing and sign ups are
                closed. If you are interested, please join the waitlist and we
                will contact you when there are spaces available.
              </Typography>
            </Box>
            <Hidden only="xs">
              <img
                className={classes.haveYourSayCrop}
                src={HeroCrop}
                // srcSet={SimpleUI1x + " 1x," + SimpleUI2x + " 2x"}
              />
            </Hidden>
            <Hidden smUp>
              <img
                className={classes.haveYourSayCropMob}
                src={HeroCropMob}
                // srcSet={SimpleUI1x + " 1x," + SimpleUI2x + " 2x"}
              />
            </Hidden>
          </Box>
          <Box className={classes.skewPop}>
            <Box className={classes.skewWrap}>
              <Box className={classes.copyConstrain}>
                <Typography variant="h4" className={classes.pageTitle}>
                  "3D, we have a problem..."
                </Typography>
                <Typography className={classes.pageCopy}>
                  The 3D workflow is long. Too long. Meetings that should take
                  minutes take hours. Tasks that should take hours take days.
                  Directors, artists and makers are forced to create in
                  isolation — while assets limp through a maze of pipelines,
                  software, files, emails and channels. Surely, there’s a better
                  way? There is. We call it Massless Space.
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.realtimeSection}>
            <Box className={classes.realtimeSectionCopy}>
              <Box className={classes.copyConstrain}>
                <Typography variant="h4" className={classes.pageTitle}>
                  {dynamicWord}
                </Typography>
                <Typography variant="h4" className={classes.pageTitleGrey}>
                  in realtime
                </Typography>
                <Typography className={classes.pageCopy}>
                  Space connects 3D tools like Blender and Unity into a single
                  collaborative environment, so you and your team can work
                  together in the same scene, at the same time, seamlessly.
                </Typography>
              </Box>
            </Box>
            <Box className={classes.realtimeUI}>
              <img
                className={classes.UIGraphic}
                src={SimpleUI1x}
                srcSet={SimpleUI1x + " 1x," + SimpleUI2x + " 2x"}
              />
              {/* <SpiralManipulator /> */}
            </Box>
          </Box>
          <Box className={classes.skewPop}>
            <Box className={classes.skewWrap}>
              <Box className={classes.copyConstrain}>
                <Typography variant="h4" className={classes.skewPopQuote}>
                  “Space unlocks my teams’ creativity, speeds up our workflow
                  and makes collaboration easy.”
                </Typography>
                <Typography className={classes.caveat}>
                  Andy Schuler - Dreamworks CDO
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.benefitsSection}>
            {benefits.map((benefit) => (
              <Box
                className={classes.benefit}
                flexDirection={benefit.id % 2 == 0 ? "row-reverse" : "row"}
              >
                <Box className={classes.benefitHalf}>
                  <img
                    className={classes.benefitImage}
                    src={benefit.img1x}
                    srcSet={benefit.img1x + " 1x," + benefit.img2x + " 2x"}
                  />
                </Box>
                <Box className={classes.benefitHalf}>
                  <Box className={classes.benefitPanel}>
                    <Box className={classes.benefitWrap}>
                      <Typography variant="h4" className={classes.benefitTitle}>
                        {benefit.title}
                      </Typography>
                      <Typography className={classes.benefitCopy}>
                        {benefit.copy}
                      </Typography>
                      {benefit.caveat != null && (
                        <Typography
                          style={{
                            color: "#7d8bae",
                            fontWeight: 300,
                            fontSize: "1rem",
                            margin: "10px 0",
                          }}
                        >
                          {benefit.caveat}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          <Box className={classes.skewPop}>
            <Box className={classes.skewWrap}>
              <Box className={classes.copyConstrain}>
                <Typography variant="h4" className={classes.skewPopQuote}>
                  Use your favorite 3D software. Supercharge it with real-time
                  synchronization. Collaborate on scenes within and across 3D
                  tools.
                </Typography>
                <Box className={classes.heroActions}>
                  <Box className={classes.buttonPrompt}>
                    <Typography
                      style={{ marginBottom: "5px" }}
                      className={classes.pageCopy}
                    >
                      Already a user?
                    </Typography>
                    <Button
                      classes={{ text: classes.ctaButtonText }}
                      className={classes.ctaButton}
                      onClick={() => {
                        setLoginDialogOpen(true);
                      }}
                    >
                      Sign In
                    </Button>
                  </Box>
                  <Box className={classes.buttonPrompt}>
                    <Typography
                      style={{ marginBottom: "5px" }}
                      className={classes.pageCopy}
                    >
                      New to Space?
                    </Typography>
                    <Button
                      classes={{ text: classes.ctaButtonText }}
                      color="secondary"
                      className={classes.ctaButton}
                      onClick={() => {
                        setWaitlistDialogOpen(true);
                      }}
                    >
                      Join Waitlist
                    </Button>
                  </Box>
                </Box>
                <Typography variant="h4" className={classes.caveat}>
                  (It’s free, forever)
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.haveYourSaySection}>
            <Box className={classes.haveYourSayContrain}>
              <Typography variant="h4" className={classes.pageTitle}>
                Have your say
              </Typography>
              <Typography className={classes.pageCopy}>
                Space works in Blender, Unity and Chrome, with more integrations
                on the way. Use the button below to push your favorite 3D
                software to the front of our roadmap
              </Typography>
              <Box className={classes.heroActions}>
                <Box style={{ marginTop: 10 }}>
                  <Button
                    classes={{ text: classes.ctaButtonText }}
                    className={classes.ctaButton}
                    onClick={() => {
                      setWaitlistDialogOpen(true);
                    }}
                  >
                    Vote
                  </Button>
                </Box>
              </Box>
            </Box>
            <Hidden mdUp>
              <img
                className={classes.haveYourSayCropMob}
                src={HaveYourSayBGCropMob}
                // srcSet={SimpleUI1x + " 1x," + SimpleUI2x + " 2x"}
              />
            </Hidden>
            <Hidden smDown>
              <img
                className={classes.haveYourSayCrop}
                src={HaveYourSayBGCrop}
                // srcSet={SimpleUI1x + " 1x," + SimpleUI2x + " 2x"}
              />
            </Hidden>
          </Box>
          <Box className={classes.footer}>
            <Box className={classes.footerWrap}>
              <img className={classes.footerLogo} src={FooterLogo} />
              <Typography className={classes.copyrightText}>
                ©2021 Massless LTD.
              </Typography>
            </Box>
          </Box>
        </ScrollBars>
      </Box>
      {/* Login Dialog */}
      <Dialog
        classes={{ paper: classes.dialogPanel }}
        open={openLoginDialog}
        onClose={() => {
          setOpenLoginDialog(false);
        }}
      >
        {stage == "start" && renderStart()}
        {stage == "signIn" && renderSignIn()}
      </Dialog>
      {/* Waitlist Dialog */}
      <Dialog
        classes={{ paper: classes.dialogPanel }}
        open={openWaitlistDialog}
        onClose={() => {
          setOpenWaitlistDialog(false);
        }}
      >
        <DialogTitle style={{ padding: "12px 18px" }}>
          Join the Waitlist
        </DialogTitle>
        <DialogContent style={{ padding: "12px 18px" }}>
          <DialogContentText style={{ marginBottom: "10px" }}>
            Enter your email below to sign up to the waitlist and be first to
            know when we have space to join Massless Space
          </DialogContentText>
          <Box className={classes.textField} style={{ marginBottom: "10px" }}>
            <TextField
              autoFocus
              label="Email"
              type="text"
              fullWidth
              color="primary"
              onChange={waitlistEmailChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
          <DialogContentText
            style={{
              marginBottom: "10px",
              marginTop: "30px",
              fontWeight: 400,
              fontSize: "1rem",
              color: "#273E78",
            }}
          >
            Want to help shape Massless Space?
          </DialogContentText>
          <DialogContentText style={{ marginBottom: "10px" }}>
            We’d love to hear which software integration you would like to see
            next. If you’d like to help out please let us know below, otherwise
            leave blank.
          </DialogContentText>
          <FormControl className={classes.voteSelect} fullWidth>
            <InputLabel
              className={classes.voteSelectLabel}
              id="demo-simple-select-helper-label"
            >
              Which software would you like to see next?
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={waitlistSoftware}
              onChange={waitlistSoftwareChange}
              fullWidth
            >
              <MenuItem value={"Maya"}>Maya</MenuItem>
              <MenuItem value={"Unreal Engine"}>Unreal Engine</MenuItem>
              <MenuItem value={"C4D"}>C4D</MenuItem>
              <MenuItem value={"Photoshop"}>Photoshop</MenuItem>
              <MenuItem value={"Other"}>Other (Add in comment)</MenuItem>
            </Select>
          </FormControl>
          <Box className={classes.textField}>
            <TextField
              label="Any other comments"
              type="text"
              multiline
              fullWidth
              color="primary"
              onChange={waitlistCommentChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="16px"
        >
          <Button
            className={classes.loginButton}
            variant="outlined"
            color="primary"
            onClick={() => {
              trackUser("landing-page-login-dialog-submit");
              waitlistSubmitClicked();
            }}
          >
            Join
          </Button>
        </Box>
      </Dialog>
    </>
  );
}

export default LoginPage;
