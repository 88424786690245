// React
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import CollectionsIcon from "@material-ui/icons/Collections";
import ExtensionIcon from '@material-ui/icons/Extension';

// Massless
import { useSpace } from "../../context/SpaceContext";
import { useAuth } from "../../context/AuthContext";
import {
  sAnalyticsTrack,
} from "../../massless/AnalyticsClient";

import SceneThumb1x from "../../imgs/SceneThumb@1x.png";
import SceneThumb2x from "../../imgs/SceneThumb@2x.png";
import { CardMedia3D_Collection } from "../CardMedia3D";

const useStyles = makeStyles((theme) => ({
  projectCard: {
    backgroundColor: "transparent",
    boxShadow: "none",
    width: "100%",
  },
  projectCardMedia: {
    height: 0,
    paddingTop: "56.25%",
    backgroundColor: "transparent",
    borderRadius: "10px",
  },
  projInfo: {
    background: "transparent",
    padding: "8px 0 0 0",
  },
  projName: {
    fontSize: "18px",
    color: "#FFFFFF",
  },
  projDescription: {
    fontSize: "12px",
    lineHeight: "13px",
    color: "#666666",
    height: "27px",
  },
}));

export function ProjectCard(props) {
  /* Analytics ********************************************************************/

  const { profile } = useAuth();

  const trackUser = (trackId) => {
    if (profile == null) {
      sAnalyticsTrack(trackId, {});
      return;
    }
    let userId = profile.sub.split("|")[1];
    sAnalyticsTrack(trackId, { id: userId, email: profile.email });
  };

  /* Canvas *********************************************************************/

  const classes = useStyles();

  const [threeCanvas, setThreeCanvas] = React.useState(null);
  React.useEffect(() => {
    if (threeCanvas == null) return;
    CardMedia3D_Collection(threeCanvas);
  }, [threeCanvas]);

   /* Space *********************************************************************/
   const { listSpaces, listNodes } = useSpace();

   let countNodes = 0;
 
   const projectInfo = { studioId: props.studioId, projectId: props.projectId };

  /* Badges *********************************************************************/

  const [numberOfSpaces, setNumberOfSpaces] = React.useState(null);
  const [numberOfAssets, setNumberOfAssets] = React.useState(null);

  const getBadgeData = () => {
    listSpaces(projectInfo).then((remoteSpaces) => {
      setNumberOfSpaces(remoteSpaces.length);
      remoteSpaces.forEach((spaceInfo) => {
          console.log(spaceInfo)
        listNodes(
          spaceInfo
        ).then((res) => {
            const nodes = res.getNodesList()
            const nodesInSpace = nodes.length;           
            countNodes += nodesInSpace
            setNumberOfAssets(countNodes)
        });
      });
    });
  };

  React.useEffect(() => {
    getBadgeData();
  }, []);

  return (
    <Card className={classes.projectCard} key={props.projectId}>
      <CardActionArea>
        <Link
          to={"/" + props.studioId + "/" + props.projectId}
          onClick={trackUser("project-view-thumbnail-click")}
        >
            {/**************************** CardMedia ********************************/}
          <Box
            ref={(ref) => {
              //setThreeParent(ref);
            }}
            style={{
              position: "relative",
              paddingTop: "56.25%",
              width: "100%",
            }}
          >
            <canvas
              ref={(ref) => {
                setThreeCanvas(ref);
              }}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                // background: "blue",
                width: "100%",
                height: "100%",
              }}
            ></canvas>

            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                color: "white",
                padding: "10px",
              }}
            >
              {numberOfSpaces != null && numberOfSpaces != 0 && (
                <Badge badgeContent={numberOfSpaces} color="primary">
                  <CollectionsIcon />
                </Badge>
              )}
              {numberOfAssets != null && numberOfAssets != 0 && (
                <Badge
                  badgeContent={numberOfAssets}
                  color="primary"
                  style={{ marginLeft: "15px", marginRight: "15px" }}
                >
                  <ExtensionIcon />
                </Badge>
              )}
            </div>
          </Box>
          {/**************************** CardMedia ********************************/}
          <CardContent className={classes.projInfo}>
            <Box
              height="22px"
              overflow="hidden"
              component="div"
              margin="0 0 5px 0"
            >
              <Typography
                noWrap
                className={classes.projName}
                gutterBottom
                variant="h5"
              >
                {props.title}
              </Typography>
            </Box>
            <Box overflow="hidden" component="div">
              <Typography
                className={classes.projDescription}
                variant="body2"
                component="p"
              >
                {props.description}
              </Typography>
            </Box>
          </CardContent>
        </Link>
      </CardActionArea>
    </Card>
  );
}
