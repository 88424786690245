/*

This is a boilerplate wrapper to the Space gRPC calls.
It was required because it grpc-web doesn't seem to have a way to
setup a channel where we set the metadata like authorization, client, 
and session info would be passed. Instead we have to pass it on every
call. This context interfaces with the auth context to wrap the calls.
- Jack

*/
// React
import React from "react";
import { useCookies } from "react-cookie";

// Other
import { v4 as uuidv4 } from "uuid";

// Massless
import { useAuth } from "./AuthContext";
import * as Space from "../massless/Space.js";

// Space Context
export const SpaceContext = React.createContext();
export const useSpace = () => React.useContext(SpaceContext);

export function SpaceProvider({ children }) {
  const { metadata } = useAuth();
  const [cookies, setCookie] = useCookies(["client_id"]);

  let client_id = null;
  let session_id = uuidv4();

  if (cookies.client_id) {
    client_id = cookies.client_id;
  } else {
    client_id = "web|" + uuidv4();
    setCookie("client_id", client_id, { path: "/" });
  }

  const compoundMetadata = {
    client_id: client_id,
    session_id: session_id,
    plugin_version: process.env.REACT_APP_VERSION,
    ...metadata,
  };

  const getMetadata = () => {
    return compoundMetadata;
  };

  const getGlobalStudioReference = async () => {
    return Space.getGlobalStudioReference(compoundMetadata);
  };

  const createProject = async (projectInfo) => {
    return Space.createProject(projectInfo, compoundMetadata);
  };

  const listGlobalProjects = async () => {
    return Space.listGlobalProjects(compoundMetadata);
  };

  const removeProject = async (projectInfo) => {
    return Space.removeProject(projectInfo, compoundMetadata);
  };

  const setProjectProperties = async (projectProperties) => {
    return Space.setProjectProperties(projectProperties, compoundMetadata);
  };

  const getProjectInfo = async (projectInfo) => {
    return Space.getProjectInfo(projectInfo, compoundMetadata);
  };

  const createSpace = async (spaceInfo) => {
    return Space.createSpace(spaceInfo, compoundMetadata);
  };

  const deleteSpace = async (spaceInfo) => {
    return Space.deleteSpace(spaceInfo, compoundMetadata);
  };

  const listSpaces = async (projectInfo) => {
    return Space.listSpaces(projectInfo, compoundMetadata);
  };

  const setSpaceProperties = async (spaceProperties) => {
    return Space.setSpaceProperties(spaceProperties, compoundMetadata);
  };

  const getSpaceInfo = async (spaceInfo) => {
    return Space.getSpaceInfo(spaceInfo, compoundMetadata);
  };

  const listNodes = async (nodeInfo) => {
    return Space.listNodes(nodeInfo, compoundMetadata);
  };

  const getNode = async (nodeInfo) => {
    return Space.getNode(nodeInfo, compoundMetadata);
  };

  const getMesh = async (req) => {
    return Space.getMesh(req, compoundMetadata);
  };

  const getTransform = async (req) => {
    return Space.getTransform(req, compoundMetadata);
  };

  const getNodeProperties = async (req) => {
    return Space.getNodeProperties(req, compoundMetadata);
  };

  const watchSpace = (req) => {
    return Space.watchSpace(req, compoundMetadata);
  };

  const watchAssets = (req) => {
    return Space.watchAssets(req, compoundMetadata);
  };

  const removeNode = (req) => {
    return Space.removeNode(req, compoundMetadata);
  };

  const setSpaceThumbnail = (req) => {
    return Space.setSpaceThumbnail(req, compoundMetadata);
  };

  const getSpaceThumbnail = (req) => {
    return Space.getSpaceThumbnail(req, compoundMetadata);
  };

  const addComment = (req) => {
    return Space.addComment(req, compoundMetadata);
  };

  const updateComment = (req) => {
    return Space.addComment(req, compoundMetadata);
  };

  const listComments = (req) => {
    return Space.listComments(req, compoundMetadata);
  };

  const deleteComment = (req) => {
    return Space.deleteComment(req, compoundMetadata);
  };

  const getClientStatus = (req) => {
    return Space.getClientStatus(req, compoundMetadata);
  };

  const setClientStatus = (req) => {
    return Space.setClientStatus(req, compoundMetadata);
  };

  const setUserRole = (req) => {
    return Space.setUserRole(req, compoundMetadata);
  };

  const getPermissions = (req) => {
    return Space.getPermissions(req, compoundMetadata);
  };

  const addTags = (req) => {
    return Space.addTags(req, compoundMetadata);
  };

  const removeTags = (req) => {
    return Space.removeTags(req, compoundMetadata);
  };

  const spaceCalls = {
    listGlobalProjects,
    getGlobalStudioReference,
    createProject,
    removeProject,
    setProjectProperties,
    getProjectInfo,
    createSpace,
    deleteSpace,
    listSpaces,
    setSpaceProperties,
    getSpaceInfo,
    listNodes,
    getNode,
    getMesh,
    getTransform,
    getNodeProperties,
    watchSpace,
    watchAssets,
    removeNode,
    setSpaceThumbnail,
    getSpaceThumbnail,
    getMetadata,
    addComment,
    updateComment,
    listComments,
    deleteComment,
    getClientStatus,
    setClientStatus,
    setUserRole,
    getPermissions,
    addTags,
    removeTags,
  };

  return (
    <SpaceContext.Provider value={spaceCalls}>{children}</SpaceContext.Provider>
  );
}
