/*

If you see a "method is unimplemented" error, check if you're passing the metadata in the rpc 

*/

// Ext
import { v4 as uuidv4 } from "uuid";

// Massless
import spaceClient from "./SpaceClient";
import * as Space from "./SpaceService_grpc_web_pb.js";

export class SpaceNode {
  constructor(nodeRef, node) {
    // console.log(">SpaceNode")
    // console.log(node)
    // console.log(node.toObject())
    this.ref = nodeRef;
    this.id = nodeRef.getNodeid();
    this.version = nodeRef.getNodeversion();
    this.propertiesRef = node.getProperties();
    this.name = "none";
    if (node.getProperties()) this.name = node.getProperties().getName();
    this.transformRef = node.getTransform();
    this.transform = null;
    this.parent = node.getParent();
    this.children = node.toObject().childrenList;
    this.threeObject = null;
    this.inScene = false;
    this.fading = false;
    this.threeGeo = null;
    this.lightRef = null;
    this.cameraRef = null;
    this.lightreference = node.toObject().lightreference;
    this.camerareference = node.toObject().camerareference;

    this.setType(node);
  }

  setType(node) {
    this.type = "empty";

    if (node.hasMesh()) {
      this.meshRef = node.getMesh();
      this.type = "mesh";
    }

    switch (node.getTypeCase()) {
      case Space.Node.TypeCase.MESHREFERENCE:
        this.meshRef = node.getMeshreference();
        this.type = "mesh";
        break;
      case Space.Node.TypeCase.LIGHTREFERENCE:
        this.lightRef = node.getLightreference();
        this.type = "light";
        break;
      case Space.Node.TypeCase.CAMERAREFERENCE:
        this.cameraRef = node.getCamerareference();
        this.type = "camera";
        break;
    }
  }
}

export async function createSpace(spaceInfo, metadata) {
  return spaceClient.createSpace(
    new Space.CreateSpaceRequest()
      .setProject(
        new Space.ProjectReference().setProjectid(spaceInfo.projectId)
      )
      .setStudio(new Space.StudioReference().setStudioid(spaceInfo.studioId))
      .setProperties(
        new Space.SpaceProperties()
          .setName(spaceInfo.name)
          .setDescription(spaceInfo.description)
      ),
    metadata
  );
}

export async function deleteSpace(spaceInfo, metadata) {
  return spaceClient.deleteSpace(
    new Space.DeleteSpaceRequest()
      .setSpace(new Space.SpaceReference().setSpaceid(spaceInfo.spaceId))
      .setProject(
        new Space.ProjectReference().setProjectid(spaceInfo.projectId)
      )
      .setStudio(new Space.StudioReference().setStudioid(spaceInfo.studioId)),
    metadata
  );
}

export async function setSpaceProperties(spaceProperties, metadata) {
  return spaceClient.setSpaceProperties(
    new Space.SetSpacePropertiesRequest()
      .setSpace(new Space.SpaceReference().setSpaceid(spaceProperties.spaceId))
      .setProject(
        new Space.ProjectReference().setProjectid(spaceProperties.projectId)
      )
      .setStudio(
        new Space.StudioReference().setStudioid(spaceProperties.studioId)
      )
      .setProperties(
        new Space.SpaceProperties()
          .setName(spaceProperties.name)
          .setDescription(spaceProperties.description)
          .setTagsList(spaceProperties.tagsList)
      ),
    metadata
  );
}

export async function listSpaces(projectInfo, metadata) {
  // Get the spaces for this project
  let remoteSpaces = await spaceClient.listSpaces(
    new Space.ListSpacesRequest()
      .setProject(
        new Space.ProjectReference().setProjectid(projectInfo.projectId)
      )
      .setStudio(new Space.StudioReference().setStudioid(projectInfo.studioId)),
    metadata
  );

  return remoteSpaces.toObject().spacesList.map((space) => {
    return {
      spaceId: space.reference.spaceid,
      ...projectInfo,
      ...space,
    };
  });
}

export async function getSpaceInfo(spaceInfo, metadata) {
  let response = await spaceClient.getSpace(
    new Space.GetSpaceRequest()
      .setStudio(new Space.StudioReference().setStudioid(spaceInfo.studioId))
      .setProject(
        new Space.ProjectReference().setProjectid(spaceInfo.projectId)
      )
      .setSpace(new Space.SpaceReference().setSpaceid(spaceInfo.spaceId)),
    metadata
  );

  return response.toObject().info;
}

export async function getProjectInfo(projectInfo, metadata) {
  //console.log(projectInfo);

  let response = await spaceClient.getProject(
    new Space.GetProjectRequest()
      .setStudio(new Space.StudioReference().setStudioid(projectInfo.studioId))
      .setProject(
        new Space.ProjectReference().setProjectid(projectInfo.projectId)
      ),
    metadata
  );

  return {
    name: response.getInfo().getProperties().getName(),
    description: response.getInfo().getProperties().getDescription(),
    created: response.getInfo().getCreatetime(),
  };
}

export async function setProjectProperties(projectProperties, metadata) {
  //console.log(projectProperties);
  return spaceClient.setProjectProperties(
    new Space.SetProjectPropertiesRequest()
      .setStudio(
        new Space.StudioReference().setStudioid(projectProperties.studioId)
      )
      .setProject(
        new Space.ProjectReference().setProjectid(projectProperties.projectId)
      )
      .setProperties(
        new Space.ProjectProperties()
          .setName(projectProperties.name)
          .setDescription(projectProperties.description)
      ),
    metadata
  );
}

export async function createProject(projectInfo, metadata) {
  return spaceClient.createProject(
    new Space.CreateProjectRequest()
      .setStudio(projectInfo.studioId)
      .setProperties(
        new Space.ProjectProperties()
          .setName(projectInfo.title)
          .setDescription(projectInfo.description)
      ),
    metadata
  );
}

export async function removeProject(projectInfo, metadata) {
  return spaceClient.removeProject(
    new Space.RemoveProjectRequest()
      .setProject(
        new Space.ProjectReference().setProjectid(projectInfo.projectId)
      )
      .setStudio(new Space.StudioReference().setStudioid(projectInfo.studioId)),
    metadata
  );
}

export async function listStudios(metadata) {
  return spaceClient.listStudios(new Space.ListStudiosRequest(), metadata);
}

export async function createStudio(studioInfo, metadata) {
  return spaceClient.createStudio(
    new Space.CreateStudioRequest().setNewstudioname(studioInfo.name),
    metadata
  );
}

export async function getGlobalStudioReference(metadata) {
  let globalStudioReference = null;

  // Check if we have already found the global studio reference

  // Find global studio
  // TODO: This is temporary the global studio reference should be hardcoded
  let response = await listStudios(metadata);

  let studios = response.getStudiosList(metadata);
  for (let i = 0; i < studios.length; i++) {
    let studioInfo = studios[i];
    if (studioInfo.getName() == "Global Studio") {
      globalStudioReference = studioInfo.getReference();
      break;
    }
  }

  // if it doesn't exist create it
  // TOOD: Remove this from web client. SysAdmin only operation
  if (globalStudioReference == null) {
    globalStudioReference = await createStudio(
      { name: "Global Studio" },
      metadata
    );
  }

  return globalStudioReference;
}

export async function listGlobalProjects(metadata) {
  // Get global studio
  // TODO: Remove when deployed
  let globalStudioReference = await getGlobalStudioReference(metadata);

  // Get project list
  let response = await spaceClient.listProjects(
    new Space.ListProjectsRequest().setStudio(globalStudioReference),
    metadata
  );

  // Build data structure to display in UI
  let remoteProjects = [];
  response.getProjectsList().forEach((projectInfo, i) => {
    remoteProjects.push({
      name: projectInfo.getProperties().getName(),
      description: projectInfo.getProperties().getDescription(),
      projectId: projectInfo.getReference().getProjectid(),
      studioId: globalStudioReference.getStudioid(),
    });
  });

  return remoteProjects;
}

export async function listNodes(info, metadata) {
  return await spaceClient.listNodes(
    new Space.ListNodesRequest()
      .setStudio(new Space.StudioReference().setStudioid(info.studioId))
      .setProject(new Space.ProjectReference().setProjectid(info.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(info.spaceId)),
    // .setPath(info.path)
    metadata
  );
}

export async function getNode(req, metadata) {
  return await spaceClient.getNode(
    new Space.GetNodeRequest()
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId))
      .setNode(req.ref)
      .setCoordinatetype(
        req.coord ? req.cord : Space.CoordinateType.COORDINATETYPE_RH_Y_UP_Z_BK
      ),
    metadata
  );
}

export async function getMesh(req, metadata) {
  return await spaceClient.getMesh(
    new Space.GetMeshRequest()
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setMeshreference(req.meshRef)
      .setCoordinatetype(Space.CoordinateType.COORDINATETYPE_RH_Y_UP_Z_BK),
    metadata
  );
}

export async function getTransform(req, metadata) {
  const coordinateTypes = {
    unity: Space.CoordinateType.COORDINATETYPE_LH_Y_UP_Z_FW,
    blender: Space.CoordinateType.COORDINATETYPE_RH_Z_UP_Y_FW,
    web: Space.CoordinateType.COORDINATETYPE_RH_Y_UP_Z_BK,
  };
  return await await spaceClient.getTransform(
    new Space.GetTransformRequest()
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId))
      .setNode(req.nodeRef)
      .setVersion(req.transformRef.getTransformversion())
      .setCoordinatetype(
        req.platform != null
          ? coordinateTypes[req.platform]
          : coordinateTypes.web
      ),
    metadata
  );
}

export async function getNodeProperties(req, metadata) {
  return await spaceClient.getNodeProperties(
    new Space.GetNodePropertiesRequest()
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId))
      .setNode(req.nodeRef),
    metadata
  );
}

export function watchSpace(req, metadata) {
  return spaceClient.watchSpace(
    new Space.WatchSpaceRequest()
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId)),
    metadata
  );
}

export function watchAssets(req, metadata) {
  return spaceClient.watchProjectAssets(
    new Space.AssetsRequest()
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId)),
    metadata
  );
}

export function removeNode(req, metadata) {
  //console.log("Space.removeNode")
  return spaceClient.removeNode(
    new Space.RemoveNodeRequest()
      .setNode(req.ref)
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId)),
    metadata
  );
}

export function setSpaceThumbnail(req, metadata) {
  //console.log("Space.setSpaceThumbnail");
  return spaceClient.setSpaceThumbnail(
    new Space.SetThumbnailRequest()
      .setThumbnail(req.data)
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId)),
    metadata
  );
}

export function getSpaceThumbnail(req, metadata) {
  //console.log("Space.getSpaceThumbnail");
  return spaceClient.getSpaceThumbnail(
    new Space.GetThumbnailRequest()
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId)),
    metadata
  );
}

export async function addComment(req, metadata) {
  // console.log("Space.addComment");
  let commentReq = null;
  let res = null;
  let comment = null;
  // console.log(req);
  try {
    comment = new Space.Comment()
      .setText(req.comment.text)
      .setPosition(
        new Space.Vector3()
          .setX(req.comment.position.x)
          .setY(req.comment.position.y)
          .setZ(req.comment.position.z)
      )
      .setNode(req.comment.nodeRef)
      .setParent(
        new Space.CommentReference().setCommentid(req.comment.parent.commentid)
      )
      .setCoordinatetype(Space.CoordinateType.COORDINATETYPE_RH_Y_UP_Z_BK);
  } catch (e) {
    console.error(e);
    throw e;
  }
  try {
    commentReq = new Space.AddCommentRequest()
      .setComment(comment)
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId));

    res = await spaceClient.addComment(commentReq, metadata);
  } catch (e) {
    console.error(e);
    throw e;
  }
  return res;
}

export function updateComment(req, metadata) {
  return spaceClient.addComment();
}

export async function listComments(req, metadata) {
  let res = null;
  try {
    res = await spaceClient.listComments(
      new Space.ListCommentsRequest()
        .setCoordinatetype(Space.CoordinateType.COORDINATETYPE_RH_Y_UP_Z_BK)
        .setStudio(new Space.StudioReference().setStudioid(req.studioId))
        .setProject(new Space.ProjectReference().setProjectid(req.projectId))
        .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId)),
      metadata
    );

    //console.log(res.toObject());
  } catch (e) {
    console.error("Space.listComments");
    console.error(e);
    throw e;
  }

  if (res == null) {
    return [];
  }

  // Otherwise put it into json format
  let json = null;
  try {
    json = res.toObject().commentsList;
  } catch (e) {
    console.error(e);
  }

  return json;
}

export function deleteComment(req, metadata) {
  return spaceClient.deleteComment(
    new Space.DeleteCommentRequest()
      .setCommentreference(
        new Space.CommentReference().setCommentid(req.commentId)
      )
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId)),
    metadata
  );
}

export function getClientStatus(req, metadata) {
  return spaceClient.getClientStatus(
    new Space.GetClientStatusRequest()
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId)),
    metadata
  );
}

export function setClientStatus(req, metadata) {
  return spaceClient.setClientStatus(
    new Space.SetClientStatusRequest()
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId)),
    metadata
  );
}

export function setUserRole(req, metadata) {
  return spaceClient.setUserRole(
    new Space.SetUserRoleRequest()
      .setRole(Space.Role.ROLE_COLLABORATOR)
      .setEmail(req.email)
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId)),
    metadata
  );
}

export function getPermissions(req, metadata) {
  return spaceClient.getPermissions(
    new Space.GetPermissionsRequest()
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId)),
    metadata
  );
}

export function readLights(req, metadata) {
  // console.log("Space.readLights")
  const _req = new Space.ReadLightsRequest()
    .setStudio(new Space.StudioReference().setStudioid(req.studioId))
    .setProject(new Space.ProjectReference().setProjectid(req.projectId))
    .setIncludeList([req.lightRef]);
  // console.log(_req)
  return spaceClient.readLights(_req, metadata);
}

export function readCameras(req, metadata) {
  // console.log("Space.readLights")
  const _req = new Space.ReadCamerasRequest()
    .setStudio(new Space.StudioReference().setStudioid(req.studioId))
    .setProject(new Space.ProjectReference().setProjectid(req.projectId))
    .setIncludeList([req.cameraRef]);
  // console.log(_req)
  return spaceClient.readCameras(_req, metadata);
}

export function addTags(req, metadata) {
  // console.log("Space.readLights")
  const _req = new Space.TaggingRequest()
    .setStudio(new Space.StudioReference().setStudioid(req.studioId))
    .setProject(new Space.ProjectReference().setProjectid(req.projectId))
    .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId))
    .setTagsList(req.tags);
  console.log(_req);
  console.log(req.tags);
  return spaceClient.addTags(_req, metadata);
}

export function removeTags(req, metadata) {
  // console.log("Space.readLights")
  const _req = new Space.TaggingRequest()
    .setStudio(new Space.StudioReference().setStudioid(req.studioId))
    .setProject(new Space.ProjectReference().setProjectid(req.projectId))
    .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId))
    .setTagsList(req.tags);
  console.log(_req);
  console.log(req.tags);
  return spaceClient.removeTags(_req, metadata);
}

export function createNodes(req, metadata) {
  return spaceClient.createNodes(
    new Space.CreateNodesRequest()
      .setStudio(new Space.StudioReference().setStudioid(req.studioId))
      .setProject(new Space.ProjectReference().setProjectid(req.projectId))
      .setSpace(new Space.SpaceReference().setSpaceid(req.spaceId))
      .setNodesList([req.node])
      .setPlatformtype(Space.Platform.PLATFORM_WEB),
    metadata
  );
}
