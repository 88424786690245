// React
import React from "react";

// Massless
import { CommandBar } from "../components/CommandBar";
import { useKeys } from "./KeysContext";

export const CommandBarContext = React.createContext();
export const useCommands = () => React.useContext(CommandBarContext);

var _commands = [];

export function CommandBarProvider({ children }) {
  const [commands, setCommands] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const registerCommands = (newCommands) => {
    // console.log("CommandBarContext>");
    // console.log(_commands);
    // console.log(newCommands);
    _commands.push(...newCommands);
    setCommands(_commands);
  };

  const unregisterCommands = (removeCommands) => {
    removeCommands.forEach((delCmd) => {
      _commands = _commands.filter((cmd) => {
        return cmd.id != delCmd.id;
      });
      // console.log(_commands)
    });
    setCommands(_commands);
  };

  const contextObject = { registerCommands, unregisterCommands };

  /*** Key binding *******************************************/

  const { bindKeys, unbindKeys, registerKeys } = useKeys();

  React.useEffect(() => {
    registerKeys([
      {
        ctrlKey: true,
        code: "KeyM",
        command: () => {
          setOpen(!open);
        },
      },
    ]);
  }, []);

  React.useEffect(() => {
    if (open) {
      unbindKeys();
      console.log("CommandBarContext> Opening");
      console.log(_commands);
    } else {
      bindKeys();
    }
  }, [open]);

  return (
    <CommandBarContext.Provider value={contextObject}>
      <CommandBar open={open} setOpen={setOpen} commands={commands} />
      {children}
    </CommandBarContext.Provider>
  );
}
