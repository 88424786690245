/*

This context is required in order to connect to a Space when
a component mounts and disconnect to the Space when the component unmounts.

Previously the SpaceProxy was constructed in the SpaceViewer, however,
when attempting to disconnect on unmount the SpaceProxy object would already
be destroyed by the time the unmount function was called, preventing
a disconnect.

We now call connectSpace and disconnectSpace on mount/unmount of SpaceViewer
- Jack

*/

// React
import React from "react";

// Massless
import { useSpace } from "./SpaceContext";
import { SpaceProxy } from "../massless/SpaceProxy";

// Context
export const SpaceProxyContext = React.createContext();
export const useSpaceProxy = () => React.useContext(SpaceProxyContext);

// Provider
export function SpaceProxyProvider({ children }) {
  const { getMetadata } = useSpace();

  const [spaceProxy, setSpaceProxy] = React.useState(null);

  const connectSpace = (spaceInfo, threeView, callbacks) => {
    setSpaceProxy(
      new SpaceProxy(spaceInfo, threeView, callbacks, getMetadata())
    );
  };
  const disconnectSpace = () => {
    if (spaceProxy != null) {
      spaceProxy.disconnect();
    }
  };

  const contextObject = {
    spaceProxy,
    connectSpace,
    disconnectSpace,
  };

  return (
    <SpaceProxyContext.Provider value={contextObject}>
      {children}
    </SpaceProxyContext.Provider>
  );
}
