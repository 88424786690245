import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import InfoIcon from "@material-ui/icons/InfoOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import SvgIcon from "@material-ui/core/SvgIcon";

import Avatar from "@material-ui/core/Avatar";

// Massless
import { useAuth } from "../context/AuthContext";

import { HelpDialog } from "../components/HelpDialog";

import { sAnalyticsTrack, trackUser } from "../massless/AnalyticsClient";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 1,
    borderTop: "1px solid #D8E0ED",
    // color: "#000000",
  },
  bottomNavIcon: { color: "#A9BDF1", padding: "6px 12px" },
  navLabel: { color: "#273E78", opacity: "1 !important" },
  newSpaceButton: { position: "absolute", top: "-30px" },
  userAvatar: { width: "24px", height: "24px" },
});

function HomeIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.88867 10L11.89 3.99867L17.8913 10H17.89V20H5.89001V10H5.88867ZM3.89001 11.9987L2.4132 13.4755L1 12.0623L10.477 2.58529C11.2574 1.8049 12.5226 1.8049 13.303 2.58529L22.78 12.0623L21.3668 13.4755L19.89 11.9987V20C19.89 21.1046 18.9946 22 17.89 22H5.89001C4.78545 22 3.89001 21.1046 3.89001 20V11.9987Z"
      />
    </SvgIcon>
  );
}

function DocumentationIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 22H6C4.34315 22 3 20.6569 3 19V5C3 3.34315 4.34315 2 6 2H21V18C20.4477 18 20 18.4477 20 19C20 19.5523 20.4477 20 21 20V22ZM18 19C18 18.6494 18.0602 18.3128 18.1707 18H6C5.44772 18 5 18.4477 5 19C5 19.5523 5.44772 20 6 20H18.1707C18.0602 19.6872 18 19.3506 18 19ZM6 4H19V16H6C5.64936 16 5.31278 16.0602 5 16.1707V5C5 4.44772 5.44772 4 6 4Z"
      />
    </SvgIcon>
  );
}

export default function SimpleBottomNavigation() {
  const classes = useStyles();

  let { profile, isAuthenticated } = useAuth();

  const [value, setValue] = React.useState(0);

  let [nickname, setNickname] = React.useState("");
  let [picture, setPicture] = React.useState("");

  //#region Email Dialog
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false);
  //#endregion

  React.useEffect(() => {
    if (profile == null) return;
    setNickname(profile.nickname);
    setPicture(profile.picture);
  }, [profile]);

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        {isAuthenticated ? (
          <>
            <BottomNavigationAction
              classes={{
                iconOnly: classes.bottomNavIcon,
                label: classes.navLabel,
              }}
              icon={<HomeIcon />}
              label="Explore"
              component={Link}
              to={"/"}
            />

            <BottomNavigationAction
              classes={{
                iconOnly: classes.bottomNavIcon,
                label: classes.navLabel,
              }}
              icon={<DocumentationIcon />}
              label="Docs"
              onClick={() => {
                window.open("https://docs.space.massless.io/");
                trackUser("side-bar-documentation-click");
              }}
            />
            <BottomNavigationAction
              classes={{
                iconOnly: classes.bottomNavIcon,
                label: classes.navLabel,
              }}
              icon={<HelpOutlineIcon />}
              label="Help"
              onClick={() => {
                setOpenEmailDialog(true);
              }}
            />
            <BottomNavigationAction
              classes={{
                iconOnly: classes.bottomNavIcon,
                label: classes.navLabel,
              }}
              icon={
                <Avatar
                  alt="User Avatar"
                  className={classes.userAvatar}
                  src={picture}
                />
              }
              label="Profile"
              component={Link}
              to={"/account/spaces"}
            />
          </>
        ) : (
          <>
            <BottomNavigationAction
              classes={{
                iconOnly: classes.bottomNavIcon,
                label: classes.navLabel,
              }}
              icon={<InfoIcon />}
              label="Learn More"
              onClick={() => {
                window.open("https://massless.io/massless-space");
                trackUser("side-bar-documentation-click");
              }}
            />
            <BottomNavigationAction
              classes={{
                iconOnly: classes.bottomNavIcon,
                label: classes.navLabel,
              }}
              icon={<DocumentationIcon />}
              label="Documentation"
              onClick={() => {
                window.open("https://docs.space.massless.io/");
                trackUser("side-bar-documentation-click");
              }}
            />
          </>
        )}
      </BottomNavigation>
      {/* Email Enquiry Dialog */}
      <HelpDialog
        open={openEmailDialog}
        callbacks={{
          setOpenEmailDialog: setOpenEmailDialog,
        }}
      />
    </>
  );
}
