// React
import React from "react";
import { useLocation } from "react-router-dom";
// Material

// Massless
import AuthPasswordless from "../massless/AuthPasswordless";

// Main
export const AuthContext = React.createContext();

export const useAuth = () => React.useContext(AuthContext);

export function AuthProvider({ children }) {
  const location = useLocation();

  const [auth0Client, setAuth0Client] = React.useState(new AuthPasswordless());
  const [authStage, setAuthStage] = React.useState("checkSession"); // Stages ["checkSession","login","authenticated"]
  const [profile, setProfile] = React.useState(null);
  const [authResult, setAuthResult] = React.useState(null);
  const [metadata, setMetadata] = React.useState({});

  const [isLoading, setIsLoading] = React.useState(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  // On load
  React.useEffect(() => {
    // Check for existing session
    if (location.pathname != "/login") {
      auth0Client
        .checkSession()
        .then((authResult) => {
          setAuthResult(authResult);
          setMetadata({ Authorization: "Bearer " + authResult.accessToken });
          setProfile(authResult.idTokenPayload);
          setAuthStage("authenticated");
          setIsLoading(false);
          setIsAuthenticated(true);
        })
        .catch((err) => {
          // console.log("Check session error");
          console.error(err);
          if (err.code == "login_required") {
            setAuthStage("login");
          } else {
            console.error({
              severity: "error",
              message: "Problem checking session",
            });
            setAuthStage("login");
          }
          setIsAuthenticated(false);
          setIsLoading(false);
        });
    } else {
      // console.log("auth context login path")
      setIsLoading(false)
    }
  }, []);

  const authStart = (email) => {
    return auth0Client.start(email);
  };
  const authSignIn = (code) => {
    return auth0Client.signIn(code);
  };
  const authSignOut = () => {
    auth0Client.signOut();
  };
  const handleAuthentication = async () => {
     let authResult = await auth0Client.handleAuthentication();
    //  console.log("authcontext handleAuthentication")
    //  console.log(authResult)
     if (authResult) {
      setAuthResult(authResult);
      setMetadata({ Authorization: "Bearer " + authResult.accessToken });
      setProfile(authResult.idTokenPayload);
      setAuthStage("authenticated");
      setIsLoading(false);
      setIsAuthenticated(true);
     }
     
     return authResult;
  };
  const authorize = (params) => {
    return auth0Client.authorize(params);
  };
  const authObject = {
    isLoading,
    isAuthenticated,
    profile,
    metadata,
    authorize,
    authStart,
    authSignIn,
    authSignOut,
    handleAuthentication,
    setIsAuthenticated,
  };

  return (
    <AuthContext.Provider value={authObject}>{children}</AuthContext.Provider>
  );
}
