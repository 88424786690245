// React
import React from "react";
import MetaTags from "react-meta-tags";

// Material
import Typography from "@material-ui/core/Typography";
import {
  Container,
  Box,
  TextField,
  Button,
  Paper,
  Fab,
  Grid,
  GridList,
  GridListTile,
} from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import { makeStyles } from "@material-ui/core/styles";

// Massless
import "../css/PluginsView.css";

import { sAnalyticsTrack } from "../massless/AnalyticsClient";
import { useAuth } from "../context/AuthContext";
// import { useStyles } from "../Style";
//import { addSnack } from "../context/"

import UnityLogo from "../imgs/UnityLogoWhiteBg.png";
import BlenderLogo1x from "../imgs/BlenderLogo@1x.png";
import BlenderLogo2x from "../imgs/BlenderLogo@2x.png";
import UnityLogo1x from "../imgs/UnityLogo@1x.png";
import UnityLogo2x from "../imgs/UnityLogo@2x.png";
import DownloadIcon1x from "../imgs/DownloadIcon@1x.png";
import DownloadIcon2x from "../imgs/DownloadIcon@2x.png";
import PowerIcon from "@material-ui/icons/Power";

const useStyles = makeStyles((theme) => ({
  pluginsPage: {
    height: "90vh",
    // alignItems: "center",
    // justifyContent: "center",
    display: "flex",
  },
  pluginsContainer: {
    justifyContent: "center",
    alignContent: "center",
  },
  pluginDetails: {
    paddingRight: "10%",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0",
    },
    paddingBottom: "20px",
  },
  // Plugin button
  pluginsButton: {
    cursor: "pointer",
    borderRadius: "30px",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%), #A6A5B0",
    "&:hover": {
      background:
        "linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%), #A6A5B0",
    },
    boxShadow:
      "0px 2px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 2px 2px rgba(255, 255, 255, 0.25)",
    margin: "32px",
    textDecoration: "none",
    color: "gray",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    margin: "0",
    minHeight: "140px",
  },
  pluginAppLogo: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    width: "75%",
  },
  pluginAppLogoImg: {
    height: "50px",
    [theme.breakpoints.only("sm")]: {
      height: "40px",
    },
    alignSelf: "center",
  },
  pluginDownloadIcon: {
    display: "flex",
    width: "25%",
    justifyContent: "center",
    borderLeft: "2px solid #8E8EA4",
    // maxWidth: "55px",
  },
  pageBody: {
    margin: "0",
  },
  pageTitle: {
    margin: "0 8px 0 0",
  },
  pluginTitle: {
    padding: "0 0 10px 0",
  },
}));

function PluginsPage(props) {
  const classes = useStyles();

  /* Analytics ********************************************************************/

  const { profile } = useAuth();

  const trackUser = (trackId, properties = {}) => {
    if (profile == null) {
      sAnalyticsTrack(trackId, properties);
      return;
    }
    let userId = profile.sub.split("|")[1];
    sAnalyticsTrack(trackId, {
      ...{ id: userId, email: profile.email },
      ...properties,
    });
  };

  React.useEffect(() => {
    if (profile == null) {
      trackUser("plugins-view-signed-out");
      return;
    }
    trackUser("plugins-view-signed-in");
  }, [profile]);

  // React.useEffect(() => {
  //   sAnalyticsPage();
  // }, []);

  /******************************************************************************/

  return (
    <>
      <MetaTags>
        <title>
          {"Massless Space | Plugins"}
        </title>
        <meta
          name="description"
          content="Massless Space. Multi-user real-time collaboration across Unity, Blender and Chrome"
        />
        <meta
          property="og:title"
          content={
            "Massless Space | Plugins"
          }
        />
        <meta
          property="og:image"
          content="https://massless.io/wp-content/uploads/2020/06/MasslessSpaceBlenderUnityPreviewFacebook.png"
        />
      </MetaTags>
      <Container className={classes.pluginsPage}>
        <Grid container cellHeight={400} className={classes.pluginsContainer}>
          <Grid item xs={12} sm={5} md={4}>
            <Box className={classes.pluginDetails}>
              <Box
                className={classes.pluginTitle}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <h2 className={classes.pageTitle}>Plugins</h2>
                <PowerIcon />
              </Box>
              <p className={classes.pageBody}>
                See the{" "}
                <a
                  href="https://docs.space.massless.io/getting-started/"
                  target="blank"
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  Getting Started
                </a>{" "}
                guide to find out how to connect Unity or Blender to Massless
                Space
              </p>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7} md={6}>
            {/* <h2 className={classes.pageTitle}>Downloads</h2> */}
            <Box display="flex" flexDirection="row">
              <Grid container cellHeight={400} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Box>
                    <a
                      href={process.env.REACT_APP_UNITY_PLUGIN}
                      onClick={() => {
                        trackUser("plugins-view-unity-plugin-click");
                      }}
                    >
                      <div className={classes.pluginsButton}>
                        <div className={classes.pluginAppLogo}>
                          <img
                            src={UnityLogo1x}
                            srcSet={UnityLogo1x + " 1x," + UnityLogo2x + " 2x"}
                            className={classes.pluginAppLogoImg}
                          />
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            align="center"
                          >
                            {process.env.REACT_APP_UNITY_VERSION}
                          </Typography>
                        </div>
                        <div className={classes.pluginDownloadIcon}>
                          <img
                            src={DownloadIcon1x}
                            srcSet={
                              DownloadIcon1x + " 1x," + DownloadIcon2x + " 2x"
                            }
                          />
                        </div>
                      </div>
                    </a>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box>
                    <a
                      href={process.env.REACT_APP_BLENDER_PLUGIN}
                      onClick={() => {
                        trackUser("plugins-view-blender-plugin-click");
                      }}
                    >
                      <div className={classes.pluginsButton}>
                        <div className={classes.pluginAppLogo}>
                          <img
                            src={BlenderLogo1x}
                            srcSet={
                              BlenderLogo1x + " 1x," + BlenderLogo2x + " 2x"
                            }
                            className={classes.pluginAppLogoImg}
                          />
                          <Typography
                            variant="subtitle1"
                            color="text.primary"
                            align="center"
                          >
                            {process.env.REACT_APP_BLENDER_VERSION}
                          </Typography>
                        </div>
                        <div className={classes.pluginDownloadIcon}>
                          <img
                            src={DownloadIcon1x}
                            srcSet={
                              DownloadIcon1x + " 1x," + DownloadIcon2x + " 2x"
                            }
                          />
                        </div>
                      </div>
                    </a>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default PluginsPage;
