import React from "react";

import { useHistory, useParams } from "react-router-dom";

import { Button, Fab, Hidden } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";

import { makeStyles, useTheme } from "@material-ui/core/styles";

// Massless
import { useAuth } from "../context/AuthContext";
import { useSpace } from "../context/SpaceContext";
import { useSnackbar } from "../context/SnackbarContext";

import { sAnalyticsTrack, trackUser } from "../massless/AnalyticsClient";

import { NewSpaceDialog } from "../components/NewSpaceDialog";

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    position: "absolute",
    right: 0,
    marginRight: "20px",
    boxShadow: "inset 0px 0px 0px 3px #6E54F4",
    textTransform: "none",
    fontSize: 16,
    fontWeight: 400,
    padding: "6px 12px",
    // border: "3px solid #6E54F4",
    lineHeight: 1.5,
    backgroundColor: "rgba(1,1,1,0)",
    color: "#6E54F4",
    borderRadius: "50px",
    "&:hover": {
      color: "#ffffff",
      background: "#6E54F4",
      boxShadow:
        "0px 4px 4px rgba(37, 14, 156, 0.2), 0px 2px 2px rgba(37, 14, 156, 0.2)",
      // border: "3px solid #F0F2F5",
      // boxShadow: "inset 0px 0px 0px 3px #F0F2F5",
      // boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#6E54F4",
      borderColor: "#6E54F4",
    },
    // "&:focus": {
    //   boxShadow: "0 0 0 0.2rem rgba(110,84,244,.5)",
    // },
  },
  mobileSpaceFab: {
    zIndex: "10",
    position: "fixed",
    bottom: 0,
    right: 0,
    margin: "66px 10px",
    background: "#6E54F4",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#8257ff",
    },
  },
}));

export default function NewSpaceButton(props) {
  const classes = useStyles();

  let history = useHistory();

  const trackUser = (trackId, properties = {}) => {
    if (profile == null) {
      sAnalyticsTrack(trackId, properties);
      return;
    }
    let userId = profile.sub.split("|")[1];
    sAnalyticsTrack(trackId, {
      ...{ id: userId, email: profile.email },
      ...properties,
    });
  };

  let { authStage, profile, isAuthenticated, signOut } = useAuth();

  //#region Setup
  let {
    removeProject,
    setProjectProperties,
    getProjectInfo,
    createSpace,
  } = useSpace();
  const { addSnack } = useSnackbar();

  const [updateSpaceGallery, setUpdateSpaceGallery] = React.useState(true);

  const [projectName, setProjectName] = React.useState("");
  const [projectDescription, setProjectDescription] = React.useState("");
  let projectInfo = {
    studioId: props.props.studioId,
    projectId: props.props.projectId,
  };
  //#endregion

  //#region List Spaces
  React.useEffect(() => {
    // Get project info on load
    getProjectInfo(projectInfo).then((remoteProjectInfo) => {
      let description = remoteProjectInfo.description;
      if (description == "") {
        description = "This project doesn't have a description :(";
      }
      setProjectName(remoteProjectInfo.name);
      setProjectDescription(description);
    });
    console.log(projectInfo);
    console.log(props);
  }, []);
  //#endregion

  //#region Create Space
  const createSpaceClicked = async (spaceName, spaceDescription) => {
    if (spaceName == "") {
      addSnack({
        severity: "error",
        message: "Please enter a name for your space",
      });
      return;
    }
    setNewSpaceFormOpen(false);
    let spaceInfo = {
      name: spaceName,
      description: spaceDescription,
      studioId: projectInfo.studioId,
      projectId: projectInfo.projectId,
    };

    createSpace(spaceInfo)
      .then((spaceRef) => {
        addSnack({ severity: "success", message: "New Space created" });
        setUpdateSpaceGallery(false);
        setUpdateSpaceGallery(true);

        history.replace("/s/" + spaceRef.getSpaceid());
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not create new Space",
        });
      });
  };

  const [newSpaceFormOpen, setNewSpaceFormOpen] = React.useState(false);

  return (
    <>
      <Hidden mdDown>
        <Button
          startIcon={<AddIcon />}
          className={classes.buttonRoot}
          classes={{ label: classes.buttonLabel }}
          variant="contained"
          // color="secondary"
          onClick={() => {
            trackUser("project-new-space-clicked", {
              studioId: projectInfo.studioId,
              projectId: projectInfo.projectId,
            });
            setNewSpaceFormOpen(true);
          }}
          // disableRipple
        >
          New Space
        </Button>
      </Hidden>
      <Hidden mdUp>
        <Fab
          className={classes.mobileSpaceFab}
          variant="round"
          color="secondary"
          onClick={() => {
            trackUser("new-space-clicked", {
              studioId: projectInfo.studioId,
              projectId: projectInfo.projectId,
            });
            setNewSpaceFormOpen(true);
          }}
        >
          <AddIcon fontSize="large" />
        </Fab>
      </Hidden>
      {/******************************   NEW SPACE DIALOG  ****************************/}
      <NewSpaceDialog
        open={newSpaceFormOpen}
        callbacks={{
          setNewSpaceFormOpen: setNewSpaceFormOpen,
          setUpdateSpaceGallery: setUpdateSpaceGallery,
          projectInfo: projectInfo,
          createSpaceClicked: createSpaceClicked,
          trackUser: trackUser,
        }}
      />
    </>
  );
}
