// React
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";

// Material
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import { Tooltip } from "@material-ui/core";

import SvgIcon from "@material-ui/core/SvgIcon";

import PowerIcon from "@material-ui/icons/Power";
import PublicIcon from "@material-ui/icons/Public";

import clsx from "clsx";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DocsIcon from "@material-ui/icons/Description";
import LearnIcon from "@material-ui/icons/MenuBook";

// Massless
import { useAuth } from "../context/AuthContext";
import { useCommands } from "../context/CommandBarContext";

import { sAnalyticsTrack, trackUser } from "../massless/AnalyticsClient";

import SpaceLogo1x from "../imgs/SpaceLogo1x.png";
import SpaceLogo2x from "../imgs/SpaceLogo2x.png";

import Spiral from "../imgs/Massless_Spiral.png";
import SpiralAnim from "../imgs/Massless-Spiral-2Loops.gif";

import NewSpaceButton from "./NewSpaceButton";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    marginBottom: "30px",
  },
  spaceLogo: { margin: "auto" },
  spaceSpiral: { width: "70px", margin: "auto 5px auto auto" },
  mobileSpaceLogo: {
    width: "70%",
    maxWidth: "300px",
    margin: "auto auto auto 5px",
  },
}));

export default function ProjectTopNav(props) {
  //#region  Analytics
  let { authStage, profile, isAuthenticated, signOut } = useAuth();
  //#endregion

  //#region Nav
  const classes = useStyles();
  let history = useHistory();
  //let { open, setOpen } = useSnackbar();

  let [nickname, setNickname] = React.useState("");
  let [picture, setPicture] = React.useState("");

  React.useEffect(() => {
    if (profile == null) return;
    setNickname(profile.nickname);
    setPicture(profile.picture);
  }, [profile]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const preLoginMenu = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          {
            label: "Learn More",
            link: "https://massless.io/massless-space/",
            id: "nav-learn-more-click",
            icon: <LearnIcon />,
          },
          {
            label: "Docs",
            link: "https://docs.space.massless.io/",
            id: "nav-learn-more-click",
            icon: <DocsIcon />,
          },
        ].map((buttonInfo) => (
          <ListItem
            button
            key={buttonInfo.label}
            component="a"
            href={buttonInfo.link}
            onClick={() => {
              trackUser(buttonInfo.id);
            }}
          >
            <ListItemIcon color="white">{buttonInfo.icon}</ListItemIcon>
            <ListItemText primary={buttonInfo.label} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const postLoginMenu = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {/* {['Projects', 'Plugins', 'Docs'].map((text, index) => ( */}
        <ListItem button key="Account" component={Link} to="/account">
          <ListItemIcon color="white">
            <Avatar
              alt="User Avatar"
              className={classes.userAvatar}
              src={picture}
            />
          </ListItemIcon>
          <ListItemText primary={nickname} />
        </ListItem>
        <Divider />
        <ListItem button key="Projects" component={Link} to="/">
          <ListItemIcon color="white">
            <PublicIcon />
          </ListItemIcon>
          <ListItemText primary="Global Studio" />
        </ListItem>
        <ListItem button key="Plugins" component={Link} to="/plugins">
          <ListItemIcon color="white">
            <PowerIcon />
          </ListItemIcon>
          <ListItemText primary="Plugins" />
        </ListItem>
        <ListItem
          button
          key="Docs"
          component="a"
          href="https://docs.space.massless.io/"
          target="blank"
        >
          <ListItemIcon color="white">
            <DocsIcon />
          </ListItemIcon>
          <ListItemText primary="Docs" />
        </ListItem>
        {/* ))} */}
      </List>
    </div>
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //#endregion

  //#region Drawer
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  //#endregion

  //#region Commands
  const { registerCommands } = useCommands();

  const addCommands = () => {
    registerCommands([
      {
        id: "explore",
        name: "Explore",
        description: "Explore Spaces",
        shortcut: "",
        command: () => {
          history.replace("/");
        },
      },
      {
        id: "connect-to-unity",
        name: "Connect to Unity",
        description: "Download Unity Plugin",
        shortcut: "",
        command: () => {
          window.open(process.env.REACT_APP_UNITY_PLUGIN);
          window.open("https://docs.space.massless.io/getting-started#unity");
        },
      },
      {
        id: "connect-to-blender",
        name: "Connect to Blender",
        description: "Download Blender Addon",
        shortcut: "",
        command: () => {
          window.open(process.env.REACT_APP_BLENDER_PLUGIN);
          window.open("https://docs.space.massless.io/getting-started#blender");
        },
      },
      {
        id: "documentation",
        name: "Documentation",
        description: "Open documentation",
        shortcut: "",
        command: () => {
          window.open("https://docs.space.massless.io/");
        },
      },
      {
        id: "plugins",
        name: "Plugins",
        description: "Open plugins page",
        shortcut: "",
        command: () => {
          history.replace("/plugins");
        },
      },
      {
        id: "account",
        name: "Account",
        description: "Open account page",
        shortcut: "",
        command: () => {
          history.replace("/account");
        },
      },
    ]);
  };
  //#endregion

  //#region On Mount
  React.useEffect(() => {
    addCommands();
    console.log(props.props);
  }, []);
  //#endregion

  return (
    <>
      <Box className={classes.root}>
        {/* <Hidden mdDown>
          <img
            className={classes.spaceLogo}
            src={SpaceLogo1x}
            // srcSet={SpaceLogo1x + " 1x," + SpaceLogo2x + " 2x"}
          />
        </Hidden>
        <Hidden mdUp> */}
        <img className={classes.spaceSpiral} src={SpiralAnim} />
        <img
          className={classes.mobileSpaceLogo}
          src={SpaceLogo1x}
          srcSet={SpaceLogo1x + " 1x," + SpaceLogo2x + " 2x"}
        />
        {/* </Hidden> */}
        <NewSpaceButton props={props.props} />
      </Box>
    </>
  );
}
