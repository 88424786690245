// React
import React, { Component } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

// Material
import { Container, Box, Typography } from "@material-ui/core";
import { useSnackbar } from "../context/SnackbarContext";
import CircularProgress from "@material-ui/core/CircularProgress";

// Massless
// import { useStyles } from "../Style";
import { useAuth } from "../context/AuthContext";
import { sAnalyticsIdentify, trackUser } from "../massless/AnalyticsClient";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  bodyContainer: {
    maxWidth: "none",
    padding: "20px 20px 20px 20px",
    color: "white",
  },
}));

function LoginRedirectPage() {
  const classes = useStyles();
  let history = useHistory();
  const { addSnack } = useSnackbar();
  const { handleAuthentication } = useAuth();
  const [cookies, setCookie] = useCookies(["destination"]);

  console.log();
  React.useEffect(() => {
    // This loads the user profile information into authPasswordless
    // console.log("handleAuthentication")
    handleAuthentication()
      .then((authResult) => {
        // console.log("handleAuthentication response")
        // console.log(authResult)
        // Identify user with analytics
        const profile = authResult.idTokenPayload;
        // console.log("profile")
        // console.log(profile)
        let userId = profile.sub.split("|")[1];
        sAnalyticsIdentify(userId, {
          name: profile.name,
          email: profile.email,
        });
        trackUser("login-redirect-authenticated", profile);
        // Check if we were on our way somewhere before login
        // console.log("destination: ")
        // console.log(cookies.destination)
        if (
          cookies.destination &&
          cookies.destination != "" &&
          cookies.destination != "/"
        ) {
          const location = cookies.destination;
          setCookie("destination", "", { path: "/" });
          // console.log("historyReplace: ");
          // console.log(location);
          history.replace(location);
        } else {
          // Redirect to root\
          // console.log("redirecting to root")
          history.replace("/");
        }
      })
      .catch((err) => {
        // console.log("problem authenticating")
        console.error(err);
        addSnack({ severity: "error", message: "Problem authenticating" });
      });
  }, []);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="center"
      style={{ margin: 16 }}
    >
      <CircularProgress />
    </Box>
  );
}

export default LoginRedirectPage;
