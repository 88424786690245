/* 
https://auth0.com/blog/react-tutorial-building-and-securing-your-first-app/#Securing-your-React-App 
https://auth0.com/docs/libraries/auth0js/v9
https://auth0.github.io/auth0.js/index.html
*/

import auth0 from "auth0-js";

export default class AuthPasswordless {
  constructor() {
    this.clientID = "XpJOUb1zxuy2CzL2XvR3SfOK59angjyj";

    // console.log("Auth passwordless")
    // console.log(process.env.REACT_APP_AUTH_DOMAIN)

    this.webAuth = new auth0.WebAuth({
      clientID: this.clientID,
      domain: "auth.massless.io",
      redirectUri: process.env.REACT_APP_LOGIN_CALLBACK,
      responseType: "token id_token",
      audience: "https://api.space.massless.io",
      scope: "openid profile email read:current_user",
      overrides: {
        __tenant: "massless.auth0.com",
        __token_issuer: "https://auth.massless.io/"
      },
    });

    //console.log( process.env.REACT_APP_LOGIN_CALLBACK);
  }

  checkSession() {
    const setAuthResult = (authResult) => {
      this.authResult = authResult;
    };
    return new Promise((resolve, reject) => {
      this.webAuth.checkSession(
        {
          audience: "https://api.space.massless.io",
          scope: "openid profile email read:current_user",
        },
        (err, authResult) => {
          // Authentication tokens or error
          if (err) {
            //console.log("Check session err");
            console.error(err);
            reject(err);
          }
          console.log("Auth session");
          console.log(authResult);
          setAuthResult(authResult);
          resolve(authResult);
        }
      );
    });
  }

  getMetadata() {
    return { Authorization: "Bearer " + this.authResult.accessToken };
  }

  // setCookie() {
  //   let sessionObj = {
  //     token: this.accessToken,
  //     profile: this.profile,
  //     expires: this.expiresAt,
  //   };
  //   let encodedObj = btoa(JSON.stringify(sessionObj));
  //   document.cookie = "spaceSession=" + encodedObj;
  //   //console.log("setting cookie");
  //   //console.log(document.cookie);
  // }

  // getCookie() {
  //   //console.log(document.cookie);
  //   if (
  //     document.cookie.split(";").some((item) => {
  //       return item.trim().indexOf("spaceSession=") == 0;
  //     })
  //   ) {
  //     //console.log('The cookie "spaceSession" exists');
  //     const sessionValue = document.cookie
  //       .split("; ")
  //       .find((row) => row.startsWith("spaceSession"))
  //       .split("=")[1];
  //     if (sessionValue.length > 0) {
  //       const decodedObj = atob(sessionValue);
  //       const sessionObj = JSON.parse(decodedObj);
  //       console.log(sessionObj);
  //       this.expiresAt = sessionObj.expires;
  //       // See if session is valid
  //       let tokenValid = new Date().getTime() < this.expiresAt;
  //       let iat = sessionObj.profile.iat;
  //       let nowSec = new Date().getTime() / 1000;
  //       let iatValid = nowSec < iat;
  //       let duration = 86400;

  //       let exp2 = iat + duration;
  //       console.log(nowSec + " < " + exp2);
  //       let tokenValid2 = nowSec < exp2;

  //       console.log("now: " + nowSec);
  //       console.log("iat: " + iat);
  //       console.log("exp1: " + sessionObj.profile.exp);
  //       console.log("exp2: " + exp2);
  //       console.log("dif:" + (sessionObj.profile.exp - nowSec));
  //       console.log("rel:" + (nowSec - iat));
  //       console.log("token valid1: " + tokenValid);
  //       console.log("token valid2: " + tokenValid2);
  //       if (tokenValid) {
  //         this.accessToken = sessionObj.token;
  //         this.profile = sessionObj.profile;
  //       } else {
  //         document.cookie = "spaceSession=";
  //       }
  //     } else {
  //       //console.log("Session value empty");
  //     }
  //   } else {
  //     //console.log("session cookie not found");
  //   }
  // }

  isAuthenticated() {
    return (
      this.authResult &&
      new Date().getTime() / 1000 < this.authResult.idTokenPayload.exp
    );
  }

  handleAuthentication() {
    const setAuthResult = (authResult) => {
      this.authResult = authResult;
    };
    return new Promise((resolve, reject) => {
      this.webAuth.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        // console.log("handle auth");
        // console.log(authResult);

        setAuthResult(authResult);
        //this.accessToken = authResult.accessToken;
        //this.profile = authResult.idTokenPayload;
        //this.expiresAt = authResult.idTokenPayload.exp * 1000;

        //this.setCookie();

        resolve(authResult);
      });
    });
  }

  start(emailAddress) {
    this.emailAddress = emailAddress;
    return new Promise((resolve, reject) => {
      this.webAuth.passwordlessStart(
        {
          connection: "email",
          send: "code",
          email: emailAddress,
        },
        (err, res) => {
          if (err) {
            reject(err);
          }
          resolve(res);
        }
      );
    });
  }

  signIn(verificationCode) {
    const setAuthResult = (authResult) => {
      this.authResult = authResult;
    };
    return new Promise((resolve, reject) => {
      this.webAuth.passwordlessLogin(
        {
          connection: "email",
          email: this.emailAddress,
          verificationCode: verificationCode,
          audience: "https://api.space.massless.io",
        },
        (err, res) => {
          if (err) {
            reject(err);
          }
          //console.log("signIn result");
          //console.log(res);
          setAuthResult(res);
          resolve(res);
        }
      );
    });
  }

  signOut() {
    //document.cookie = "spaceSession=";
    this.webAuth.logout({
      returnTo: process.env.REACT_APP_ROOT,
      clientID: this.clientID,
    });
  }

  authorize() {
    return this.webAuth.authorize({
      connection: "google-oauth2"
    });
  }
}

// const authPasswordless = new AuthPasswordless();

// export default authPasswordless;
