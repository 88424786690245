// React
import React from "react";
// Material
import Snackbar from "@material-ui/core/Snackbar";

import MuiAlert from "@material-ui/lab/Alert";
import { Container } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const SnackbarContext = React.createContext();

export const useSnackbar = () => React.useContext(SnackbarContext);

const AUTO_DISMISS = 5000;

export function SnackbarProvider({ children }) {
  const [open, setOpen] = React.useState(false);
  const [info, setInfo] = React.useState({ severity: "", message: "" });

  //   const handleSnackbarClose = () => {};
  //   const snackbarInfo = { severity: "success", message: "you doin good" };

  const addSnack = (alertInfo) => {
    setInfo(alertInfo);
    setOpen(true);
    // console.log(alertInfo)
  };

  const addErrorSnack = (text) => {
    addSnack({ severity: "error", message: text });
  };

  const addSuccessSnack = (text) => {
    addSnack({ severity: "success", message: text });
  };

  const addInfoSnack = (text) => {
    addSnack({ severity: "info", message: text });
  };

  const handleSnackbarClose = () => {
    setOpen(false);
  };

  const snackbarCalls = {
    addSnack,
    addErrorSnack,
    addSuccessSnack,
    addInfoSnack
  }

  return (
    <SnackbarContext.Provider value={snackbarCalls}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={info.severity}>
          {info.message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
}
