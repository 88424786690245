// React
import React, { useState, useEffect } from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import CircularProgress from "@material-ui/core/CircularProgress";

// Massless
import { useSpace } from "../../context/SpaceContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { useAuth } from "../../context/AuthContext";
import {
  sAnalyticsTrack,
} from "../../massless/AnalyticsClient";
import { ProjectCard } from "./ProjectCard";

import SceneThumb1x from "../../imgs/SceneThumb@1x.png";
import SceneThumb2x from "../../imgs/SceneThumb@2x.png";

const useStyles = makeStyles((theme) => ({
  newProjButton: {
    height: "30px",
    border: "solid 2px #5423e6",
    borderRadius: "20px",
    marginLeft: "10px",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#5423e6",
      border: "solid 2px #5423e6",
    },
    alignSelf: "center",
  },
  projectDetails: {
    [theme.breakpoints.up("sm")]: {
      paddingRight: "10%",
    },
  },
  projectDetailButtons: {
    width: "47.5%",
    margin: "20px 0 20px 5%",
    borderRadius: "100px",
  },
  // From NewProjectGrid.js
  control: {
    padding: theme.spacing(2),
  },

  projectCardAction: {
    backgroundColor: "transparent",
  },

  projGridContainer: {
    maxWidth: "none",
  },

  // SpaceGallery
  projInfoLink: {
    maxWidth: "65%",
  },
  iconButton: {
    color: "#ffffff",
    padding: "10px",
  },
  cardActionArea: {
    display: "flex",
  },
}));

const ProjectGrid = (props) => {
  /* Analytics ********************************************************************/

  const { profile } = useAuth();

  const trackUser = (trackId) => {
    if (profile == null) {
      sAnalyticsTrack(trackId, {});
      return;
    }
    let userId = profile.sub.split("|")[1];
    sAnalyticsTrack(trackId, { id: userId, email: profile.email });
  };

  /******************************************************************************/

  const classes = useStyles();

  const getGridListCols = () => {
    if (isWidthUp("xl", props.width)) {
      return 4;
    }

    if (isWidthUp("lg", props.width)) {
      return 3;
    }

    if (isWidthUp("md", props.width)) {
      return 2;
    }

    return 1;
  };

  const [projects, setProjects] = useState([]);
  const [updateProjectList, setUpdateProjectList] = useState(true);
  const { addSnack } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const { listGlobalProjects } = useSpace();

  // If we aint got no projects then gitsum
  useEffect(() => {
    if (updateProjectList) {
      setLoading(true);
      listGlobalProjects()
        .then((remoteProjects) => {
          setProjects(remoteProjects);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          addSnack({ severity: "error", message: "Could not list Projects." });
          setLoading(false);
        });
      setUpdateProjectList(false);
    }
  });

  // This isn't working at ther moment
  let ProjectCards = projects.map((project) => (
    <Grid item md={2} sm={3} xs={12}>
      <ProjectCard
        key={project.spaceId}
        title={project.name}
        description={project.description}
        studioId={project.studioId}
        projectId={project.projectId}
      />
    </Grid>
  ));

  // This is just a dummy list so that we can get the styling right for the Cards
  return (
    // <div className={classes.root}>
    <>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignContent="center"
          style={{ margin: 16 }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loading && (
        <Grid container spacing={2}>
          {ProjectCards}
        </Grid>
      )}
    </>
  );
};

export default withWidth()(ProjectGrid);
