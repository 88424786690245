// React
import React, { useState, useEffect } from "react";

// Material
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import ThumbnailIcon from "@material-ui/icons/Wallpaper";

import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import CameraAltIcon from "@material-ui/icons/CameraAlt";

// Massless
import { useAuth } from "../../context/AuthContext";
import { useCommands } from "../../context/CommandBarContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { useSpace } from "../../context/SpaceContext";

import { sAnalyticsTrack } from "../../massless/AnalyticsClient";

const useStyles = makeStyles((theme) => ({
  toolTip: {
    fontSize: "14px",
    backgroundColor: "#050510",
    borderRadius: "8px",
  },
  iconButton: {
    color: "#000000",
    paddingTop: "5px",
    paddingBottom: "5px",
    margin: "5px 10px",
  },
}));

const colorSelected = "#6E54F4";
const colorUnselected = "#A9BDF1";

function downloadImage(data, filename = "untitled.jpeg") {
  var a = document.createElement("a");
  a.href = data;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function PhotoButton(props) {
  /* Analytics ********************************************************************/

  const { profile } = useAuth();

  const trackUser = (trackId, properties = {}) => {
    if (profile == null) {
      sAnalyticsTrack(trackId, properties);
      return;
    }
    let userId = profile.sub.split("|")[1];
    sAnalyticsTrack(trackId, {
      ...{ id: userId, email: profile.email },
      ...properties,
    });
  };

  /* Photobutton ********************************************************************/

  const [openPhotoDialog, setOpenPhotoDialog] = React.useState(false);
  const [photoData, setPhotoData] = React.useState(null);
  let { setSpaceThumbnail } = useSpace();
  const { addSnack } = useSnackbar();

  //   React.useEffect(() => {
  //     if (photoData != null) {
  //         setOpenPhotoDialog(true);
  //     }
  //   }, [photoData]);
  //let takePhoto = false;

  const photoButtonClicked = () => {
    //console.log("photo clicked");
    if (props.threeView != null) {
      //console.log("space proxy not null");
      props.threeView.requestPhoto(setPhotoData, setOpenPhotoDialog);
    } else {
      console.error("need to provide threeView property");
    }
  };

  const executeDownloadPhoto = () => {
    downloadImage(photoData, `${props.spaceInfo.spaceId}.png`);
  };

  const executeSetThumbnail = () => {
    // split photodata out
    const photoHeader = "data:image/png;base64";
    const photoPayload = photoData.split(",")[1];

    addSnack({ severity: "info", message: "Setting Space thumbnail" });

    setSpaceThumbnail({ data: photoPayload, ...props.spaceInfo })
      .then(() => {
        addSnack({ severity: "success", message: "Space thumbnail set! 🤩" });
      })
      .catch((err) => {
        console.error(err);
        addSnack({
          severity: "error",
          message: "Could not set Space thumbnail 😞",
        });
      });
  };

  const renderPhotoDialog = () => {
    return (
      <Dialog
        open={openPhotoDialog}
        onClose={() => {
          setOpenPhotoDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Save Photo"}</DialogTitle>
        <DialogContent>
          <img src={photoData} style={{ width: "100%", height: "auto" }}></img>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //executeSavePhoto();
              setOpenPhotoDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            startIcon={<GetAppIcon />}
            onClick={() => {
              trackUser("space-viewer-photo-download-click");
              executeDownloadPhoto();
            }}
            color="primary"
          >
            Download
          </Button>
          <Button
            startIcon={<CropOriginalIcon />}
            onClick={() => {
              trackUser("space-viewer-photo-thumbnail-click");
              executeSetThumbnail();
            }}
            color="primary"
          >
            Set thumbnail
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const classes = useStyles();

  const render = () => {
    return (
      <>
        <Box className={classes.iconButton}>
          <Tooltip
            classes={{ tooltip: classes.toolTip }}
            title="Change Space Thumbnail"
            placement="left"
          >
            <IconButton
              size="small"
              onClick={() => {
                props.onClick();
                photoButtonClicked();
              }}
            >
              <ThumbnailIcon
                style={{
                  color: props.selected ? colorSelected : colorUnselected,
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        {renderPhotoDialog()}
      </>
    );
  };

  const { registerCommands, unregisterCommands } = useCommands();
  const commands = [
    {
      id: "set-thumbnail",
      name: "Set Thumbnail",
      description: "Set Space thumbnail",
      shortcut: "",
      command: () => {
        executeSetThumbnail();
      },
    },
    {
      id: "download-image",
      name: "Download image",
      description: "Download a snapshot of the viewer",
      shortcut: "",
      command: () => {
        executeDownloadPhoto();
      },
    },
  ];
  const addCommands = () => {
    registerCommands(commands);
  };
  const removeCommands = () => {
    unregisterCommands(commands);
  };

  /** On Mount  ******************************************/

  // List Spaces
  React.useEffect(() => {
    // console.log("> PhotoButton");
    // console.log(props.spaceInfo);
    // Add commands to command bar
    addCommands();
    return () => {
      removeCommands();
    };
  }, []);

  return render();
}
