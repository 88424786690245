// React
import React from "react";

// Three
import * as THREE from "three";

// Massless
import * as Space from "../massless/Space.js";
import * as SpaceService from "../massless/SpaceService_grpc_web_pb.js";
import * as Utility from "../massless/Utility";
import { SpotLight, Vector3 } from "three";

/********************************** Space Proxy  *********************************************/

export class SpaceProxy {
  constructor(spaceInfo, threeView, callbacks, metadata, rootNode) {
    this.debug = false;
    this.rootNode = rootNode;
    this.metadata = metadata;

    this.spaceInfo = spaceInfo;

    this.meshDatabase = new Map();
    this.spaceNodes = new Map();
    this.lightDatabase = new Map();
    this.cameraDatabase = new Map();
    this.callbacks = callbacks;

    this.nodeCount = 0; // Nodes locally
    this.nodeTotal = 0; // Total nodes in scene

    this.photoRequest = false;
    this.photoCb = null;

    this.three = threeView;

    this.watchSpaceStream = null;
    this.watchSpaceData = this.watchSpaceData.bind(this);

    this.init();
  }

  populateChildren(outlineNode) {}

  getFatNode(nodeId) {
    console.log(">getFatNode");
    let spaceNode = this.spaceNodes.get(nodeId);
    if (spaceNode) {
      if (spaceNode.type == "light") {
        const lightKey =
          spaceNode.lightreference.lightid +
          spaceNode.lightreference.lightversion;

        const lightData = this.lightDatabase.get(lightKey);
        spaceNode.lightData = lightData;
        console.log(lightData);
      }
      if (spaceNode.type == "camera") {
        const cameraKey =
          spaceNode.camerareference.cameraid +
          spaceNode.camerareference.cameraversion;

        const cameraData = this.cameraDatabase.get(cameraKey);
        spaceNode.cameraData = cameraData;
        console.log(cameraData);
      }
    }
    return spaceNode;
  }

  disconnect() {
    this.watchSpaceStream.cancel();
  }

  CreateOutlineNode(spaceNode) {
    if (spaceNode == null)
      return {
        spaceNode: { name: "" },
        children: [],
      };

    let childNodes = spaceNode.children.map((childId) => {
      return this.CreateOutlineNode(this.spaceNodes.get(childId));
    });

    childNodes.sort((a, b) => {
      return a.spaceNode.name > b.spaceNode.name ? 1 : -1;
    });

    return {
      spaceNode: spaceNode,
      children: childNodes,
    };
  }
  generateHierarchy() {
    // Loop through spaceNodes map and extract root nodes
    let rootOutlineNodes = [];
    for (let [nodeId, spaceNode] of this.spaceNodes) {
      if (spaceNode.parent == "None") {
        rootOutlineNodes.push(this.CreateOutlineNode(spaceNode));
      }
    }

    rootOutlineNodes.sort((a, b) => {
      return (a.spaceNode.name ? a.spaceNode.name : "") >
        (b.spaceNode.name ? b.spaceNode.name : "")
        ? 1
        : -1;
    });

    if (this.debug) console.log("Hierarchy> ");
    if (this.debug) console.log(rootOutlineNodes);

    this.callbacks.setOutline({
      spaceNode: { name: "Root" },
      children: rootOutlineNodes,
    });
  }

  init() {
    this.watchSpace();
    // init comments
    this.initComments();
  }

  initComments() {
    Space.listComments(this.spaceInfo, this.metadata)
      .then((comments) => {
        // console.log("three> listing comments");
        // console.log(comments);
        this.three.setComments(comments);
      })
      .catch((err) => {
        console.error(err);
        this.callbacks.addSnack({
          severity: "error",
          message: "Could not list comments in viewer",
        });
      });
  }

  deleteComment(commentInfo) {
    return Space.deleteComment({
      commentId: commentInfo.reference.commentid,
      ...this.spaceInfo,
    });
  }

  // handle mesh
  async handleMeshRespose(node, mesh) {
    // Check if mesh in database,  if not add it
    const meshId = node.getMesh().getMeshid();
    let spaceNode = this.spaceNodes.get(node.getNodeid());
    const spaceMesh = Utility.convertMesh(mesh);
    if (!this.meshDatabase.has(meshId)) {
      this.meshDatabase.set(meshId, spaceMesh);
    } else {
      //console.log("updating existing")
      this.meshDatabase.set(meshId, spaceMesh);
    }
    let localMesh = this.meshDatabase.get(meshId);

    if (spaceNode != null) {
      spaceNode.threeGeo = localMesh;
      if (spaceNode.threeObject != null) {
        //console.log("updating threeObject")
        spaceNode.threeObject.geometry = localMesh;
        let threeMaterial = Utility.createDefaultThreeMaterial();
        spaceNode.threeObject.material = threeMaterial;
        spaceNode.type = "mesh";
      } else {
        //console.log("three object doesn't exist")
      }
    }

    // Check if any other objects in the scene are waiting for my mesh
    this.three.scene.traverse((object) => {
      const nodeId = object.userData["nodeId"];
      const itNode = this.spaceNodes.get(nodeId);
      if (
        itNode != null &&
        itNode.meshRef != null &&
        itNode.meshRef.getMeshid() == meshId &&
        itNode.threeObject != null
      ) {
        itNode.threeGeo = localMesh;
        itNode.threeObject.geometry = localMesh;
        let threeMaterial = Utility.createDefaultThreeMaterial();
        itNode.threeObject.material = threeMaterial;
        itNode.type = "mesh";
      }
    });

    this.handleSceneAdd(spaceNode);
  }

  // handle light
  async handleLightResponse(node, res) {
    // console.log("> handleLightResponse");
    // console.log(res)
    // console.log(res.toObject());
    // Add/update database
    res.toObject().lightsList.forEach((light) => {
      // console.log("> adding light to database");
      // console.log(light.lightreference);
      const lightKey =
        light.lightreference.lightid + light.lightreference.lightversion;
      this.lightDatabase.set(lightKey, light);
      // console.log(this.lightDatabase.get(lightKey));
      // console.log("lightId:" + lightId);
      // console.log(lightData)
    });

    // Check if any other spaceNodes have this lightRef
    // If so handle scene update
    let spaceNode = this.spaceNodes.get(node.getNodeid());
    this.handleSceneAdd(spaceNode);
  }

  // handle camera
  async handleCameraResponse(node, res) {
    // console.log("> handleLightResponse");
    // console.log(res)
    // console.log(res.toObject());
    // Add/update database
    res.toObject().camerasList.forEach((camera) => {
      // console.log("> adding camera to database");
      // console.log(camera.camerareference);
      const cameraKey =
        camera.camerareference.cameraid + camera.camerareference.cameraversion;
      this.cameraDatabase.set(cameraKey, camera);
      // console.log(this.cameraDatabase.get(cameraKey));
      // console.log("lightId:" + lightId);
      // console.log(lightData)
    });

    // Check if any other spaceNodes have this lightRef
    // If so handle scene update
    let spaceNode = this.spaceNodes.get(node.getNodeid());
    this.handleSceneAdd(spaceNode);
  }

  // handle transform
  async handleTransformResponse(node, transform) {
    let spaceNode = this.spaceNodes.get(node.getNodeid());
    spaceNode.transform = Utility.convertTransform(spaceNode, transform);
    this.handleSceneAdd(spaceNode);
  }

  createPlatformObject(spaceNode) {
    let threeObject = null;
    if (spaceNode.type == "mesh") {
      threeObject = new THREE.Mesh();
      threeObject.geometry = spaceNode.threeGeo;
      threeObject.material = Utility.createDefaultThreeMaterial();
      threeObject.castShadow = true;
      threeObject.receiveShadow = true;
    } else if (spaceNode.type == "empty") {
      threeObject = new THREE.Mesh();
    } else if (spaceNode.type == "light") {
      //Add light to scene
      const lightKey =
        spaceNode.lightreference.lightid +
        spaceNode.lightreference.lightversion;
      const lightData = this.lightDatabase.get(lightKey);
      threeObject = Utility.convertLight(lightData.web);

      //Turn off default Light if scene lights present
      this.setDefaultLightVisibility(false);
    } else if (spaceNode.type == "camera") {
      //Add light to scene
      const cameraKey =
        spaceNode.camerareference.cameraid +
        spaceNode.camerareference.cameraversion;
      const cameraData = this.cameraDatabase.get(cameraKey);
      threeObject = Utility.convertCamera(cameraData.web);
      // threeObject = new THREE.Object3D();
    }
    return threeObject;
  }

  setDefaultLightVisibility(state) {
    this.three.scene.traverse((object) => {
      if (object != null) {
        if (object.name == "defaultLight") {
          object.visible = state;
          this.callbacks.setSceneLight(!state);
        }
      }
    });
  }

  async handleSceneAdd(spaceNode) {
    //console.log("handleSceneAdd");
    // console.log(spaceNode.lightRef);
    // if (spaceNode.type == "light") {
    //   // console.log("handle scene add: ");
    //   // console.log(spaceNode.lightreference);
    //   const lightKey =
    //     spaceNode.lightreference.lightid +
    //     spaceNode.lightreference.lightversion;
    //   const lightData = this.lightDatabase.get(lightKey);
    //   // console.log(lightData);
    //   // if (lightData == null) {
    //   //   console.log(this.lightDatabase);
    //   // }
    // }
    // const lightKey =
    //   spaceNode.lightreference.lightid + spaceNode.lightreference.lightversion;

    const isSupportedType =
      spaceNode.type == "empty" ||
      (spaceNode.type == "mesh" && spaceNode.threeGeo != null) ||
      (spaceNode.type == "light" &&
        this.lightDatabase.get(
          spaceNode.lightreference.lightid +
            spaceNode.lightreference.lightversion
        ) != null) ||
      (spaceNode.type == "camera" &&
        this.cameraDatabase.get(
          spaceNode.camerareference.cameraid +
            spaceNode.camerareference.cameraversion
        ) != null);
    const parentPresent =
      spaceNode.parent == "None" ||
      this.spaceNodes.get(spaceNode.parent) != null;
    const hasName = spaceNode.name != null;
    const hasTransform = spaceNode.transform != null;
    const isNotInScene = !spaceNode.inScene;
    const readyToAdd =
      isNotInScene &&
      isSupportedType &&
      hasName &&
      hasTransform &&
      parentPresent;

    if (this.debug)
      console.log("Scene> isSupportedType: [ " + isSupportedType + " ]");
    if (this.debug)
      console.log("Scene> parentPresent: [ " + parentPresent + " ]");
    if (this.debug)
      console.log("Scene> hasName: [ " + (spaceNode.name != null) + " ]");
    if (this.debug)
      console.log(
        "Scene> hasTransform:[ " + (spaceNode.transform != null) + " ]"
      );
    if (this.debug)
      console.log("Scene> isNotInScene: [ " + !spaceNode.inScene + " ]");
    if (this.debug) console.log("-----------------------------------");
    if (this.debug) console.log("Scene> Ready: " + readyToAdd);
    if (this.debug) console.log("-----------------------------------");
    if (readyToAdd) {
      if (this.debug) console.log("Scene> ");
      if (this.debug) console.log("Scene> ***** Adding node! ****");
      if (this.debug) console.log("Scene> ");
      if (this.debug) console.log("Scene> Name: " + spaceNode.name);
      if (this.debug) console.log("Scene> Id: " + spaceNode.id);
      if (this.debug) console.log("Scene> Parent: " + spaceNode.parent);
      if (this.debug) console.log("Scene> Type: " + spaceNode.type);
      if (this.debug) console.log("Scene> ");

      let threeObject = this.createPlatformObject(spaceNode);

      spaceNode.threeObject = threeObject;
      // Set name
      threeObject.name = spaceNode.name;
      // Set node ref
      threeObject.userData["nodeRef"] = spaceNode.ref;
      threeObject.userData["nodeId"] = spaceNode.id;
      threeObject.userData["nodeVersion"] = spaceNode.version;
      // Apply transform
      Utility.applyTransform(spaceNode, spaceNode.transform);
      // Setup the Light Helpers
      let threeHelper = Utility.addObjectHelpers(threeObject);
      // If parent is none add to scene
      if (spaceNode.parent == "None") {
        if (this.debug) console.log("Scene> Root node");
        this.three.scene.add(spaceNode.threeObject);
        if (threeHelper != null) {
          //Add the Light Helper to the scene
          this.three.scene.add(threeHelper);
        }
        spaceNode.inScene = true;
        this.nodeAddedToScene();
      }
      // Else add to parent
      else {
        if (this.debug) console.log("Scene> Child node");
        let spaceParent = this.spaceNodes.get(spaceNode.parent);
        if (spaceParent != null) {
          if (this.debug)
            console.log("Scene> Parent [ Found ] " + spaceParent.name);
          if (spaceParent.threeObject != null) {
            if (this.debug) console.log("Scene> Parent Object [ Found ]");
            if (this.debug) console.log("Scene> Adding to parent");
            if (this.debug)
              console.log(
                "Scene> " +
                  spaceNode.name +
                  " " +
                  spaceNode.type +
                  " " +
                  spaceNode.threeObject +
                  " " +
                  spaceNode.threeGeo
              );
            if (this.debug)
              console.log(
                "Scene> " +
                  spaceParent.name +
                  " " +
                  spaceParent.type +
                  " " +
                  spaceParent.threeObject +
                  " " +
                  spaceParent.threeGeo
              );

            if (
              spaceNode.threeObject.type == "SpotLight" ||
              spaceNode.threeObject.type == "DirectionalLight"
            ) {
              var targetObject = new THREE.Object3D();
              spaceNode.threeObject.add(targetObject);
              let targetPosition = new Vector3(0, 0, -1);
              targetObject.position.copy(targetPosition);
              spaceNode.threeObject.target = targetObject;
            }
            spaceParent.threeObject.add(spaceNode.threeObject);

            spaceNode.inScene = spaceParent.inScene;
            if (spaceNode.inScene) this.nodeAddedToScene();
            //nodeAdded();
            // spaceParent.threeObject.updateWorldMatrix(true,true)
            // spaceNode.threeObject.updateWorldMatrix(true,true)
          } else {
            if (this.debug) console.log("Scene> Parent Object [ Missing ]");
            this.handleSceneAdd(spaceParent);
          }
        } else {
          if (this.debug) console.log("Scene> Parent [ Missing ]");
        }
      }
      // Check if we have any children waiting
      if (this.debug)
        console.log("Scene> Check children N = " + spaceNode.children.length);
      spaceNode.children.forEach((child) => {
        if (this.debug) console.log("Scene> Child nodeId " + child);
        let spaceChild = this.spaceNodes.get(child);
        if (spaceChild) {
          if (this.debug) console.log("Scene> Child " + spaceChild.name);
          this.handleSceneAdd(spaceChild);
        }
      });
    }
  }

  nodeAddedToSpace() {
    this.nodeTotal++;
    this.callbacks.setNodeTotal(this.nodeTotal);
    this.generateHierarchy();
  }

  nodeAddedToScene() {
    this.nodeCount++;
    this.callbacks.setNodeCount(this.nodeCount);
    this.generateHierarchy();
  }

  nodeRemovedFromSpace() {
    this.nodeTotal--;
    this.callbacks.setNodeTotal(this.nodeTotal);
    this.generateHierarchy();
  }

  nodeRemovedFromScene() {
    this.nodeCount--;
    this.callbacks.setNodeCount(this.nodeCount);
    this.generateHierarchy();
  }

  removeNode(nodeId) {
    //console.log("spaceProxy.removeNod
    const nodeRef = new SpaceService.NodeReference().setNodeid(nodeId);
    Space.removeNode({ ref: nodeRef, ...this.spaceInfo }, this.metadata)
      .then((res) => {
        // TODO: More delete local object
        this.spaceNodes.delete(nodeRef.getNodeid());
        /*this.callbacks.addSnack({
          severity: "success",
          message: "Node removed",
        });*/
        this.nodeRemovedFromSpace();
      })
      .catch((err) => {
        console.error(err);
        this.callbacks.addSnack({
          severity: "error",
          message: "Could not remove node",
        });
      });
  }

  async addNodeLocal(nodeRef) {
    const nodeId = nodeRef.getNodeid();
    Space.getNode({ ref: nodeRef, ...this.spaceInfo }, this.metadata).then(
      (node) => {
        // check not in cache
        let spaceNode = this.spaceNodes.get(nodeId);
        if (spaceNode != null) {
          if (this.debug) console.log("Add> Node already in cache");
          return;
        }

        // Add to local spaceNode cache
        spaceNode = new Space.SpaceNode(nodeRef, node);
        this.spaceNodes.set(nodeId, spaceNode);
        //console.log(spaceNode.parent);

        // Set name
        if (node.getProperties() != null) {
          spaceNode.name = node.getProperties().getName();
        }

        // Get transform
        if (node.hasTransform()) {
          if (this.debug) console.log("Add> Transform [ Yes ]");
          Space.getTransform(
            {
              nodeRef: nodeRef,
              transformRef: node.getTransform(),
              ...this.spaceInfo,
            },
            this.metadata
          )
            .then((transform) => {
              this.handleTransformResponse(node, transform);
            })
            .catch((err) => {
              console.log(nodeRef);
              console.log(node.getTransform());
              console.error(err);
            });
        } else {
          if (this.debug) console.log("Add> Transform [ No ]");
        }

        // Check mesh
        if (node.hasMesh()) {
          if (this.debug) console.log("Add> Mesh [ Yes ]");
          // If mesh not in db then download
          const meshId = node.getMesh().getMeshid();
          if (!this.meshDatabase.has(meshId)) {
            Space.getMesh(
              {
                meshRef: node.getMesh(),
                ...this.spaceInfo,
              },
              this.metadata
            ).then((mesh) => {
              this.handleMeshRespose(node, mesh);
            });
          }
          // else assign the mesh to the spaceNode
          else {
            let spaceNode = this.spaceNodes.get(node.getNodeid());
            spaceNode.threeGeo = this.meshDatabase.get(meshId);
            this.handleSceneAdd(spaceNode);
          }
        } else {
          if (this.debug) console.log("Add> Mesh [ No ]");
        }

        // Get node type/attribute
        switch (node.getTypeCase()) {
          // Check light
          case SpaceService.Node.TypeCase.LIGHTREFERENCE:
            const lightRef = spaceNode.lightRef;

            // If not in db download
            // console.log("light type detected");
            // console.log(lightRef.getLightid())
            const lightKey =
              spaceNode.lightreference.lightid +
              spaceNode.lightreference.lightversion;
            if (!this.lightDatabase.has(lightKey)) {
              // console.log("light not in database")
              // Get the light
              Space.readLights({ ...this.spaceInfo, lightRef: lightRef })
                .then((res) => {
                  // console.log("readLights")
                  // console.log(res)
                  this.handleLightResponse(node, res);
                })
                .catch((err) => {
                  console.error(err);
                });
            }
            break;
          // Check camera
          case SpaceService.Node.TypeCase.CAMERAREFERENCE:
            const cameraRef = spaceNode.cameraRef;

            // If not in db download
            // console.log("light type detected");
            // console.log(lightRef.getLightid())
            const cameraKey =
              spaceNode.camerareference.cameraid +
              spaceNode.camerareference.cameraversion;
            if (!this.cameraDatabase.has(cameraKey)) {
              // console.log("light not in database")
              // Get the light
              Space.readCameras({ ...this.spaceInfo, cameraRef: cameraRef })
                .then((res) => {
                  // console.log("readLights")
                  // console.log(res)
                  this.handleCameraResponse(node, res);
                })
                .catch((err) => {
                  console.error(err);
                });
            }
            break;
        }

        // Register new node
        this.nodeAddedToSpace();
      }
    );
  }

  /******************************* UPDATE ******************************************************************/

  handleNodeUpdate(nodeRef, node) {
    if (this.debug) console.log("Update> ");
    let spaceNode = this.spaceNodes.get(node.getNodeid());

    // If it isn't in the database ignore updates
    // TODO: if it isn't in the database, make a request to add it
    if (spaceNode == null) {
      this.addNodeLocal(nodeRef);
      return;
    }

    // Check for type update
    let changedTypeToMesh = false;
    let typeBefore = spaceNode.type;

    var previousMeshRef = null;
    // Store the old mesh reference, to compare for changes later
    if (typeBefore == "mesh") {
      previousMeshRef = spaceNode.meshRef;
    }

    // Overwrites the mesh reference with the new one during setting of the type
    spaceNode.setType(node);
    if (typeBefore != spaceNode.type) {
      if (this.debug)
        console.log("Update> Type [Yes] " + typeBefore + "->" + spaceNode.type);
      if (spaceNode.type == "mesh") {
        changedTypeToMesh = true;
      }
    } else {
      if (this.debug) console.log("Update> Type [No]");
    }

    // const green = new THREE.Color(0x00f5a6);
    // const white = new THREE.Color(0xffffff);
    // if (spaceNode.threeObject != null) {
    //   if (!spaceNode.fading) {
    //     spaceNode.fading = true;
    //     fade(
    //       spaceNode,
    //       (value) => {
    //         spaceNode.threeObject.material.color = value;
    //       },
    //       green,
    //       white,
    //       0.0,
    //       1.0,
    //       2000
    //     );
    //   }
    // }

    // Update name
    spaceNode.name = node.getProperties().getName();

    // Update parent
    let remoteParent = node.getParent();
    if (spaceNode.parent != remoteParent) {
      if (this.debug) console.log("Update> Parent [ Yes ]");
      // Remove from current parent
      if (this.debug)
        console.log("Update> Current parent: " + spaceNode.parent);
      if (spaceNode.parent == "None" || spaceNode.parent == null) {
        if (this.debug)
          console.log("Update> Removing from root " + spaceNode.name);
        this.three.scene.remove(spaceNode.threeObject);
      } else {
        let spaceParent = this.spaceNodes.get(spaceNode.parent);
        if (spaceParent != null) {
          spaceParent.threeObject.remove(spaceNode.threeObject);
          if (this.debug)
            console.log("Update> Removing from object " + spaceParent.name);
        }
      }
      // Add to new parent
      let newSpaceParent = this.spaceNodes.get(remoteParent);
      if (newSpaceParent != null && newSpaceParent.threeObject != null) {
        newSpaceParent.threeObject.add(spaceNode.threeObject);
        spaceNode.parent = remoteParent;
        newSpaceParent.children.push(spaceNode.id);
        if (this.debug)
          console.log("Update> Parenting to object " + newSpaceParent.name);
      }
      // Update hierarchy
      this.generateHierarchy();
    } else {
      if (this.debug) console.log("Update> Parent [ No ]");
    }

    // Update transform
    if (node.hasTransform()) {
      Space.getTransform(
        {
          nodeRef: nodeRef,
          transformRef: node.getTransform(),
          ...this.spaceInfo,
        },
        this.metadata
      )
        .then((transform) => {
          console.log("Update> Transform [ Yes ]");
          //console.log("getTransform()");
          //console.log(transform);\

          let spaceNode = this.spaceNodes.get(node.getNodeid());
          //console.log(spaceNode);

          // store transform
          let localTransform = Utility.convertTransform(spaceNode, transform);
          spaceNode.transform = localTransform;

          // if we have a threeObject then apply the transform
          if (spaceNode.threeObject != null) {
            Utility.applyTransform(spaceNode, localTransform);
          }
          // Check if we're ready to add to scene
          this.handleSceneAdd(spaceNode);
        })
        .catch((err) => {
          console.error(err);
          this.callbacks.addSnack({
            severity: "error",
            message: "Could not get transform",
          });
        });
    } else {
      if (this.debug) console.log("Update> Transform [ No ]");
    }

    // Mesh update

    if (
      node.hasMesh() &&
      (previousMeshRef == null ||
        changedTypeToMesh ||
        previousMeshRef.getMeshid() != node.getMesh().getMeshid() ||
        previousMeshRef.getMeshversion() != node.getMesh().getMeshversion())
    ) {
      //console.log("Mesh updated");
      Space.getMesh(
        {
          meshRef: node.getMesh(),
          ...this.spaceInfo,
        },
        this.metadata
      ).then((mesh) => {
        if (this.debug) console.log("Update> Mesh [ Yes ]");
        this.handleMeshRespose(node, mesh);
      });
    } else {
      if (this.debug) console.log("Update> Mesh [ No ]");
    }

    // Type change check if children are waiting
    if (changedTypeToMesh) {
      spaceNode.children.forEach((child) => {
        if (this.debug) console.log("Update> Child nodeId " + child);
        let spaceChild = this.spaceNodes.get(child);
        if (spaceChild) {
          if (this.debug) console.log("Update> Child " + spaceChild.name);
          this.handleSceneAdd(spaceChild);
        }
      });
    }
  }

  deleteNodeLocal(nodeRef) {
    const nodeId = nodeRef.getNodeid();
    const spaceNode = this.spaceNodes.get(nodeId);
    if (spaceNode == null) {
      console.log("Delete> Node not in cache");
      return;
    }

    // Remove from parents children list
    if (spaceNode.parent != "None" || spaceNode.parent != null) {
      let spaceParent = this.spaceNodes.get(spaceNode.parent);
      if (spaceParent != null) {
        console.log(spaceParent.children);
        spaceParent.children = spaceParent.children.filter(
          (childId) => childId != nodeId
        );
      }
    }

    // Remove from scene or parent
    if (spaceNode.parent == "None" || spaceNode.parent == null) {
      this.three.scene.remove(spaceNode.threeObject);
      this.nodeRemovedFromScene();
    } else {
      let spaceParent = this.spaceNodes.get(spaceNode.parent);
      if (spaceParent != null) {
        spaceParent.threeObject.remove(spaceNode.threeObject);
        this.nodeRemovedFromScene();
      }
    }

    // remove children from scene
    console.log("Delete> Node children: " + spaceNode.children.length);
    spaceNode.children.forEach((childId) => {
      let childNode = this.spaceNodes.get(childId);
      if (childNode) {
        childNode.parent = "None";
        childNode.inScene = false;
        this.nodeRemovedFromScene();
      }
    });
    // Delete from cache
    this.spaceNodes.delete(nodeId);
    // Update node stats + hierarchy
    this.nodeRemovedFromSpace();
    // Snack
    //this.callbacks.addSnack({ severity: "info", message: "Node removed" });
  }

  updateNodeLocal(nodeRef) {
    // Get the node and handle update
    Space.getNode({ ref: nodeRef, ...this.spaceInfo }, this.metadata).then(
      (node) => {
        this.handleNodeUpdate(nodeRef, node);
        //this.callbacks.addSnack({ severity: "info", message: "Node updated" });
      }
    );
  }

  watchSpaceData(spaceInfo, nodeChange) {
    //console.log("stream")
    let nodeRef = nodeChange.getNode();
    let type = nodeChange.getType();
    //this.log(Type);
    if (type == SpaceService.NodeChangeType.NODECHANGETYPE_ADDED) {
      if (this.debug) console.log("Watch> Node added");
      if (this.debug) console.log(nodeRef);
      this.addNodeLocal(nodeRef);
    } else if (type == SpaceService.NodeChangeType.NODECHANGETYPE_UPDATED) {
      if (this.debug) console.log("Watch> Node updated");
      this.updateNodeLocal(nodeRef);
    } else if (type == SpaceService.NodeChangeType.NODECHANGETYPE_DELETED) {
      if (this.debug) console.log("Watch> Node delete");
      //Delete the Light Helper when the light is deleted
      console.log(this.three.scene);
      if (this.three.scene != null) {
        this.three.scene.traverse((object) => {
          if (object.name.includes("LightHelper")) {
            console.log(object);
            if (object.light.userData["nodeId"] == nodeRef.getNodeid()) {
              this.three.scene.remove(object);
            }
          }
        });
      }
      this.deleteNodeLocal(nodeRef);
    }
  }

  watchSpace() {
    this.watchSpaceStream = Space.watchSpace(this.spaceInfo, this.metadata)
      .on("data", async (nodeChange) => {
        if (this.debug) console.log("Watch> Data");
        this.watchSpaceData(this.spaceInfo, nodeChange);
      })
      .on("status", async (status) => {
        if (status == 16) {
          this.callbacks.addSnack({
            severity: "error",
            message: "Access denied to Space stream",
          });
        }
        if (this.debug) console.log(status.code);
        if (this.debug) console.log(status.details);
        if (this.debug) console.log(status.metadata);
      })
      .on("end", async (end) => {
        if (this.debug) console.log("Watch> space stream ended");
      });
  }
}
