import React, { Component } from "react";
import * as THREE from "three";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";

import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { RoughnessMipmapper } from "three/examples/jsm/utils/RoughnessMipmapper.js";

import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper.js";
import { RectAreaLightUniformsLib } from "three/examples/jsm/lights/RectAreaLightUniformsLib.js";

let scene;

class SpiralManipulator extends Component {
  componentDidMount() {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    scene = new THREE.Scene();

    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setClearColor("#d9d9d9");
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);

    this.renderer.gammaOutput = true;

    //add Camera
    this.camera = new THREE.OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      1,
      1000
    );
    this.camera.position.z = 100;
    this.camera.position.y = 0;
    this.camera.zoom = 0.5;

    //Camera Controls
    const controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.enableZoom = false;
    controls.enablePan = false;
    controls.minAzimuthAngle = 0;
    controls.maxAzimuthAngle = 0;
    // controls.minPolarAngle = 0;
    // controls.maxPolarAngle = 0;

    const loader = new GLTFLoader().setPath("/");
    loader.load("Spiral Tex.gltf", function (gltf) {
      gltf.scene.traverse(function (child) {
        if (child.isMesh) {
          // TOFIX RoughnessMipmapper seems to be broken with WebGL 2.0
        }
      });
      scene.add(gltf.scene);
    });

    this.renderScene();

    //start animation
    this.start();

    scene.traverse((object) => {
      console.log(object);
    });
  }

  componentWillUnmount() {
    this.stop();
    this.mount.removeChild(this.renderer.domElement);
  }
  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };
  stop = () => {
    cancelAnimationFrame(this.frameId);
  };
  animate = () => {
    // -----Step 3--------
    //Rotate Models
    if (this.cube) this.cube.rotation.y += 0.01;
    if (this.spiral) this.spiral.rotation.y += 0.01;
    if (this.freedomMesh) this.freedomMesh.rotation.y += 0.01;

    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
  };
  renderScene = () => {
    if (this.renderer) this.renderer.render(scene, this.camera);
  };

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          outline: "inherit",
        }}
        ref={(mount) => {
          this.mount = mount;
        }}
      />
    );
  }
}
export default SpiralManipulator;
