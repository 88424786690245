// React
import React from "react";

export const KeysContext = React.createContext();
export const useKeys = () => React.useContext(KeysContext);

var _commands = [];

function keyHandler(e) {
  // console.log(e);
  // console.log(commands);
  // console.log(
  //   commands.filter((cmd) => {
  //     return e.ctrlKey == cmd.ctrlKey && e.code == cmd.code;
  //   })
  // );
  // console.log(_commands);

  const matching = _commands.filter((cmd) => {
    return e.ctrlKey == cmd.ctrlKey && e.code == cmd.code;
  });
  if (matching.length > 0) {
    matching[0].command();
  }
}

export function KeysProvider({ children }) {
  const [isBound, setIsBound] = React.useState(false);
  const [callback, setCallback] = React.useState(null);
  const [commands, setCommands] = React.useState([]);

  const bindKeys = () => {
    // console.log("bind");
    window.addEventListener("keypress", keyHandler, true);
    setIsBound(true);
  };

  const unbindKeys = () => {
    // console.log("unbind");
    window.removeEventListener("keypress", keyHandler, true);
    setIsBound(false);
  };

  const registerKeys = (newCommands) => {
    _commands = _commands.concat(newCommands);
    setCommands(commands.concat(newCommands));
  };

  const unregisterKeys = (removeCommands) => {
    setCommands(
      commands.filter((cmd) => {
        return !removeCommands.includes(cmd);
      })
    );
  };

  const contextObject = { isBound, bindKeys, unbindKeys, registerKeys, unregisterKeys };

  return (
    <KeysContext.Provider value={contextObject}>
      {children}
    </KeysContext.Provider>
  );
}
