// React
import React from "react";

//Material UI
import { makeStyles } from "@material-ui/core/styles";

import { Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

// Massless
import { useAuth } from "../context/AuthContext";
import { sAnalyticsTrack, trackUser } from "../massless/AnalyticsClient";

const useStyles = makeStyles((theme) => ({
  dialogPanel: {
    borderRadius: 20,
    display: "flex",
    flexDirection: "column",
    maxWidth: "440px",
    [theme.breakpoints.down("sm")]: {
      padding: 8,
      margin: 8,
    },
    [theme.breakpoints.up("sm")]: {
      padding: 32,
      margin: 16,
    },
  },
  loginButton: {
    height: "30px",
    borderRadius: "20px",
    width: "100%",
  },
}));

export function HelpDialog(props) {
  const classes = useStyles();

  const [openEmailDialog, setOpenEmailDialog] = React.useState(false);

  const trackUser = (trackId, properties = {}) => {
    if (profile == null) {
      sAnalyticsTrack(trackId, properties);
      return;
    }
    let userId = profile.sub.split("|")[1];
    sAnalyticsTrack(trackId, {
      ...{ id: userId, email: profile.email },
      ...properties,
    });
  };

  let { authStage, profile, isAuthenticated, signOut } = useAuth();

  const render = () => {
    return (
      <>
        <Dialog
          classes={{ paper: classes.dialogPanel }}
          open={props.open}
          onClose={() => {
            props.callbacks.setOpenEmailDialog(false);
          }}
        >
          <DialogTitle style={{ padding: "12px 18px 0" }}>
            Need Some Help?
          </DialogTitle>
          <DialogContent style={{ padding: "12px 18px" }}>
            <DialogContentText style={{ marginBottom: "10px" }}>
              Want to refer a friend or request a feature? Please send us an
              email using the button below, we would love to hear from you!
            </DialogContentText>
          </DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding="0 16px 16px"
          >
            <Button
              className={classes.loginButton}
              variant="outlined"
              color="primary"
              onClick={() => {
                trackUser("side-nav-email-help");
                window.open("mailto:space.enquiry@massless.io");
              }}
            >
              Send us an email!
            </Button>
          </Box>
        </Dialog>
      </>
    );
  };
  return render();
}
