import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

export const gAnalytics = Analytics({
  app: "space",
  plugins: [
    googleAnalytics({
      trackingId: "UA-134353122-2",
    }),
  ],
});

export const sAnalyticsLoad = () => {
  window.analytics.load("etFBz1J8hSnVB43dLmJmlmbTdN0aF5Vg");
};

export const sAnalyticsPage = () => {
  window.analytics.page();
};

export const sAnalyticsTrack = (name, properties) => {
  window.analytics.track(name, properties);
};

export const sAnalyticsIdentify = (userId, properties) => {
  window.analytics.identify(userId, properties);
};

export const trackUser = (trackId, profile, properties = {}) => {
  if (profile == null) {
    sAnalyticsTrack(trackId, properties);
    return;
  }
  let userId = profile.sub.split("|")[1];
  sAnalyticsTrack(trackId, {
    ...{ id: userId, email: profile.email },
    ...properties,
  });
};
