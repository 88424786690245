// React
import React from "react";

// Material
import { makeStyles, createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import TextField from "@material-ui/core/TextField";
import InputBase from "@material-ui/core/InputBase";
import Link from "@material-ui/core/Link";

import useAutocomplete from "@material-ui/lab/useAutocomplete";

// Massless
import { useKeys } from "../context/KeysContext";
import { Divider } from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: "20px",
        background: "#3b3f71",
      },
      paperWidthSm: {
        height: "600px",
        width: "600px",
        maxWidth: "600px",
      }
    }
  }
});

const useStyles = makeStyles((theme) => ({
  DialogRoot: {
    paperWidthSm: {
      height: "600px",
      width: "600px",
      maxWidth: "600px",
    }
    // height: "600px",
  },
  commandBar: {
    borderRadius: "4.5px",
    padding: "5px !important",
  },
  searchField: {
    backgroundColor: "#1F2249",
    fontWeight: "300",
    fontSize: 14,
    borderRadius: "15px",
    padding: "5px 8px",
    // width: "100%",
    height: "30px",
    color: "#FFFFFF",
  },
  suggestionText: {
    fontWeight: "300",
    fontSize: 14,
  },
  suggestionButtonRow: {
    padding: "0",
    textAlign: "left",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
}));

var _commands = [];

function keyHandler(e) {
  const matching = _commands.filter((cmd) => {
    return e.ctrlKey == cmd.ctrlKey && e.code == cmd.code;
  });
  if (matching.length > 0) {
    matching[0].command();
  }
}

export function CommandBar(props) {
  // Style Classes **************************************************
  const classes = useStyles();
  // Keys **************************************************
  const { registerKeys, unregisterKeys } = useKeys();
  // Autocomplete *******************************************
  const { getInputProps, groupedOptions } = useAutocomplete({
    id: "use-autocomplete",
    options: props.commands,
    getOptionLabel: (option) => option.name,
  });
  // const [openDialog, props.setOpen] = React.useState(false);
  const [text, setText] = React.useState("");
  const [suggestions, setSuggestions] = React.useState([]);
  const textRef = React.useRef();
  // Key selection *******************************************
  const [selected, setSelected] = React.useState(null);

  const processSugguestions = () => {
    // compare string similarity to
    const suggestions = groupedOptions;

    if (text.length == 0) {
      setSuggestions(props.commands);
    } else {
      // setSuggestions(suggestions);
      setSuggestions(suggestions);
    }
  };

  React.useEffect(() => {
    processSugguestions();
  }, [text]);

  React.useEffect(() => {
    if (suggestions.length > 0) {
      setSelected(suggestions[0].id);
    }
  }, [suggestions]);

  React.useEffect(() => {
    processSugguestions();
    props.setOpen(props.open);
    if (props.open) {
      _commands = [
        {
          ctrlKey: true,
          code: "KeyM",
          command: () => {
            handleClose();
          },
        },
      ];
      window.addEventListener("keypress", keyHandler, true);
    } else {
      window.removeEventListener("keypress", keyHandler, true);
    }
  }, [props.open]);


  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      handleEnter();
    } else if (e.key == "ArrowDown") {
      console.log(selected);
      if (selected == null && suggestions.length > 0) {
        setSelected(suggestions[0].id);
      } else if (selected != null) {
        let index = suggestions.findIndex((suggestion) => {
          return suggestion.id == selected;
        });
        if (index >= 0) {
          const nextIndex = index + 1 < suggestions.length ? index + 1 : 0;
          setSelected(suggestions[nextIndex].id);
        }
      }
    } else if (e.key == "ArrowUp") {
      console.log(selected);
      if (selected == null && suggestions.length > 0) {
        setSelected(suggestions[suggestions.length - 1].id);
      } else if (selected != null) {
        let index = suggestions.findIndex((suggestion) => {
          return suggestion.id == selected;
        });
        if (index >= 0) {
          const nextIndex = index - 1 >= 0 ? index - 1 : suggestions.length - 1;
          setSelected(suggestions[nextIndex].id);
        }
      }
    }
  };

  const handleClose = () => {
    props.setOpen(false);

    setTimeout(() => {
      // Clear the text field for next time
      setText("");
    }, 500);
  };

  const handleEnter = () => {
    let index = suggestions.findIndex((suggestion) => {
      return suggestion.id == selected;
    });
    if (index >= 0) {
      suggestions[index].command()
    }
    handleClose();
  };
  
  const renderCommandBarDialog = () => {
    return (
      <MuiThemeProvider theme={theme}>
        <Dialog
          className={classes.DialogRoot}
          open={props.open}
          onClose={() => {
            handleClose();
          }}
        >
          <DialogContent 
            className={classes.commandBar}
          >
            <InputBase
              className={classes.searchField}
              fullWidth
              autoFocus = "true"
              placeholder="Search for a feature..."
              onChange={(e) => {
                setText(e.target.value);
              }}
              onKeyDown={(event) => {
                handleKeyDown(event);
              }}
              inputProps={{ ...getInputProps(), value: text }}
            />
            <Box style={{  margin: "0 8px", }}>
              {suggestions.map((cmd) => {
                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{ color: cmd.id == selected ? "#00f5a7" : "white", }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      style={{ color: cmd.id == selected ? "#00f5a7" : "white", paddingTop: "8px", paddingBottom: "8px",}}
                    >
                      <Button
                      className={classes.suggestionButtonRow}
                      fullWidth
                      style={{ color: cmd.id == selected ? "#00f5a7" : "white" }}
                      onMouseDown={() => {
                        setSelected(cmd.id)
                      }}
                      onMouseUp={() => {
                        handleEnter()
                      }}
                      >
                        <Typography
                          className={classes.suggestionText}
                          noWrap
                          style={{ display: "inline", width: "25%" }}
                        >
                          {cmd.name}
                        </Typography>
                        <Typography
                          className={classes.suggestionText}
                          noWrap
                          style={{ display: "inline", width: "50%" }}
                        >
                          {cmd.description}
                        </Typography>
                        <Typography
                          className={classes.suggestionText}
                          noWrap
                          style={{ display: "inline", width: "25%", }}
                        >
                          {cmd.shortcut}
                        </Typography>
                      </Button>
                    </Box>
                    <Divider/>
                  </Box>
                );
              })}
            </Box>
          </DialogContent>
          {/* <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              color="secondary"
            >
              Close
            </Button>
          </DialogActions> */}
        </Dialog>
      </MuiThemeProvider>
    );
  };

  return renderCommandBarDialog();
}
